import React, { createContext, useEffect, useReducer } from 'react'

// third-party
import { Auth0Client } from '@auth0/auth0-spa-js'

// reducer - state management
import { LOGIN, LOGOUT } from 'contexts/auth-reducer/actions'
import authReducer from 'contexts/auth-reducer/auth'

// project import
import Loader from 'components/Loader'
import { Auth0ContextType, AuthProps } from 'types/auth'
import { KeyedObject } from 'types/root'

// constant
let auth0Client: Auth0Client

const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
}

const Auth0Context = createContext<Auth0ContextType | null>(null)

export const Auth0Provider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)

  auth0Client = new Auth0Client({
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    useRefreshTokens: true,
    authorizationParams: {
      redirect_uri: window.location.origin,
      async openUrl(url: string) {
        window.location.replace(url)
      },
      appState: {
        returnTo: window.location.origin,
      },
    },
  })

  useEffect(() => {
    async function initAuth0() {
      try {
        if (!state.isInitialized) {
          // Check if the URL contains the Auth0 callback parameters
          if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
            try {
              const result = await auth0Client.handleRedirectCallback()
              // Parse the state parameter
              const appState = result.appState
              // Update the location with the targetUrl
              if (appState && appState.targetUrl) {
                window.history.replaceState({ targetUrl: appState.targetUrl }, document.title, appState.targetUrl)
              }
            } catch (error) {
              console.error('Error handling redirect:', error)
            }
          }

          await auth0Client.checkSession()
          const isLoggedIn = await auth0Client.isAuthenticated()

          if (isLoggedIn) {
            const user = await auth0Client.getUser()
            const token = await auth0Client.getTokenSilently()
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user: {
                  id: user?.sub,
                  email: user?.email,
                },
                token: token,
              },
            })
          } else {
            await auth0Client.loginWithRedirect({
              openUrl(url) {
                window.location.replace(url)
              },
              appState: {
                targetUrl: window.location.pathname + window.location.search,
              },
            })
          }
        }
      } catch (err) {
        console.error('Error during Auth0 session check:', err)
        if (!state.isInitialized) {
          await auth0Client.loginWithRedirect({
            openUrl(url) {
              window.location.replace(url)
            },
            appState: {
              targetUrl: window.location.pathname + window.location.search,
            },
          })
        }
      }
    }

    initAuth0()
  }, [state.isInitialized])

  const login = async (options?: KeyedObject) => {
    await auth0Client.loginWithPopup(options)
    const isLoggedIn = await auth0Client.isAuthenticated()

    if (isLoggedIn) {
      const user = await auth0Client.getUser()
      const token = await auth0Client.getTokenSilently()
      dispatch({
        type: LOGIN,
        payload: {
          isLoggedIn: true,
          user: {
            id: user?.sub,
            avatar: user?.picture,
            email: user?.email,
            name: user?.name,
            tier: 'Premium',
          },
          token: token,
        },
      })
    }
  }

  const logout = () => {
    auth0Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    })

    dispatch({
      type: LOGOUT,
    })
  }

  const resetPassword = async (email: string) => {}

  const updateProfile = () => {}

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />
  }

  return (
    <Auth0Context.Provider value={{ ...state, login, logout, resetPassword, updateProfile }}>
      {children}
    </Auth0Context.Provider>
  )
}

export default Auth0Context
