// LoginSessionsSection.tsx
import { CircularProgress, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import useAuth from 'hooks/useAuth'
import { useLoginHistory } from 'hooks/useLoginHistory'
import React from 'react'

const LoginSessionsSection: React.FC = () => {
  const { token } = useAuth()
  const { loginHistory, isLoading, isError } = useLoginHistory(token!)

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError) {
    return <div>Error fetching login sessions</div>
  }

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Login Sessions
      </Typography>
      <Paper style={{ width: '100%', overflowX: 'auto', marginBottom: '20px' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Location</TableCell>
              <TableCell>Device</TableCell>
              <TableCell>IP Address</TableCell>
              <TableCell>Time</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loginHistory.map((session, index) => (
              <TableRow key={index}>
                <TableCell>{session.location}</TableCell>
                <TableCell>{session.device}</TableCell>
                <TableCell>{session.ipAddress}</TableCell>
                <TableCell>{session.time}</TableCell>
                <TableCell align="right">{session.action}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default LoginSessionsSection
