import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CardActions, CardContent, Grid, IconButton, Slider, Theme, Typography, useTheme } from '@mui/material'
import AcceleratorSelector from 'components/AcceleratorSelector'
import GradientPaper from 'components/cards/GradientPaper'
import { useCreatePlanContext } from 'contexts/CreatePlanContextProvider'
import React, { useEffect } from 'react'
import { VariableAllocationRow, VariableAllocationTable } from 'types/comp-allocations' // Adjust the import path as needed

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
}

const AllocationTable: React.FC<{
  data: VariableAllocationTable
  tableIndex: number
  updateAllocationPercent: (tableIndex: number, rowIndex: number, value: number) => void
  handleEditAcheivementMultiplier: (tableIndex: number, rowIndex: number, newMultiplier: string) => void
  toggleDisabled: (tableIndex: number, rowIndex: number) => void
  theme: Theme
  baseSalary: number
  variablePercent: number
  reviewOnly: boolean // New prop to control visibility of disabled rows
}> = ({
  data,
  tableIndex,
  updateAllocationPercent,
  handleEditAcheivementMultiplier,
  toggleDisabled,
  theme,
  baseSalary,
  variablePercent,
  reviewOnly,
}) => {
  // Filter rows based on the showDisabledRows prop
  const visibleRows = data.rows.filter((row) => {
    if (reviewOnly) {
      return row.disabled == undefined || row.disabled == null || !row.disabled
    } else {
      return true
    }
  })

  // Calculate the total payout for visible rows
  const totalPayout = visibleRows.reduce((acc, row) => {
    const payoutValue = baseSalary * row.allocationPercentage * variablePercent
    return acc + payoutValue
  }, 0)

  // Do not render the table if all rows are disabled and showDisabledRows is false
  if (reviewOnly && visibleRows.length === 0) return null

  return (
    <GradientPaper sx={{ marginTop: 3 }} gradientScale={0.5}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={reviewOnly ? 4 : 3}>
            <Typography variant="h3">{data.label}</Typography>
          </Grid>
          <Grid item xs={4} style={{ paddingRight: '30px' }}>
            <Typography variant="h5" align="center">
              Allocation %
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h5" align="center">
              Accelerator
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h5" align="center">
              On-Target Payout
            </Typography>
          </Grid>
        </Grid>
        {visibleRows.map((row, index) => (
          <AllocationRow
            key={index}
            row={row}
            tableIndex={tableIndex}
            rowIndex={index}
            updateAllocationPercent={updateAllocationPercent}
            handleEditAcheivementMultiplier={handleEditAcheivementMultiplier}
            toggleDisabled={toggleDisabled}
            baseSalary={baseSalary}
            variablePercent={variablePercent}
            theme={theme}
            reviewOnly={reviewOnly}
          />
        ))}
      </CardContent>
      <CardActions
        style={{
          justifyContent: 'flex-end',
          paddingRight: reviewOnly ? '22px' : '16px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={reviewOnly ? 10 : 8}>
            <Typography variant="h5" align="right">
              On-Target Total:
            </Typography>
          </Grid>
          <Grid item xs={reviewOnly ? 2 : 4}>
            <Typography variant="h5" align="center">
              {formatCurrency(totalPayout)}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </GradientPaper>
  )
}

const AllocationRow: React.FC<{
  row: VariableAllocationRow
  tableIndex: number
  rowIndex: number
  updateAllocationPercent: (tableIndex: number, rowIndex: number, value: number) => void
  handleEditAcheivementMultiplier: (tableIndex: number, rowIndex: number, newMultiplier: string) => void
  toggleDisabled: (tableIndex: number, rowIndex: number) => void
  baseSalary: number
  variablePercent: number
  theme: Theme
  reviewOnly: boolean
}> = ({
  row,
  tableIndex,
  rowIndex,
  updateAllocationPercent,
  handleEditAcheivementMultiplier,
  toggleDisabled,
  baseSalary,
  variablePercent,
  theme,
  reviewOnly,
}) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const rowStyle = row.disabled ? { color: 'gray' } : {}

  // Define marks for min, max, and current value, converting decimal to percentage
  const marks = [
    {
      value: (row.minAllocationPercentage ?? 0) * 100,
      label: `${((row.minAllocationPercentage ?? 0) * 100).toFixed(0)}%`,
    },
    {
      value: (row.allocationPercentage ?? 0) * 100,
      label: `${((row.allocationPercentage ?? 0) * 100).toFixed(0)}%`,
    },
    {
      value: (row.maxAllocationPercentage ?? 1) * 100,
      label: `${((row.maxAllocationPercentage ?? 1) * 100).toFixed(0)}%`,
    },
  ]

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={reviewOnly ? 4 : 3} style={{ display: 'flex', alignItems: 'center' }}>
        {!row.disabled && (
          <IconButton onClick={handleExpandClick} style={{ marginRight: '8px' }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <Typography style={rowStyle}>{row.label}</Typography>
      </Grid>
      <Grid
        item
        xs={4}
        // style={{ paddingRight: '30px' }}
      >
        {!row.disabled && (
          <Slider
            value={row.allocationPercentage * 100} // Convert decimal to percentage for the slider value
            min={(row.minAllocationPercentage ?? 0) * 100} // Convert decimal to percentage for min
            max={(row.maxAllocationPercentage ?? 1) * 100} // Convert decimal to percentage for max
            valueLabelDisplay="off" // Disable the tooltip
            step={1}
            marks={marks}
            onChange={(_, value) => {
              if (reviewOnly) {
                return
              }
              const newValue = Array.isArray(value) ? value[0] : value
              // Convert percentage back to decimal before updating
              updateAllocationPercent(tableIndex, rowIndex, newValue / 100)
            }}
          />
        )}
      </Grid>
      <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex' }}>
        {!row.disabled && (
          <AcceleratorSelector
            value={row.acheivementMultiplier}
            setValue={(newValue) => handleEditAcheivementMultiplier(tableIndex, rowIndex, newValue)}
            incrementAmount={0.1}
            reviewOnly={reviewOnly}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {!row.disabled && (
          <Typography align="center">
            {formatCurrency(baseSalary * row.allocationPercentage * variablePercent)}
          </Typography>
        )}
      </Grid>
      {!reviewOnly && (
        <Grid item xs={1}>
          <IconButton onClick={() => toggleDisabled(tableIndex, rowIndex)} style={{ color: 'inherit' }}>
            {row.disabled ? (
              <PlusCircleOutlined style={{ color: theme.palette.primary.main }} />
            ) : (
              <MinusCircleOutlined style={{ color: theme.palette.warning.main }} />
            )}
          </IconButton>
        </Grid>
      )}
      {expanded && !row.disabled && (
        <Grid item xs={12}>
          <Typography variant="body2">Additional details for {row.label}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

interface PlanAllocationTablesProps {
  reviewOnly?: boolean
}

const PlanAllocationTables: React.FC<PlanAllocationTablesProps> = ({ reviewOnly = false }) => {
  const theme = useTheme()

  const { compensationTemplate, templateComponentsInPlan, setTemplateComponentsInPlan, plan, setPlan } =
    useCreatePlanContext()

  useEffect(() => {
    if (plan.variableAllocations.length === 0 && compensationTemplate !== undefined && compensationTemplate !== null) {
      plan.variableAllocations = compensationTemplate.template.variableAllocations
      setPlan(plan)
      setTemplateComponentsInPlan(compensationTemplate.template.variableAllocations)
    }
  }, [[templateComponentsInPlan, plan]])

  const updateRowProperty = (
    tableIndex: number,
    rowIndex: number,
    updateFunction: (row: VariableAllocationRow) => void
  ) => {
    if (
      plan &&
      plan.variableAllocations &&
      plan.variableAllocations[tableIndex] &&
      plan.variableAllocations[tableIndex].rows &&
      plan.variableAllocations[tableIndex].rows[rowIndex]
    ) {
      const newPlan = { ...plan }
      const row = newPlan.variableAllocations[tableIndex].rows[rowIndex]
      updateFunction(row) // Apply the update function directly to the row
      setPlan(newPlan)
      setTemplateComponentsInPlan(newPlan.variableAllocations)
    } else {
      console.error('Invalid tableIndex or rowIndex, or plan is undefined/null.')
    }
  }

  const baseSalary = plan.baseSalary
  const variablePercent = plan.variablePercent

  const allocations =
    plan.variableAllocations.length > 0
      ? plan.variableAllocations
      : compensationTemplate != undefined && compensationTemplate != null
        ? compensationTemplate.template.variableAllocations
        : []

  return (
    <div style={{ width: '100%', marginLeft: reviewOnly ? '16px' : '0px' }}>
      {allocations.map((table, index) => (
        <AllocationTable
          key={index}
          data={table}
          tableIndex={index}
          baseSalary={baseSalary}
          variablePercent={variablePercent}
          updateAllocationPercent={(tableIndex, rowIndex, value) =>
            updateRowProperty(tableIndex, rowIndex, (row) => {
              row.allocationPercentage = value
            })
          }
          handleEditAcheivementMultiplier={(tableIndex, rowIndex, newMultiplier) =>
            updateRowProperty(tableIndex, rowIndex, (row) => {
              row.acheivementMultiplier = newMultiplier
            })
          }
          toggleDisabled={(tableIndex, rowIndex) =>
            updateRowProperty(tableIndex, rowIndex, (row) => {
              if (row.disabled == undefined || row.disabled == null) {
                row.disabled = false
              }

              row.disabled = !row.disabled
            })
          }
          theme={theme}
          reviewOnly={reviewOnly}
        />
      ))}
    </div>
  )
}

export default PlanAllocationTables
