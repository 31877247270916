import { CompensationPlanCreateDTO } from 'types/comp-plan'

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

export function createPlan(token: string, plan: CompensationPlanCreateDTO) {
  return fetch(apiServerUrl + 'compensation/plans', {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'content-Type': 'application/json',
    }),
    body: JSON.stringify(plan),
  }).then((res) => res.json())
}
