import {
  AuditOutlined,
  EditOutlined,
  EyeOutlined,
  FormOutlined,
  PlusOutlined,
  SearchOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useSearchUsersWithPlanInfo } from 'hooks/useUsers'
import * as React from 'react'
import { startTransition, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Permissions } from 'types/permissions'
import { KeyedObject } from 'types/root'
import { UserWithPlanInfo } from 'types/user'
import { getOrganizationIdsWithPermissions, getOrganizationLabelMap } from 'types/user-profile'

// Debounce function
const debounce = (func: Function, delay: number) => {
  let timeoutId: NodeJS.Timeout
  return (...args: any[]) => {
    if (timeoutId) clearTimeout(timeoutId)
    timeoutId = setTimeout(() => {
      func(...args)
    }, delay)
  }
}

const Organization: React.FC = () => {
  const [search, setSearch] = useState('')
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [planFilters, setPlanFilters] = useState<string[]>(['withoutPlans', 'unapproved', 'approved'])

  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const {
    users = [],
    isLoading,
    pagination = { totalItems: 0, totalPages: 1 },
  } = useSearchUsersWithPlanInfo(token!, {
    searchTerm: search,
    organizationIds: selectedOrganizations,
    perPage: rowsPerPage,
    page: page + 1,
    includeWithoutPlans: planFilters.includes('withoutPlans'),
    includeUnapproved: planFilters.includes('unapproved'),
    includeApproved: planFilters.includes('approved'),
  })

  const organizationIdsWithMembersWrite =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.MEMBERS_WRITE]) : []
  const organizationIdsWithPlansRead =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.PLANS_READ]) : []
  const organizationIdsWithPlanWrite =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.PLANS_WRITE]) : []

  const canManageTeamStructure = organizationIdsWithMembersWrite.length > 0
  const canManagePlans = organizationIdsWithPlansRead.length > 0

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value)
    }, 300),
    []
  )

  const handleFilterChange = (event: SelectChangeEvent<string[]>) => {
    setPlanFilters(event.target.value as string[])
  }

  // table columns
  interface ColumnProps {
    id: string
    label: string
    minWidth: number
    align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
    format?: (value: any, row: any) => string | boolean | JSX.Element
  }

  const navigate = useNavigate()

  const columns: ColumnProps[] = [
    { id: 'name', label: 'Name', minWidth: 200, format: (value: any, row: any) => `${row.firstName} ${row.lastName}` },
    {
      id: 'organization',
      label: 'Team',
      minWidth: 170,
      format: (value: any, row: UserWithPlanInfo) => row.organizationName,
    },
    { id: 'position', label: 'Position', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 100,
      format: (userId: string, row: UserWithPlanInfo) => {
        const hasApprovedPlan = row.planApproved
        const hasUnapprovedPlan = row.approvalBatchId != null && !row.planApproved
        const hasNoPlan = !row.approvalBatchId

        return (
          <Box display="flex">
            {hasApprovedPlan && organizationIdsWithPlansRead.includes(row.organizationId) && (
              <Tooltip title="View Employee Compensation Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/employee/${userId}/compplan`)
                    })
                  }}
                >
                  <EyeOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasUnapprovedPlan && (
              <Tooltip title="Review Unapproved Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      // Note: You'll need to replace this with the actual way to get the approval ID
                      navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                    })
                  }}
                >
                  <AuditOutlined />
                </IconButton>
              </Tooltip>
            )}
            {hasNoPlan && organizationIdsWithPlanWrite.includes(row.organizationId) && (
              <Tooltip title="Create New Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/admin/templates/select/${userId}`)
                    })
                  }}
                >
                  <FormOutlined />
                </IconButton>
              </Tooltip>
            )}
            {!hasNoPlan && organizationIdsWithPlanWrite.includes(row.organizationId) && (
              <Tooltip title="Edit Employee Compensation Plan">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/create-comp-plan?userId=${userId}`)
                    })
                  }}
                >
                  <EditOutlined />
                </IconButton>
              </Tooltip>
            )}
            {organizationIdsWithMembersWrite.includes(row.organizationId) && (
              <Tooltip title="Edit Employee Profile">
                <IconButton
                  color="primary"
                  onClick={() => {
                    startTransition(() => {
                      navigate(`/admin/users/profile?userId=${userId}`)
                    })
                  }}
                >
                  <UserOutlined />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )
      },
    },
  ]

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={2}>
            <Typography variant="h2">Team</Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent={canManageTeamStructure ? 'flex-start' : 'flex-end'}
            >
              <Grid item xs={12} md={canManageTeamStructure ? (canManagePlans ? 4 : 5) : canManagePlans ? 5 : 6}>
                <TextField
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchOutlined />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    userSelect: 'none',
                    width: '100%',
                    '& .MuiInputLabel-root': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  variant="standard"
                  onChange={(e) => debouncedSetSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={canManageTeamStructure ? (canManagePlans ? 3 : 4) : canManagePlans ? 4 : 6}>
                <MultiOrganizationSelector
                  userProfile={profile}
                  selectedOrganizationIds={selectedOrganizations}
                  handleChange={setSelectedOrganizations}
                />
              </Grid>
              {canManagePlans && (
                <Grid item xs={12} md={canManageTeamStructure ? 2 : 3}>
                  <Select
                    labelId="filter-label"
                    variant="standard"
                    multiple
                    value={planFilters}
                    onChange={handleFilterChange}
                    renderValue={(selected) => {
                      const count = selected.length
                      return `Plan Filters (${count})`
                    }}
                    fullWidth
                  >
                    {[
                      { value: 'withoutPlans', label: 'No Plan' },
                      { value: 'unapproved', label: 'Plan Not Approved' },
                      { value: 'approved', label: 'Plan Approved' },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Checkbox checked={planFilters.indexOf(option.value) > -1} />
                        <ListItemText primary={option.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {canManageTeamStructure && (
                <Grid item xs={12} md={canManagePlans ? 3 : 3}>
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<TeamOutlined />}
                    onClick={() => navigate('/admin/organizations/manage')}
                    sx={{ borderColor: 'transparent', whiteSpace: 'nowrap', width: '100%' }}
                  >
                    Manage Team Structure
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid container spacing={12}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex', // Enables flexbox
                justifyContent: 'center', // Centers horizontally
                alignItems: 'center', // Centers vertically
                height: '100%', // Takes full height of the container
              }}
            >
              <CircularProgress color="primary" />
              Loading
            </Grid>
          </Grid>
        ) : (
          <Paper>
            {/* table */}
            <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
              <Table stickyHeader size="small" aria-label="sticky table">
                <TableHead
                  sx={{
                    '& th': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `2px solid ${theme.palette.divider} !important`,
                    },
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        id={'cell-col-' + (column.id || '')}
                        sx={{
                          minWidth: column.minWidth,
                          position: 'sticky !important',
                        }}
                        key={column.id}
                        align={column.align}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.length > 0 ? (
                    users.map((row: KeyedObject) => (
                      <TableRow
                        id={'table-row-' + row.id}
                        sx={{ py: 3 }}
                        role="checkbox"
                        tabIndex={-1}
                        key={'table-row-' + row.id}
                      >
                        {columns.map((column) => {
                          let value
                          if (column.id === 'name') {
                            value = `${row.firstName} ${row.lastName}`
                          } else if (column.id === 'organization') {
                            const orgId = row.metadata?.organizationId
                            value = orgId ? organizationMap[orgId] || '' : ''
                          } else {
                            value = row[column.id]
                          }
                          return (
                            <TableCell id={'cell-' + column.id} key={'cell-' + column.id} align={column.align}>
                              {column.format ? column.format(value, row) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                  {canManageTeamStructure && (
                    <TableRow
                      id={'create-new-team-member'}
                      sx={{ py: 3 }}
                      role="checkbox"
                      tabIndex={-1}
                      key={'create-new-team-member'}
                    >
                      <TableCell colSpan={columns.length} align="left">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            startTransition(() => {
                              navigate('/admin/users/profile')
                            })
                          }
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            sx={{ whiteSpace: 'nowrap', marginLeft: 13 }}
                          >
                            <PlusOutlined style={{ marginRight: 8, marginLeft: 75 }} />
                            Create New Team Member
                          </Box>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[50, 100, 200]}
              component="div"
              count={pagination.totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </div>
    </>
  )
}

export default Organization
