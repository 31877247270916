import { MouseEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// material-ui
import {
  Box,
  ButtonBase,
  IconButton,
  IconButtonProps,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material'
import { Theme, styled, useTheme } from '@mui/material/styles'

// project import
import { useGetMenuMaster } from 'api/menu'
import Avatar from 'components/@extended/Avatar'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'

// assets
import { LogoutOutlined, RightOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'

// hooks
import { useHasAnyOrganizationPermissions } from 'hooks/useOrganizations'

// types
import { ThemeMode } from 'types/config'
import { Permissions } from 'types/permissions'

interface ExpandMoreProps extends IconButtonProps {
  theme: Theme
  expand: boolean
  drawerOpen: boolean
}

const ExpandMore = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'theme' && prop !== 'expand' && prop !== 'drawerOpen',
})(({ theme, expand, drawerOpen }: ExpandMoreProps) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(-90deg)',
  marginLeft: 'auto',
  color: theme.palette.secondary.dark,
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  ...(!drawerOpen && {
    opacity: 0,
    width: 50,
    height: 50,
  }),
}))

const NavUser = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { logout, user, token } = useAuth()
  const { profile } = useProfile(token!)

  const { menuMaster } = useGetMenuMaster()
  const drawerOpen = menuMaster.isDashboardDrawerOpened

  const { hasPermissions } = useHasAnyOrganizationPermissions([Permissions.ORGANIZATION_WRITE])

  const avatar = user?.avatar
  const handleLogout = async () => {
    try {
      await logout()
      navigate(`/login`, {
        state: {
          from: '',
        },
      })
    } catch (err) {
      console.error(err)
    }
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleNavigateToProfile = () => {
    navigate('/profile')
    handleClose()
  }

  const handleNavigateToAccount = () => {
    navigate('/company-settings')
    handleClose()
  }

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  return (
    <ButtonBase
      onClick={handleClick}
      sx={{
        width: '100%', // Ensure the ButtonBase covers the entire Box area
        borderRadius: 'inherit', // Maintain any border radius set on the Box
      }}
    >
      <Box
        sx={{
          p: 1.25,
          px: !drawerOpen ? 1.25 : 3,
          borderTop: `2px solid ${theme.palette.divider}`,
          width: '100%', // Ensure the Box fills the ButtonBase
        }}
      >
        <List disablePadding>
          <ListItem
            disablePadding
            secondaryAction={
              <ExpandMore
                theme={theme}
                expand={open}
                drawerOpen={drawerOpen}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                aria-label="show more"
              >
                <RightOutlined style={{ fontSize: '0.625rem' }} />
              </ExpandMore>
            }
            sx={{
              '& .MuiListItemSecondaryAction-root': {
                right: !drawerOpen ? -20 : -16,
              },
            }}
          >
            <ListItemAvatar>
              <Avatar alt="profile user" src={avatar} sx={{ ...(drawerOpen && { width: 46, height: 46 }) }} />
            </ListItemAvatar>
            <ListItemText primary={user?.name} secondary={profile?.user?.firstName + ' ' + profile?.user?.lastName} />
          </ListItem>
        </List>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiMenuItem-root:hover': {
              bgcolor: hoverColor,
            },
          }}
        >
          <MenuItem component={Link} to="#" onClick={handleNavigateToProfile}>
            <ListItemIcon>
              <UserOutlined />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </MenuItem>

          {hasPermissions && (
            <MenuItem component={Link} to="#" onClick={handleNavigateToAccount}>
              <ListItemIcon>
                <SettingOutlined />
              </ListItemIcon>
              <ListItemText primary="Company Settings" />
            </MenuItem>
          )}

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutOutlined />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Menu>
      </Box>
    </ButtonBase>
  )
}

export default NavUser
