// types
import { PalettesProps } from '@ant-design/colors'
import { PaletteColorOptions } from '@mui/material/styles'
import { ThemeMode } from 'types/config'
import { PaletteThemeProps } from 'types/theme'

const ThemePurcent = (colors: PalettesProps, mode: ThemeMode): PaletteThemeProps => {
  const { grey } = colors
  const greyColors: PaletteColorOptions = {
    0: grey[0],
    50: grey[1],
    100: grey[2],
    200: grey[3],
    300: grey[4],
    400: grey[5],
    500: grey[6],
    600: grey[7],
    700: grey[8],
    800: grey[9],
    900: grey[10],
    A50: grey[15],
    A100: grey[11],
    A200: grey[12],
    A400: grey[13],
    A700: grey[14],
    A800: grey[16],
  }
  let contrastText = '#000000'

  let primaryColors = [
    '#F5F5F5',
    '#D5EDE8',
    // '#B5EADF',
    '#17B09314',
    '#7DE3CF',
    '#1DD3B0',
    '#17B093',
    '#14957C',
    '#087575',
    '#0C4C4C',
    '#193C4D',
  ]
  let secondaryColors = [
    '#F8F8F8',
    '#D5EDE8',
    '#B5EADF',
    '#7DE3CF',
    '#1DD3B0',
    '#F8F8F8',
    '#F5F5F5',
    '#087575',
    '#D9D9D9',
    '#193C4D',
  ]
  let errorColors = ['#FDE8E7', '#F25E52', '#F04134', '#EE3B2F', '#E92A21']
  let warningColors = ['#FFF7E0', '#FFC926', '#FFBF00', '#FFB900', '#FFA900']
  let infoColors = ['#E0F4F5', '#26B0BA', '#00A2AE', '#009AA7', '#008694']
  let successColors = ['#E0F5EA', '#26B56E', '#00A854', '#00A04D', '#008D3A']

  let colorIndex = {
    lighter: 0,
    100: 1,
    200: 2,
    light: 3,
    400: 4,
    main: 5,
    dark: 6,
    700: 7,
    darker: 8,
    900: 9,
  }

  if (mode === ThemeMode.DARK) {
    primaryColors = [
      '#193C4D',
      '#17B09329',
      '#087575',
      '#14957C',
      '#17B093',
      '#1DD3B0',
      '#7DE3CF',
      '#B5EADF',
      '#D5EDE8',
      '#F5F5F5',
    ]
    secondaryColors = [
      '#1E1E1E',
      '#D5EDE8',
      '#B5EADF',
      '#7DE3CF',
      '#1DD3B0',
      '#1E1E1E',
      '#434343',
      '#087575',
      '#2D2D2D',
      '#17B09329',
    ]

    // Reverse the index for dark mode
    colorIndex = {
      lighter: 9,
      100: 8,
      200: 7,
      light: 6,
      400: 5,
      main: 4,
      dark: 3,
      700: 2,
      darker: 1,
      900: 0,
    }

    errorColors = ['#321d1d', '#7d2e28', '#d13c31', '#e66859', '#f8baaf']
    warningColors = ['#342c1a', '#836611', '#dda705', '#e9bf28', '#f8e577']
    infoColors = ['#1a2628', '#11595f', '#058e98', '#1ea6aa', '#64cfcb']
    successColors = ['#1a2721', '#115c36', '#05934c', '#1da65d', '#61ca8b']
    contrastText = '#FFFFFF'
  }

  return {
    primary: {
      lighter: primaryColors[colorIndex.lighter],
      100: primaryColors[colorIndex[100]],
      200: primaryColors[colorIndex[200]],
      light: primaryColors[colorIndex.light],
      400: primaryColors[colorIndex[400]],
      main: primaryColors[colorIndex.main],
      dark: primaryColors[colorIndex.dark],
      700: primaryColors[colorIndex[700]],
      darker: primaryColors[colorIndex.darker],
      900: primaryColors[colorIndex[900]],
      contrastText,
    },
    secondary: {
      lighter: secondaryColors[colorIndex.lighter],
      100: secondaryColors[colorIndex[100]],
      200: secondaryColors[colorIndex[200]],
      light: secondaryColors[colorIndex.light],
      400: secondaryColors[colorIndex[400]],
      main: secondaryColors[colorIndex.main],
      dark: secondaryColors[colorIndex.dark],
      700: secondaryColors[colorIndex[700]],
      darker: secondaryColors[colorIndex.darker],
      900: secondaryColors[colorIndex[900]],
      contrastText,
    },
    error: {
      lighter: errorColors[0],
      light: errorColors[1],
      main: errorColors[2],
      dark: errorColors[3],
      darker: errorColors[4],
      contrastText,
    },
    warning: {
      lighter: warningColors[0],
      light: warningColors[1],
      main: warningColors[2],
      dark: warningColors[3],
      darker: warningColors[4],
      contrastText: greyColors[100],
    },
    info: {
      lighter: infoColors[0],
      light: infoColors[1],
      main: infoColors[2],
      dark: infoColors[3],
      darker: infoColors[4],
      contrastText,
    },
    success: {
      lighter: successColors[0],
      light: successColors[1],
      main: successColors[2],
      dark: successColors[3],
      darker: successColors[4],
      contrastText,
    },
    grey: greyColors,
  }
}

export default ThemePurcent
