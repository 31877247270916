import { Box, LinearProgress, Paper, Typography, useTheme } from '@mui/material'
import { FC } from 'react'

interface TeamOverviewProps {
  userPeriodOverviews: any[]
}

const TeamOverview: FC<TeamOverviewProps> = ({ userPeriodOverviews }) => {
  const theme = useTheme()

  const calculateTotals = () => {
    let totalMaxAmount = 0
    let totalPayoutAmount = 0

    if (userPeriodOverviews) {
      userPeriodOverviews.forEach((user) => {
        user.planItems.forEach((item: any) => {
          totalMaxAmount += item.maxAmount
          totalPayoutAmount += item.payoutAmount
        })
      })
    }

    return { totalMaxAmount, totalPayoutAmount }
  }

  const { totalMaxAmount, totalPayoutAmount } = calculateTotals()

  const progress = (totalPayoutAmount / totalMaxAmount) * 100
  const formattedTotalPayout = totalPayoutAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  const formattedTotalMax = totalMaxAmount.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <Paper sx={{ mb: 2, padding: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Team Overview
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Total Variable Compensation Earned:</Typography>
        <Typography variant="h2" sx={{ color: theme.palette.success.main, fontWeight: 'bold' }}>
          ${formattedTotalPayout}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
        <Typography variant="h6">Total Maximum Variable Compensation:</Typography>
        <Typography variant="h4" sx={{ color: theme.palette.info.main, fontWeight: 'bold' }}>
          ${formattedTotalMax}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{ mt: 3, height: 20, borderRadius: 5, backgroundColor: theme.palette.grey[300] }}
      />
    </Paper>
  )
}

export default TeamOverview
