import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useAuth from 'hooks/useAuth'
import { useCreateLedgerAccount, useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useProfile } from 'hooks/useProfile'
import LedgerAccountDialog from 'pages/Admin/LedgerAccounts/ManageLedgerAccounts/LedgerAccountDialog'
import React, { useCallback, useEffect, useMemo, useState, useTransition } from 'react'
import { LedgerAccount, LedgerAccountDTO } from 'types/ledger-account'

const ManageLedgerAccounts: React.FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { profile, isLoading: isProfileLoading } = useProfile(token!)
  const [organizationId, setOrganizationId] = useState<string | null>(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<LedgerAccount | null>(null)
  const [isPending, startTransition] = useTransition()
  const [localLedgerAccounts, setLocalLedgerAccounts] = useState<LedgerAccount[]>([])

  useEffect(() => {
    if (profile && profile.roles) {
      const organizations = profile.roles.map((role) => role.organization)
      const rootOrgId = organizations[0]?.rootOrganizationId || null
      setOrganizationId(rootOrgId)
    }
  }, [profile])

  const {
    ledgerAccounts,
    isLoading: isLedgerAccountsLoading,
    refetch,
  } = useFetchLedgerAccounts(organizationId || '', token!, organizationId !== '')

  const { createLedgerAccount, isLoading: isCreating } = useCreateLedgerAccount(token!)

  useEffect(() => {
    if (ledgerAccounts) {
      setLocalLedgerAccounts(ledgerAccounts)
    }
  }, [ledgerAccounts])

  const handleChangePage = (event: unknown, newPage: number) => {
    startTransition(() => {
      setPage(newPage)
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    startTransition(() => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    })
  }

  const handleOpenDialog = useCallback((account: LedgerAccount | null = null) => {
    startTransition(() => {
      setSelectedAccount(account ? { ...account } : null)
      setDialogOpen(true)
    })
  }, [])

  const handleCloseDialog = useCallback(() => {
    startTransition(() => {
      setSelectedAccount(null)
      setDialogOpen(false)
      refetch()
    })
  }, [refetch])

  const handleDialogSubmit = useCallback(
    (newAccount: LedgerAccountDTO) => {
      if (selectedAccount) {
        // Handle update case
        // ...
      } else {
        createLedgerAccount(newAccount)
          .then((createdAccount) => {
            setLocalLedgerAccounts((prev) => [...prev, createdAccount])
            setDialogOpen(false)
            refetch() // Optional: refetch to ensure server-side consistency
          })
          .catch((error) => {
            // Handle error (e.g., show an error message)
            console.error('Failed to create ledger account:', error)
          })
      }
      setSelectedAccount(null)
    },
    [selectedAccount, createLedgerAccount, refetch]
  )

  const columns = useMemo(
    () => [
      { id: 'externalId', label: 'External ID', minWidth: 100 },
      { id: 'label', label: 'Label', minWidth: 170 },
      {
        id: 'actions',
        label: 'Actions',
        minWidth: 70,
        align: 'center',
        format: (value: any, row: LedgerAccount) => (
          <IconButton color="primary" onClick={() => handleOpenDialog(row)}>
            <EditOutlined />
          </IconButton>
        ),
      },
    ],
    [handleOpenDialog]
  )

  const paginatedLedgerAccounts = useMemo(() => {
    const accounts = Array.isArray(localLedgerAccounts) ? localLedgerAccounts : []
    return accounts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  }, [localLedgerAccounts, page, rowsPerPage])

  if (isProfileLoading || !organizationId) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  if (isLedgerAccountsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h2">Ledger Accounts</Typography>
      </Box>
      <Paper>
        {isPending && (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <CircularProgress />
          </Box>
        )}
        <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
          <Table stickyHeader size="small" aria-label="sticky table">
            <TableHead
              sx={{
                '& th': {
                  borderTop: `1px solid ${theme.palette.divider}`,
                  borderBottom: `2px solid ${theme.palette.divider} !important`,
                },
              }}
            >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      minWidth: column.minWidth,
                      position: 'sticky !important',
                      textAlign: column.align || 'left',
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedLedgerAccounts.map((row) => {
                return (
                  <TableRow role="checkbox" tabIndex={-1} key={row.id} sx={{ py: 3 }}>
                    {columns.map((column) => {
                      const value = row[column.id as keyof LedgerAccount]
                      return (
                        <TableCell key={column.id} align={column.align as 'left' | 'center' | 'right' | undefined}>
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
              <TableRow role="checkbox" tabIndex={-1} key="add-new" sx={{ py: 3 }}>
                <TableCell colSpan={columns.length} align="left">
                  <Button
                    variant="text"
                    color="primary"
                    startIcon={<PlusOutlined />}
                    onClick={() => handleOpenDialog()}
                    sx={{ whiteSpace: 'nowrap', marginLeft: 0 }}
                  >
                    Add Ledger Account
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[100, 250, 500]}
          component="div"
          count={Array.isArray(localLedgerAccounts) ? localLedgerAccounts.length : 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <LedgerAccountDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSubmit={handleDialogSubmit}
        account={selectedAccount}
        organizationId={organizationId}
        isLoading={isCreating}
      />
    </>
  )
}

export default React.memo(ManageLedgerAccounts)
