import { DownOutlined, EyeOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import { postWithToken } from 'hooks/http'
import { useAccruals } from 'hooks/useAccruals'
import useAuth from 'hooks/useAuth'
import { useFetchAllLedgerAccounts } from 'hooks/useLedgerAccounts'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { debounce } from 'lodash'
import { useSnackbar } from 'notistack'
import React, { Suspense, useCallback, useEffect, useState, useTransition } from 'react'
import { AccrualsQueryParams } from 'types/accruals'
import { ThemeMode } from 'types/config'
import { LedgerAccount } from 'types/ledger-account'
import { Period, createIdLabelMap } from 'types/periods'
import { UserRole } from 'types/role'
import AccrualDetails from './AccrualDetails'

interface Column {
  id: string
  label: string
  width: number
  format?: (row: any) => React.ReactNode
}

const ManageAccruals: React.FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [periods, setPeriods] = useState<Period[]>([])
  const [isPending, startTransition] = useTransition()
  const [periodLabels, setPeriodLabels] = useState<Record<string, string>>({})
  const [status, setStatus] = useState<AccrualsQueryParams['status']>('Draft')
  const [selectedAccrual, setSelectedAccrual] = useState<any | null>(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [selectedLedgerAccount, setSelectedLedgerAccount] = useState<string>('')
  const [expandedEmployees, setExpandedEmployees] = useState<Set<string>>(new Set())
  const [periodsInitialized, setPeriodsInitialized] = useState(false)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isQueueing, setIsQueueing] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const hoverColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  const { ledgerAccounts, isLoading: isLoadingLedgerAccounts } = useFetchAllLedgerAccounts(
    selectedOrganizations,
    token!,
    selectedOrganizations.length > 0 && !!token
  )

  const { periods: fetchedPeriods, isLoadingPeriods } = usePeriods(
    token!,
    selectedOrganizations[0] || '',
    !!token && selectedOrganizations.length > 0
  )

  useEffect(() => {
    if (fetchedPeriods) {
      setPeriods(fetchedPeriods.periods || [])

      // Select the first root period if available
      if (
        fetchedPeriods.periods &&
        fetchedPeriods.periods.length > 0 &&
        selectedPeriods.length === 0 &&
        !periodsInitialized
      ) {
        setSelectedPeriods([fetchedPeriods.periods[0].id!])
        setPeriodsInitialized(true)
      }
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (fetchedPeriods && fetchedPeriods.periods.length > 0) {
      const rootPeriod = fetchedPeriods.periods[0]
      const idLabelMap = createIdLabelMap(rootPeriod)
      setPeriodLabels(idLabelMap)
    }
  }, [fetchedPeriods])

  useEffect(() => {
    if (profile.roles.length > 0 && !isInitialized) {
      const allOrganizationIds = profile.roles.map((role) => role.organization.id)
      setSelectedOrganizations(allOrganizationIds)
      setIsInitialized(true)
    }
  }, [profile, isInitialized])

  const queryParams: AccrualsQueryParams = {
    organizationIds: selectedOrganizations,
    periodIds: selectedPeriods,
    status: status,
    searchTerm: search,
    page: page + 1,
    perPage: rowsPerPage,
  }

  const {
    accruals = [],
    pagination,
    isLoading,
    isError,
  } = useAccruals(
    token!,
    queryParams,
    !!token && selectedOrganizations.length > 0 && selectedPeriods.length > 0 && isInitialized
  )

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    startTransition(() => {
      setPage(newPage)
    })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    startTransition(() => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    })
  }

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      startTransition(() => {
        setSearch(value)
        setPage(0)
      })
    }, 300),
    []
  )

  const organizationLabels: Record<string, string> = profile.roles.reduce(
    (acc: Record<string, string>, role: UserRole) => {
      acc[role.organization.id] = role.organization.name
      return acc
    },
    {} as Record<string, string>
  )

  const ledgerAccountLabels: Record<string, string> =
    ledgerAccounts?.reduce(
      (acc: Record<string, string>, ledgerAccount: LedgerAccount) => {
        acc[ledgerAccount.id] = ledgerAccount.label
        return acc
      },
      {} as Record<string, string>
    ) || {}

  const [selected, setSelected] = useState<string[]>([])

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = accruals.map((n) => n.id)
      setSelected(newSelected)
      return
    }
    setSelected([])
  }

  const isAllSelected = accruals.length > 0 && selected.length === accruals.length

  const handleClick = (event: React.MouseEvent<unknown> | React.ChangeEvent<HTMLInputElement>, id: string) => {
    event.stopPropagation() // Prevent event bubbling
    const selectedIndex = selected.indexOf(id)
    let newSelected: string[] = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }

    setSelected(newSelected)
  }

  const handleEmployeeRowClick = (event: React.MouseEvent<unknown>, employeeId: string) => {
    event.stopPropagation() // Prevent event bubbling
    toggleEmployeeExpansion(employeeId)
  }

  const handleEmployeeCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    employeeAccruals: typeof accruals
  ) => {
    event.stopPropagation() // Prevent event bubbling
    const isChecked = event.target.checked
    const newSelected = isChecked
      ? [...selected, ...employeeAccruals.map((accrual) => accrual.id).filter((id) => !selected.includes(id))]
      : selected.filter((id) => !employeeAccruals.map((accrual) => accrual.id).includes(id))
    setSelected(newSelected)
  }

  const isSelected = (id: string) => selected.indexOf(id) !== -1

  const handleViewAccrual = (accrual: any) => {
    setSelectedAccrual(accrual)
    setOpenDialog(true)
  }

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setSelectedAccrual(null)
  }

  const handleRunAccruals = () => {
    setOpenConfirmDialog(true)
  }

  const handleConfirmRunAccruals = async () => {
    setIsQueueing(true)
    try {
      const accrualIds = getSelectedAccruals().map((accrual) => accrual.id)
      await postWithToken('accruals/queue', token!, { accrualIds })

      enqueueSnackbar('Accruals queued successfully', { variant: 'success' })
      setOpenConfirmDialog(false)
      setSelected([]) // Clear selection after successful queue
      // Optionally, refresh the accruals data here
    } catch (error: any) {
      console.error('Failed to queue accruals:', error)
      enqueueSnackbar(error.message || 'Failed to queue accruals', { variant: 'error' })
    } finally {
      setIsQueueing(false)
    }
  }

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
  }

  const toggleEmployeeExpansion = (employeeId: string) => {
    setExpandedEmployees((prev) => {
      const newSet = new Set(prev)
      if (newSet.has(employeeId)) {
        newSet.delete(employeeId)
      } else {
        newSet.add(employeeId)
      }
      return newSet
    })
  }

  const groupedAccruals = accruals.reduce(
    (acc, accrual) => {
      const employeeId = `${accrual.userFirstName} ${accrual.userLastName}`.trim()
      if (!acc[employeeId]) {
        acc[employeeId] = []
      }
      acc[employeeId].push(accrual)
      return acc
    },
    {} as Record<string, typeof accruals>
  )

  // Create summary rows only for groups with more than one accrual
  const summaryRows = Object.entries(groupedAccruals)
    .filter(([_, employeeAccruals]) => employeeAccruals.length > 1)
    .map(([employeeId, employeeAccruals]) => {
      const totalAmount = employeeAccruals.reduce((sum, accrual) => sum + Number(accrual.payoutAmount), 0)
      return {
        id: employeeId,
        employeeId,
        lineItemCount: employeeAccruals.length,
        payoutAmount: totalAmount,
        userLedgerAccountId: employeeAccruals[0].userLedgerAccountId,
        periodId: employeeAccruals[0].periodId,
        status: employeeAccruals[0].status,
      }
    })

  const columns: Column[] = [
    {
      id: 'checkbox',
      label: 'Select',
      width: 65,
    },
    {
      id: 'employee',
      label: 'Employee',
      width: 200,
    },
    {
      id: 'account',
      label: 'Account',
      width: 150,
      format: (row) => ledgerAccountLabels[row.userLedgerAccountId] || '-',
    },
    {
      id: 'periodId',
      label: 'Period',
      width: 150,
      format: (row) => periodLabels[row.periodId] || row.periodId,
    },
    {
      id: 'status',
      label: 'Status',
      width: 100,
      format: (row) => row.status.charAt(0).toUpperCase() + row.status.slice(1),
    },
    {
      id: 'lineItem',
      label: 'Line Item',
      width: 200,
      format: (row) =>
        row.lineItemCount
          ? `(${row.lineItemCount}) Line Items`
          : `${row.allocationTableName} | ${row.allocationRowName}`,
    },
    {
      id: 'total',
      label: 'Total',
      width: 100,
      format: (row) =>
        row.payoutAmount !== null
          ? new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(Number(row.payoutAmount))
          : '-',
    },
    {
      id: 'actions',
      label: 'Actions',
      width: 100,
      format: (row) => (
        <IconButton onClick={() => handleViewAccrual(row)}>
          <EyeOutlined />
        </IconButton>
      ),
    },
  ]

  const handleOrganizationChange = (newSelectedOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newSelectedOrganizations)
      setPage(0)
    })
  }

  const handlePeriodChange = (newSelectedPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newSelectedPeriods)
      setPage(0)
    })
  }

  const getSelectedAccruals = () => {
    return accruals.filter((accrual) => selected.includes(accrual.id))
  }

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 2 }} alignItems="center">
        <Grid item xs={12} md={4}>
          <Typography variant="h2">Accruals</Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            key="search-field"
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            sx={{ width: '100%' }}
            variant="standard"
            onChange={(e) => debouncedSetSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {status === 'Draft' && (
            <Button variant="contained" color="primary" disabled={selected.length === 0} onClick={handleRunAccruals}>
              Run Accruals
            </Button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" color="text.secondary" sx={{ mb: 0.5 }}>
            Teams
          </Typography>
          <MultiOrganizationSelector
            key="organization-selector"
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={handleOrganizationChange}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant="caption" color="text.secondary" sx={{ mb: 0.5 }}>
            Periods
          </Typography>
          <MultiPeriodSelector
            key="period-selector"
            periods={periods}
            selectedPeriods={selectedPeriods}
            handleChange={handlePeriodChange}
            rootPeriodOnly={false}
            sortOrder="asc"
            timeFilter="pastOnly"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="standard">
            <Typography variant="caption" color="text.secondary" sx={{ mb: 0.5 }}>
              Status
            </Typography>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value as AccrualsQueryParams['status'])}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: hoverColor,
                    },
                  },
                },
              }}
            >
              <MenuItem value="Draft">Draft</MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
              <MenuItem value="Recorded">Recorded</MenuItem>
              <MenuItem value="Cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth variant="standard">
            <Typography variant="caption" color="text.secondary" sx={{ mb: 0.5 }}>
              Ledger Account
            </Typography>
            <Select
              value={selectedLedgerAccount}
              onChange={(e) => setSelectedLedgerAccount(e.target.value as string)}
              displayEmpty
              MenuProps={{
                PaperProps: {
                  sx: {
                    '& .MuiMenuItem-root:hover': {
                      backgroundColor: hoverColor,
                    },
                  },
                },
              }}
            >
              <MenuItem value="">All Ledger Accounts</MenuItem>
              {isLoadingLedgerAccounts ? (
                <MenuItem disabled>Loading...</MenuItem>
              ) : (
                ledgerAccounts?.map((account: LedgerAccount) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.label}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Suspense fallback={<CircularProgress />}>
        {isLoadingPeriods || isLoading || isPending ? (
          <CircularProgress />
        ) : isError ? (
          <Typography color="error">Error loading accruals</Typography>
        ) : (
          <Paper>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" align="center" style={{ width: columns[0].width }}>
                      <Checkbox
                        indeterminate={selected.length > 0 && selected.length < accruals.length}
                        checked={isAllSelected}
                        onChange={handleSelectAllClick}
                      />
                    </TableCell>
                    {columns.slice(1).map((column) => (
                      <TableCell key={column.id} style={{ width: column.width, minWidth: column.width }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(groupedAccruals).map(([employeeId, employeeAccruals]) => (
                    <React.Fragment key={employeeId}>
                      {employeeAccruals.length > 1 ? (
                        // Render summary row for groups with more than one accrual
                        <TableRow
                          onClick={(event) => handleEmployeeRowClick(event, employeeId)}
                          sx={{
                            backgroundColor: expandedEmployees.has(employeeId)
                              ? theme.palette.action.hover // Use theme color for consistency
                              : 'inherit',
                            '&:hover': {
                              backgroundColor: theme.palette.action.hover,
                            },
                          }}
                        >
                          <TableCell padding="checkbox" align="center" style={{ width: columns[0].width }}>
                            <Checkbox
                              checked={employeeAccruals.every((accrual) => isSelected(accrual.id))}
                              indeterminate={
                                employeeAccruals.some((accrual) => isSelected(accrual.id)) &&
                                !employeeAccruals.every((accrual) => isSelected(accrual.id))
                              }
                              onChange={(event) => handleEmployeeCheckboxChange(event, employeeAccruals)}
                              onClick={(event) => event.stopPropagation()}
                            />
                          </TableCell>
                          {columns.slice(1).map((column) => (
                            <TableCell key={column.id} style={{ width: column.width }}>
                              {column.id === 'employee' ? (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    width: '100%',
                                  }}
                                >
                                  <span>{employeeId}</span>
                                  <IconButton
                                    size="small"
                                    onClick={(event) => {
                                      event.stopPropagation()
                                      toggleEmployeeExpansion(employeeId)
                                    }}
                                  >
                                    {expandedEmployees.has(employeeId) ? <UpOutlined /> : <DownOutlined />}
                                  </IconButton>
                                </Box>
                              ) : column.id === 'total' ? (
                                expandedEmployees.has(employeeId) ? (
                                  '-'
                                ) : column.format ? (
                                  column.format(summaryRows.find((row) => row.employeeId === employeeId))
                                ) : (
                                  '-'
                                )
                              ) : column.format ? (
                                column.format(summaryRows.find((row) => row.employeeId === employeeId))
                              ) : (
                                '-'
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ) : (
                        // Render single row directly without grouping
                        <TableRow role="checkbox" tabIndex={-1} selected={isSelected(employeeAccruals[0].id)}>
                          {columns.map((column) => (
                            <TableCell key={column.id} style={{ width: column.width }}>
                              {column.id === 'checkbox' ? (
                                <Checkbox
                                  checked={isSelected(employeeAccruals[0].id)}
                                  onChange={(event) => handleClick(event, employeeAccruals[0].id)}
                                  onClick={(event) => event.stopPropagation()}
                                />
                              ) : column.id === 'employee' ? (
                                employeeId
                              ) : column.format ? (
                                column.format(employeeAccruals[0])
                              ) : (
                                employeeAccruals[0][column.id as keyof (typeof employeeAccruals)[0]]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      )}
                      {employeeAccruals.length > 1 &&
                        expandedEmployees.has(employeeId) &&
                        employeeAccruals.map((accrual) => (
                          <TableRow key={accrual.id} role="checkbox" tabIndex={-1} selected={isSelected(accrual.id)}>
                            {columns.map((column) => (
                              <TableCell key={column.id} style={{ width: column.width }}>
                                {column.id === 'checkbox' ? (
                                  <Checkbox
                                    checked={isSelected(accrual.id)}
                                    onChange={(event) => handleClick(event, accrual.id)}
                                    onClick={(event) => event.stopPropagation()}
                                  />
                                ) : column.id === 'employee' ? (
                                  employeeId
                                ) : column.format ? (
                                  column.format(accrual)
                                ) : (
                                  accrual[column.id as keyof typeof accrual]
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[100, 250, 500]}
              component="div"
              count={pagination?.totalItems || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </Suspense>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogContent>
          {selectedAccrual && (
            <AccrualDetails
              accrual={selectedAccrual}
              organizationLabels={organizationLabels}
              periodLabels={periodLabels}
              ledgerAccountLabels={ledgerAccountLabels}
              handleConfirmRunAccruals={handleConfirmRunAccruals}
            />
          )}
        </DialogContent>
        <DialogActions sx={{ m: 2 }}>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog} maxWidth="md" fullWidth>
        <DialogTitle>Confirm Run Accruals</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to run accruals for the following items?</Typography>
          <TableContainer sx={{ maxHeight: 300, mt: 2 }}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Employee</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Line Item</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getSelectedAccruals().map((accrual) => (
                  <TableRow key={accrual.id}>
                    <TableCell>{`${accrual.userFirstName} ${accrual.userLastName}`}</TableCell>
                    <TableCell>{ledgerAccountLabels[accrual.userLedgerAccountId || ''] || '-'}</TableCell>
                    <TableCell>{periodLabels[accrual.periodId] || accrual.periodId}</TableCell>
                    <TableCell>{`${accrual.allocationTableName} | ${accrual.allocationRowName}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} disabled={isQueueing}>
            Cancel
          </Button>
          <Button onClick={handleConfirmRunAccruals} variant="contained" color="primary" disabled={isQueueing}>
            {isQueueing ? 'Queueing...' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ManageAccruals
