import useSWR from 'swr'
import { CompensationPlansResponse, ExpandedCompensationPlan } from 'types/comp-plan'
import { fetchWithToken } from './http'

export function useFetchPlans(token: string, userId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`compensation/plans/user/${userId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    compPlans: data as CompensationPlansResponse,
    isLoading,
    isError: error,
  }
}

export function useFetchOrganizationPlans(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`compensation/plans/organization/${orgId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    plans: data?.plans ? (data?.plans as ExpandedCompensationPlan[]) : [],
    isLoading,
    isError: error,
  }
}
