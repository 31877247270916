// EditProfile/index.tsx

import { CircularProgress, Divider, Grid } from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import React from 'react'
import LoginSessionsSection from './LoginSessionsSection'
import OrganizationsSection from './OrganizationsSection'
import PasswordSection from './PasswordSection'
import ProfileSection from './Profile'
import TwoStepAuthSection from './TwoStepSection'

const EditProfilePage: React.FC = () => {
  const { token } = useAuth()
  const { profile, isLoading } = useProfile(token!)

  return isLoading ? (
    <Grid container spacing={12}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex', // Enables flexbox
          justifyContent: 'center', // Centers horizontally
          alignItems: 'center', // Centers vertically
          height: '100%', // Takes full height of the container
        }}
      >
        <CircularProgress color="primary" />
        Loading
      </Grid>
    </Grid>
  ) : (
    <>
      <SimpleTitle title="Profile" />
      <ProfileSection profile={profile} />
      <Divider style={{ width: '100%', margin: '40px 0' }} />
      <PasswordSection />
      <Divider style={{ width: '100%', margin: '40px 0' }} />
      <TwoStepAuthSection />
      <Divider style={{ width: '100%', margin: '40px 0' }} />
      <LoginSessionsSection />
      <Divider style={{ width: '100%', margin: '40px 0' }} />
      <OrganizationsSection profile={profile} />
    </>
  )
}

export default EditProfilePage
