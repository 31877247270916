import { UserRole } from './role'
import { User } from './user'

export interface UserProfile {
  user: User
  roles: UserRole[]
}

export const getOrganizationLabelMap = (userProfile: UserProfile): Record<string, string> => {
  return userProfile.roles.reduce(
    (acc: Record<string, string>, role) => {
      acc[role.organization.id] = role.organization.name
      return acc
    },
    {} as Record<string, string>
  )
}

export const getFirstRootOrganizationId = (userProfile: UserProfile): string | null => {
  const rootOrg = userProfile.roles.find((role) => role.organization.rootOrganizationId === role.organization.id)
  return rootOrg ? rootOrg.organization.id : null
}

export const getOrganizationIdsWithPermissions = (
  userProfile: UserProfile,
  requiredPermissions: string[]
): string[] => {
  return userProfile.roles.reduce((acc: string[], role) => {
    const hasAllPermissions = requiredPermissions.every((permission) => role.role.permissions.includes(permission))

    if (hasAllPermissions && !acc.includes(role.organization.id)) {
      acc.push(role.organization.id)
    }

    return acc
  }, [])
}
