import Alert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
import { deleteWithToken } from 'hooks/http'
import TemplatesTable from 'pages/Templates/TemplatesTable'
import React, { useState } from 'react'

const ManageTemplates: React.FC = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState('')

  const showMessage = (message: string) => {
    setSnackbarMessage(message)
    setSnackbarOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackbarOpen(false)
  }

  if (snackbarMessage) {
    showMessage(snackbarMessage)
  }

  const deleteTemplate = (token: string, id: string): Promise<{ message: string }> => {
    return deleteWithToken(`compensation/templates/${id}`, token)
  }

  return (
    <>
      <TemplatesTable deleteTemplate={deleteTemplate} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ManageTemplates
