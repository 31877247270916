import AuthLayout from 'layout/Auth'
import AuthCheckMail from 'pages/auth/check-mail'
import AuthCodeVerification from 'pages/auth/code-verification'
import AuthForgotPassword from 'pages/auth/forgot-password'
import AuthLogin from 'pages/auth/login'
import AuthResetPassword from 'pages/auth/reset-password'

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        {
          path: '/',
          element: <AuthLogin />,
        },
        {
          path: 'login',
          element: <AuthLogin />,
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />,
        },
        {
          path: 'check-mail',
          element: <AuthCheckMail />,
        },
        {
          path: 'reset-password',
          element: <AuthResetPassword />,
        },
        {
          path: 'code-verification',
          element: <AuthCodeVerification />,
        },
      ],
    },
  ],
}

export default LoginRoutes
