import { useTheme } from '@mui/material/styles'
import React from 'react'

// material-ui
import { Box, Paper, Typography } from '@mui/material'

interface CompensationSummaryTileProps {
  label: string
  value: number
  formatter: Intl.NumberFormat | null
  width: string
  footer?: string | null
  footerTextColor?: string | null
  footerBackgroundColor?: string | null
  border?: boolean | null
  borderColor?: string | null
  backgroundColor?: string | null
}

const CompensationSummaryTile = ({
  label,
  value,
  formatter,
  width,
  footer,
  footerTextColor,
  footerBackgroundColor,
  border,
  borderColor,
  backgroundColor,
}: CompensationSummaryTileProps) => {
  const theme = useTheme()

  // Adjust primary text color for light mode
  const primaryTextColor = theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.text.primary

  return (
    <React.Fragment>
      <Box
        sx={{
          padding: 2,
        }}
      >
        <Paper
          elevation={3}
          sx={{
            background: backgroundColor || theme.palette.background.paper,
            color: primaryTextColor, // Use adjusted text color
            pl: 0,
            pb: 0,
            borderRadius: 2,
            border: border ? `2px solid ${borderColor || theme.palette.divider}` : 'none',
          }}
        >
          <Typography variant="h3" component="div" sx={{ fontWeight: 'bold', pl: 3, pt: 2, pb: 0 }}>
            {label}
          </Typography>
          <Typography variant="h2" component="div" sx={{ fontWeight: 'bold', my: 1, pl: 3, pt: 0 }}>
            {formatter ? formatter.format(value) : value}
          </Typography>

          {footer && (
            <Box
              sx={{
                backgroundColor: footerBackgroundColor || theme.palette.background.default,
                fontSize: '0.75rem',
                padding: 0,
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: footerTextColor || theme.palette.text.secondary,
                  fontWeight: 'bold',
                  py: 1,
                  pl: 3,
                  pr: 2,
                }}
              >
                {footer}
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    </React.Fragment>
  )
}

export default CompensationSummaryTile
