import { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'

// project import
import router from 'routes'

import Snackbar from 'components/@extended/Snackbar'
import Notistack from 'components/third-party/Notistack'

import { useGlobalState } from 'contexts/GlobalStateContext'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'

const App = () => {
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const { purcentAppState, onChangeOrganizationSelection } = useGlobalState()

  useEffect(() => {
    let isMounted = true

    const getUser = async () => {
      if (!isMounted) return

      if (profile && profile.roles && profile.roles.length > 0) {
        if (purcentAppState!.selectedOrganization === null) {
          onChangeOrganizationSelection(profile.roles[0].organization || null)
        }
      }
    }

    getUser()

    return () => {
      isMounted = false
    }
  })

  return (
    <>
      <Notistack>
        <RouterProvider router={router} />
        <Snackbar />
      </Notistack>
    </>
  )
}

export default App
