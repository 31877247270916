export enum Permissions {
  ACCRUALS_READ = 'accruals.read',
  ACCRUALS_WRITE = 'accruals.write',
  APPROVALS_READ = 'approvals.read',
  APPROVALS_WRITE = 'approvals.write',
  COMPONENTS_DELETE = 'components.delete',
  COMPONENTS_READ = 'components.read',
  COMPONENTS_WRITE = 'components.write',
  LEDGER_ACCOUNTS_DELETE = 'ledger.accounts.delete',
  LEDGER_ACCOUNTS_READ = 'ledger.accounts.read',
  LEDGER_ACCOUNTS_WRITE = 'ledger.accounts.write',
  MANAGER_DASHBOARD_READ = 'manager.dashboard.read',
  MEMBERS_READ = 'members.read',
  MEMBERS_WRITE = 'members.write',
  ORGANIZATION_DELETE = 'organization.delete',
  ORGANIZATION_READ = 'organization.read',
  ORGANIZATION_WRITE = 'organization.write',
  PERIODS_DELETE = 'periods.delete',
  PERIODS_READ = 'periods.read',
  PERIODS_WRITE = 'periods.write',
  PLANS_READ = 'plans.read',
  PLANS_WRITE = 'plans.write',
  RESTRICTED_READ = 'restricted.read',
  SETTINGS_READ = 'settings.read',
  SETTINGS_WRITE = 'settings.write',
  TEMPLATES_READ = 'templates.read',
  TEMPLATES_WRITE = 'templates.write',
  TARGETS_READ = 'targets.read',
  TARGETS_WRITE = 'targets.write',
  METRICS_READ = 'metrics.read',
  METRICS_WRITE = 'metrics.write',
}
