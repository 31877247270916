const apiServerUrl = process.env.REACT_APP_API_SERVER_URL

export const fetchWithToken = (url: string, token: string, params: Array<[string, string]> | null) => {
  let paramsString = ''
  if (params) {
    paramsString = '?' + new URLSearchParams(params).toString()
  }
  return fetch(apiServerUrl + url + paramsString, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'content-Type': 'application/json',
    }),
  }).then((res) => res.json())
}

export const fetchWithTokenWithStatus = (url: string, token: string, params: Array<[string, string]> | null) => {
  let paramsString = ''
  if (params) {
    paramsString = '?' + new URLSearchParams(params).toString()
  }
  return fetch(apiServerUrl + url + paramsString, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'content-Type': 'application/json',
    }),
  }).then((res) => {
    const status = res.status
    return res.json().then((data) => ({ data, status }))
  })
}

export const postWithToken = (url: string, token: string, data: any) => {
  return fetch(apiServerUrl + url, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) {
      // Handle non-2xx responses, maybe throw an error
      throw new Error(`Server responded with status ${res.status}`)
    }
    return res.json()
  })
}

export const putWithToken = (url: string, token: string, data: any) => {
  return fetch(apiServerUrl + url, {
    method: 'PUT',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(data),
  }).then((res) => {
    if (!res.ok) {
      // Handle non-2xx responses, maybe throw an error
      throw new Error(`Server responded with status ${res.status}`)
    }
    return res.json()
  })
}

export const deleteWithToken = (url: string, token: string) => {
  return fetch(apiServerUrl + url, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
  }).then((res) => {
    if (!res.ok) {
      // Handle non-2xx responses
      return res.json().then((errorData) => {
        throw new Error(errorData.error || `Server responded with status ${res.status}`)
      })
    }
    return res.json()
  })
}

export const postWithTokenWithStatus = (url: string, token: string, data: any) => {
  return fetch(apiServerUrl + url, {
    method: 'POST',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
    body: JSON.stringify(data),
  }).then((res) => {
    const status = res.status
    return res.json().then((data) => ({ data, status }))
  })
}

export const deleteWithTokenWithStatus = (url: string, token: string) => {
  return fetch(apiServerUrl + url, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    }),
  }).then((res) => {
    const status = res.status
    return res.json().then((data) => ({ data, status }))
  })
}
