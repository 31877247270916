import PlansTable from './PlansTable'

const ManagePlans: React.FC = () => {
  return (
    <>
      <PlansTable />
    </>
  )
}

export default ManagePlans
