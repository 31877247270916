import { InputFormat } from 'types/common'

export const currencyFormatter = new Intl.NumberFormat('default', {
  style: 'currency',
  currency: 'USD',
})

export const twoDigitPercentFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const wholePercentFormatter = new Intl.NumberFormat('default', {
  style: 'percent',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const integerFormatter = new Intl.NumberFormat('default', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

export const decimalFormatter = new Intl.NumberFormat('default', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const numberFormatter = new Intl.NumberFormat('default')

export const formatter = (value: number, format: InputFormat | null) => {
  switch (format) {
    case 'INPUT_FORMAT_PERCENT':
      return twoDigitPercentFormatter.format(value)
    case 'INPUT_FORMAT_CURRENCY':
      return currencyFormatter.format(value)
    case 'INPUT_FORMAT_INTEGER':
      return integerFormatter.format(value)
    case 'INPUT_FORMAT_FLOAT':
      return decimalFormatter.format(value)
    default:
      return numberFormatter.format(value)
  }
}
