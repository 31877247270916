import { Box, LinearProgress, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { UserPeriodOverview } from 'types/dashboard'

interface TeamEmployeeOverviewsProps {
  userPeriodOverviews: UserPeriodOverview[]
}

const TeamEmployeeOverviews: FC<TeamEmployeeOverviewsProps> = ({ userPeriodOverviews }) => {
  if (!userPeriodOverviews) {
    return null
  }

  return (
    <Paper sx={{ p: 3, mt: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Employee Overviews
      </Typography>
      {userPeriodOverviews
        .filter((employee) => employee.planItems.length > 0)
        .map((employee) => {
          const totalMaxAmount = employee.planItems.reduce((sum, item) => sum + item.maxAmount, 0)
          const totalPayoutAmount = employee.planItems.reduce((sum, item) => sum + item.payoutAmount, 0)
          const progressPercentage = (totalPayoutAmount / totalMaxAmount) * 100

          return (
            <Box key={employee.userId} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">
                {employee.firstName} {employee.lastName} - {employee.organizationName}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress variant="determinate" value={progressPercentage} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" color="text.secondary">
                  Earned: ${totalPayoutAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })} / Max: $
                  {totalMaxAmount.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                  {progressPercentage.toFixed(0)}%
                </Typography>
              </Box>
            </Box>
          )
        })}
    </Paper>
  )
}

export default TeamEmployeeOverviews
