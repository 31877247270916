import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { CardActions, CardContent, Grid, IconButton, Theme, Typography, useTheme } from '@mui/material'
import AcceleratorSelector from 'components/AcceleratorSelector'
import GradientPaper from 'components/cards/GradientPaper'
import React from 'react'
import { VariableAllocationRow, VariableAllocationTable } from 'types/comp-allocations'
import { ExpandedCompensationPlan, getSlugLabelMapForPlan } from 'types/comp-plan'
import { LedgerAccount } from 'types/ledger-account'

const formatCurrency = (amount: number): string => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)
}

const AllocationTable: React.FC<{
  data: VariableAllocationTable
  tableIndex: number
  theme: Theme
  baseSalary: number
  variablePercent: number
  slugLabelMap: { [key: string]: string }
  ledgerAccounts: LedgerAccount[] | null
}> = ({ data, tableIndex, theme, baseSalary, variablePercent, slugLabelMap, ledgerAccounts }) => {
  // Calculate the total payout for visible rows
  const totalPayout = data.rows.reduce((acc, row) => {
    const payoutValue = baseSalary * row.allocationPercentage * variablePercent
    return acc + payoutValue
  }, 0)

  return (
    <GradientPaper sx={{ marginBottom: 3 }} gradientScale={0.5}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h3">{data.label}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h5" align="center">
              Account
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ paddingRight: '30px' }}>
            <Typography variant="h5" align="center">
              Allocation %
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h5" align="center">
              Accelerator
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="h5" align="center">
              On-Target Payout
            </Typography>
          </Grid>
        </Grid>
        {data.rows.map((row, index) => (
          <AllocationRow
            key={index}
            row={row}
            tableIndex={tableIndex}
            rowIndex={index}
            baseSalary={baseSalary}
            variablePercent={variablePercent}
            theme={theme}
            slugLabelMap={slugLabelMap}
            ledgerAccounts={ledgerAccounts}
          />
        ))}
      </CardContent>
      <CardActions
        style={{
          justifyContent: 'flex-end',
          paddingRight: '16px',
        }}
      >
        <Grid container spacing={3} mb={1}>
          <Grid item xs={11}>
            <Typography variant="h5" align="right" style={{ marginRight: '100px' }}>
              On-Target Total:
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h5" align="center" style={{ marginLeft: '-100px' }}>
              {formatCurrency(totalPayout)}
            </Typography>
          </Grid>
        </Grid>
      </CardActions>
    </GradientPaper>
  )
}

const AllocationRow: React.FC<{
  row: VariableAllocationRow
  tableIndex: number
  rowIndex: number
  baseSalary: number
  variablePercent: number
  theme: Theme
  slugLabelMap: { [key: string]: string }
  ledgerAccounts: LedgerAccount[] | null
}> = ({ row, tableIndex, rowIndex, baseSalary, variablePercent, theme, slugLabelMap, ledgerAccounts }) => {
  const [expanded, setExpanded] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const rowStyle = row.disabled ? { color: 'gray' } : {}

  // Define marks for min, max, and current value, converting decimal to percentage
  const marks = [
    {
      value: (row.minAllocationPercentage ?? 0) * 100,
      label: `${((row.minAllocationPercentage ?? 0) * 100).toFixed(0)}%`,
    },
    {
      value: (row.allocationPercentage ?? 0) * 100,
      label: `${((row.allocationPercentage ?? 0) * 100).toFixed(0)}%`,
    },
    {
      value: (row.maxAllocationPercentage ?? 1) * 100,
      label: `${((row.maxAllocationPercentage ?? 1) * 100).toFixed(0)}%`,
    },
  ]

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
        {!row.disabled && (
          <IconButton onClick={handleExpandClick} style={{ marginRight: '8px' }}>
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        )}
        <Typography style={rowStyle}>{row.label}</Typography>
      </Grid>
      <Grid item xs={2}>
        {!row.disabled && (
          <Typography align="center">
            {row.ledgerAccountId && ledgerAccounts
              ? ledgerAccounts.find((account) => account.id === row.ledgerAccountId)?.label || '-'
              : '-'}
          </Typography>
        )}
      </Grid>
      <Grid item xs={2}>
        {!row.disabled && <Typography align="center">{marks[1].label}</Typography>}
      </Grid>
      <Grid item xs={2} style={{ justifyContent: 'center', display: 'flex' }}>
        {!row.disabled && (
          <AcceleratorSelector
            value={row.acheivementMultiplier}
            setValue={(newValue) => {}}
            incrementAmount={0.1}
            reviewOnly={true}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {!row.disabled && (
          <Typography align="center">
            {formatCurrency(baseSalary * row.allocationPercentage * variablePercent)}
          </Typography>
        )}
      </Grid>
      {expanded && !row.disabled && (
        <Grid container item xs={12} justifyContent="center" style={{ marginLeft: '16px', marginRight: '16px' }}>
          <Grid
            container
            item
            spacing={2}
            style={{ marginTop: '10px', marginBottom: '40px', maxWidth: '100%' }}
            justifyContent="center"
          >
            <Grid item xs={4}>
              <Typography variant="h5">Requirements</Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography variant="h5">Formula</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5">Payout</Typography>
            </Grid>
            {row.formulaRequirements.map((requirement, index) => {
              const formulaValue = /^[0-9.]+$/.test(row.formulaOptions[index])
                ? parseFloat(row.formulaOptions[index])
                : 1.0

              const requirementLabel = Object.keys(slugLabelMap).reduce((acc, key) => {
                if (acc.includes(key)) {
                  acc = acc.replace(key, slugLabelMap[key])
                }
                return acc
              }, requirement)

              const formulaLabel = Object.keys(slugLabelMap).reduce((acc, key) => {
                if (acc.includes(key)) {
                  acc = acc.replace(key, slugLabelMap[key])
                }
                return acc
              }, row.formulaOptions[index])

              return (
                <React.Fragment key={index}>
                  <Grid item xs={4}>
                    {requirementLabel.replace(/_/g, '')}
                  </Grid>
                  <Grid item xs={7}>
                    {formulaLabel.replace(/_/g, '')}
                  </Grid>
                  <Grid item xs={1}>
                    <Typography>
                      {formatCurrency(baseSalary * row.allocationPercentage * variablePercent * formulaValue)}
                    </Typography>
                  </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

interface PlanAllocationTablesProps {
  plan: ExpandedCompensationPlan
  ledgerAccounts: LedgerAccount[] | null
}

const PlanAllocationsCard: React.FC<PlanAllocationTablesProps> = ({ plan, ledgerAccounts }) => {
  const theme = useTheme()

  const baseSalary = plan.plan.baseSalary
  const variablePercent = plan.plan.variablePercent
  const allocations = plan.plan.variableAllocations
  const slugLabelMap = getSlugLabelMapForPlan(plan)

  return (
    <div style={{ width: '100%' }}>
      {allocations.map((table, index) => (
        <AllocationTable
          key={index}
          data={table}
          tableIndex={index}
          baseSalary={baseSalary}
          variablePercent={variablePercent}
          theme={theme}
          slugLabelMap={slugLabelMap}
          ledgerAccounts={ledgerAccounts}
        />
      ))}
    </div>
  )
}

export default PlanAllocationsCard
