import { Grid, Stack, TextField, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

import { NumericFormat } from 'react-number-format'

import GradientPaper from 'components/cards/GradientPaper'
import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

const PlanComponentSummary = () => {
  const theme = useTheme()

  const { totalComponentsInPlanValue, maxVariableAllowedInComponents, plan, mode } = useCreatePlanContext()

  React.useEffect(() => {}, [maxVariableAllowedInComponents, plan, plan.variableCap])

  return mode === 'REVIEW' ? (
    <></>
  ) : (
    <React.Fragment>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={12}>
          <GradientPaper sx={{ marginTop: 5 }}>
            <Grid item container xs={12} spacing={2} alignItems="center">
              <Grid item xs={8}>
                <Typography variant="h3" sx={{ pl: 2, pb: 2, pt: 0 }}>
                  {'Plan Configuration Status'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Stack margin={2} spacing={1} sx={{ pl: 0, pb: 1, pt: 0, pr: 1 }}>
                  <NumericFormat
                    value={totalComponentsInPlanValue}
                    thousandSeparator
                    decimalScale={1}
                    suffix="%"
                    customInput={TextField}
                    label="Component Percentage Total"
                    variant="filled"
                    helperText={totalComponentsInPlanValue === 100 ? '' : 'Must total 100%'}
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor:
                          totalComponentsInPlanValue === 100
                            ? `${theme.palette.success.main}`
                            : `${theme.palette.error.main}`,
                      },
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
          </GradientPaper>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PlanComponentSummary
