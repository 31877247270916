import useSWR, { mutate } from 'swr'
import {
  Component,
  ComponentSearchParams,
  ExpandedTarget,
  ExpandedTargetSearchResponse,
  MetricDetailsParams,
  MetricDetailsResponse,
  MetricSearchResponse,
  NeededTargetMetric,
  SearchNeededTargetMetricsParams,
  TargetDetailsParams,
  TargetDetailsResponse,
  TargetSearchResponse,
} from 'types/components'
import { fetchWithToken, postWithToken } from './http'

export function useFetchOrganizationComponents(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`components/organization/${orgId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    components: data ? (data as Component[]) : [],
    isLoading,
    isError: error,
  }
}

export function useFetchExpandedTargets(token: string, rootOrganizationId: string, rootPeriodId: string) {
  const shouldFetch = token != null && token.trim() !== ''

  const { data, error, isValidating } = useSWR(
    shouldFetch && rootOrganizationId && rootPeriodId
      ? [`components/period/target/expanded?organizationId=${rootOrganizationId}&rootPeriodId=${rootPeriodId}`, token]
      : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: false,
      revalidateIfStale: true,
      revalidateOnFocus: true,
    }
  )

  return {
    targets: data as ExpandedTarget[],
    isLoadingExpandedTargets: !data && isValidating,
    isError: error,
  }
}

export function useSearchTargets(token: string, params: ComponentSearchParams, shouldFetch: boolean) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/targets/search`, token, params] : null, // Conditional key based on shouldFetch
    ([url, token, params]) => postWithToken(url, token, params)
  )

  return {
    targets: data as TargetSearchResponse,
    isLoading,
    isError: error,
  }
}

export function useSearchExpandedTargets(token: string, params: ComponentSearchParams, shouldFetch: boolean) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/targets/expanded/search`, token, params] : null,
    ([url, token, params]) => postWithToken(url, token, params)
  )

  return {
    targets: data as ExpandedTargetSearchResponse,
    isLoading,
    isError: error,
  }
}

export function useSearchMetrics(token: string, params: ComponentSearchParams, shouldFetch: boolean) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/period/search`, token, params] : null, // Conditional key based on shouldFetch
    ([url, token, params]) => postWithToken(url, token, params)
  )

  return {
    metrics: data as MetricSearchResponse,
    isLoading,
    isError: error,
  }
}

export function useSearchNeededTargetMetrics(
  token: string,
  params: SearchNeededTargetMetricsParams,
  shouldFetch: boolean
) {
  const { data, error, isLoading } = useSWR(
    shouldFetch ? [`components/targets/metrics/search`, token, params] : null,
    ([url, token, params]) => postWithToken(url, token, params)
  )

  return {
    targets: data as NeededTargetMetric[],
    isLoading,
    isError: error,
  }
}

export function useFetchTargetDetails(token: string, params: TargetDetailsParams, shouldFetch: boolean) {
  const key = shouldFetch ? [`components/targets/details`, token, JSON.stringify(params)] : null
  const { data, error, isLoading } = useSWR(
    key,
    async ([url, token, paramsString]) => {
      const parsedParams = JSON.parse(paramsString)
      return postWithToken(url, token, parsedParams)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const revalidate = () => {
    if (key) {
      mutate(key)
    }
  }

  return {
    targetDetails: data as TargetDetailsResponse,
    isLoading,
    isError: error,
    revalidate,
  }
}

export function useFetchMetricDetails(token: string, params: MetricDetailsParams, shouldFetch: boolean) {
  const key = shouldFetch ? [`components/metrics/details`, token, JSON.stringify(params)] : null
  const { data, error, isLoading } = useSWR(
    key,
    async ([url, token, paramsString]) => {
      const parsedParams = JSON.parse(paramsString)
      return postWithToken(url, token, parsedParams)
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      dedupingInterval: 0,
    }
  )

  const revalidate = () => {
    if (key) {
      mutate(key)
    }
  }

  return {
    metricDetails: data as MetricDetailsResponse,
    isLoading,
    isError: error,
    revalidate,
  }
}
