// OrganizationsSection.tsx
import { Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

import { UserProfile } from 'types/user-profile'

type OrganizationsSectionProps = {
  profile: UserProfile // Define the prop type
}

const OrganizationsSection: React.FC<OrganizationsSectionProps> = ({ profile }) => {
  const sortedRoles = profile?.roles.slice().sort((a, b) => a.organization.name.localeCompare(b.organization.name))

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Organizations
      </Typography>
      <Paper sx={{ width: '100%', overflowX: 'auto', mb: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Role</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedRoles.map((roleEntry) => (
              <TableRow key={roleEntry.organization.id}>
                <TableCell>{roleEntry.organization.name}</TableCell>
                <TableCell>{roleEntry.role.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </>
  )
}

export default OrganizationsSection
