import { TextField } from '@mui/material'
import React from 'react'
import { NumericFormat } from 'react-number-format'

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
  decimalScale: number
}

const NumericFormatCustom = React.forwardRef<HTMLInputElement, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, decimalScale, ...other } = props

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values: { value: string }) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        })
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={decimalScale}
      fixedDecimalScale
    />
  )
})

interface DecimalFieldProps {
  label: string
  value: string
  onChange: (value: string) => void
  name: string
  decimalPlaces?: number
  disabled?: boolean
  placeholder?: string
}

const DecimalField: React.FC<DecimalFieldProps> = ({
  label,
  value,
  onChange,
  name,
  decimalPlaces = 2,
  disabled = false,
  placeholder,
}) => {
  return (
    <TextField
      fullWidth
      label={label}
      variant="standard"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      name={name}
      InputProps={{
        inputComponent: NumericFormatCustom as any,
        inputProps: {
          decimalScale: decimalPlaces,
        },
      }}
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}

export default DecimalField
