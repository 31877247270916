import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useSearchOrganizationTemplates } from 'hooks/useTemplates'
import { ChangeEvent, FC, startTransition, useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { KeyedObject } from 'types/root'
import { getOrganizationLabelMap } from 'types/user-profile'
import { debounce } from 'utils/debounce'
import { wholePercentFormatter } from 'utils/formatting'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

interface TemplatesTableProps {
  withCreateButtons?: boolean
  deleteTemplate: (token: string, id: string) => Promise<{ message: string }>
}

const TemplatesTable: FC<TemplatesTableProps> = ({ withCreateButtons = true, deleteTemplate }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])

  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const { templates, isLoading, refetch } = useSearchOrganizationTemplates(token!, {
    searchTerm: search,
    organizationIds: selectedOrganizations,
    perPage: rowsPerPage,
    page: page + 1,
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    setRowsPerPage(+event?.target?.value!)
    setPage(0)
  }

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value)
    }, 300),
    []
  )

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  // table columns
  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    { id: 'organizationId', label: 'Team', minWidth: 170, format: (value) => organizationMap[value] },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variablePercent',
      label: 'Variable Compensation',
      minWidth: 140,
      format: (value) => {
        return wholePercentFormatter.format(value)
      },
    },
    {
      id: 'variableCap',
      label: 'Max Payout',
      minWidth: 170,
      format: (value) => {
        return wholePercentFormatter.format(value)
      },
    },
    {
      id: 'id',
      label: 'Actions',
      minWidth: 180, // Increased to accommodate the new button
      format: (templateId: string, row: any) => (
        <Box display="flex">
          <Tooltip title="Duplicate Template">
            <IconButton
              color="primary"
              onClick={() =>
                startTransition(() => {
                  navigate(`/admin/templates/${templateId}/copy`)
                })
              }
            >
              <CopyOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              color="primary"
              onClick={() =>
                startTransition(() => {
                  navigate(`/admin/templates/${templateId}/edit`)
                })
              }
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          {withCreateButtons && (
            <Tooltip title="Create Plan from this Template">
              <IconButton color="primary" onClick={() => navigate(`/create-comp-plan?templateId=${templateId}`)}>
                <PlusCircleOutlined />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Delete Template">
            <IconButton color="error" onClick={() => handleDeleteClick(templateId, row.label)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ]

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [templateToDelete, setTemplateToDelete] = useState<{ id: string; name: string } | null>(null)

  const handleDeleteClick = (templateId: string, templateName: string) => {
    setTemplateToDelete({ id: templateId, name: templateName })
    setDeleteDialogOpen(true)
  }

  const handleDeleteConfirm = () => {
    if (templateToDelete && token) {
      deleteTemplate(token, templateToDelete.id)
        .then(() => {
          setDeleteDialogOpen(false)
          setTemplateToDelete(null)
          refetch() // Trigger a re-fetch of the templates
        })
        .catch((error) => {
          console.error('Failed to delete template:', error)
          // Handle error (e.g., show error message to user)
        })
    }
  }

  return (
    <>
      <div style={{ position: 'relative' }}>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={4}>
            <Typography variant="h2">Templates</Typography>
          </Grid>
          <Grid item xs={4} container justifyContent="center">
            <TextField
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              sx={{
                userSelect: 'none',
                width: '100%',
                '& .MuiInputLabel-root': {
                  backgroundColor: 'transparent',
                },
              }}
              variant="standard"
              onChange={(e) => debouncedSetSearch(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <MultiOrganizationSelector
              userProfile={profile}
              selectedOrganizationIds={selectedOrganizations}
              handleChange={setSelectedOrganizations}
              width="80%"
            />
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid container spacing={12}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex', // Enables flexbox
                justifyContent: 'center', // Centers horizontally
                alignItems: 'center', // Centers vertically
                height: '100%', // Takes full height of the container
              }}
            >
              <CircularProgress color="primary" />
              Loading
            </Grid>
          </Grid>
        ) : (
          <Paper>
            {/* table */}
            <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
              <Table stickyHeader size="small" aria-label="sticky table">
                <TableHead
                  sx={{
                    '& th': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `2px solid ${theme.palette.divider} !important`,
                    },
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        id={'cell-col-' + (column.id || '')}
                        sx={{
                          minWidth: column.minWidth,
                          position: 'sticky !important',
                        }}
                        key={column.id}
                        align={column.align}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.length > 0 ? (
                    templates.map((row: KeyedObject) => (
                      <TableRow
                        id={'table-row-' + row.id}
                        sx={{ py: 3 }}
                        role="checkbox"
                        tabIndex={-1}
                        key={'table-row-' + row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id]
                          return (
                            <TableCell id={'cell-' + column.id} key={'cell-' + column.id} align={column.align}>
                              {column.format ? column.format(value, row) : value}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} align="center">
                        No data available
                      </TableCell>
                    </TableRow>
                  )}
                  {withCreateButtons && (
                    <TableRow
                      id={'create-new-template'}
                      sx={{ py: 3 }}
                      role="checkbox"
                      tabIndex={-1}
                      key={'create-new-template'}
                    >
                      <TableCell colSpan={columns.length} align="left">
                        <IconButton
                          color="primary"
                          onClick={() =>
                            startTransition(() => {
                              navigate('/admin/templates/create')
                            })
                          }
                        >
                          <Box display="flex" alignItems="center" justifyContent="flex-start">
                            <PlusOutlined style={{ marginRight: 8, marginLeft: 35 }} />
                            Create
                          </Box>
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={templates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
      </div>
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="delete-template-dialog"
        aria-describedby="delete-template-description"
      >
        <DialogTitle id="delete-template-dialog">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-template-description">
            Are you sure you want to delete{' '}
            <Typography component="span" color="primary" fontWeight="bold" display="inline">
              {templateToDelete?.name}
            </Typography>
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default TemplatesTable
