import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

// material-ui
import { Box, Container, Toolbar, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project import
import Breadcrumbs from 'components/@extended/Breadcrumbs'
import Loader from 'components/Loader'
import AuthGuard from 'utils/route-guard/AuthGuard'
import Drawer from './Drawer'
import HorizontalBar from './Drawer/HorizontalBar'
import Footer from './Footer'
import Header from './Header'

import { handlerDrawerOpen, useGetMenuMaster } from 'api/menu'
import useConfig from 'hooks/useConfig'

// types
import { MenuOrientation } from 'types/config'

// ==============================|| MAIN LAYOUT ||============================== //

const DashboardLayout = () => {
  const theme = useTheme()
  const { menuMasterLoading } = useGetMenuMaster()
  const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'))
  const downLG = useMediaQuery(theme.breakpoints.down('lg'))

  const { miniDrawer, menuOrientation } = useConfig()

  const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      handlerDrawerOpen(!matchDownXL)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownXL])

  if (menuMasterLoading) return <Loader />

  return (
    <AuthGuard>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header />
        {!isHorizontal ? <Drawer /> : <HorizontalBar />}

        <Box
          component="main"
          sx={{
            width: { xs: '100%', lg: `calc(100% - ${!isHorizontal ? '260px' : '0px'})` },
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
          }}
        >
          <Toolbar sx={{ mt: isHorizontal ? 8 : 'inherit' }} />
          <Container
            maxWidth={false}
            sx={{
              px: { xs: 0, sm: 2 },
              position: 'relative',
              minHeight: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Breadcrumbs />
            <Outlet />
            <Footer />
          </Container>
        </Box>
      </Box>
    </AuthGuard>
  )
}

export default DashboardLayout
