// project import
import DashboardLayout from 'layout/Dashboard'
import SimpleLayout from 'layout/Simple'

import Dashboard from 'pages/Dashboard'

// types
import { SimpleLayoutType } from 'types/config'

import AppContactUS from 'pages/contact-us'

import CompensationPlan from 'pages/CompPlan'
import CompPlans from 'pages/CompPlans'
import Organization from 'pages/Organization'
import OrganizationSettings from 'pages/OrganizationSettings'
import EditProfile from 'pages/Profile'
import Templates from 'pages/Templates'

import CreatePlanForEmployee from 'forms/CreatePlanForEmployee'
import ManageAccruals from 'pages/Admin/Accruals/ManageAccruals'
import EditApproval from 'pages/Admin/Approvals/EditApproval'
import ManageApprovals from 'pages/Admin/Approvals/ManageApprovals'
import CreateMetrics from 'pages/Admin/Components/CreateMetrics'
import CreateTargets from 'pages/Admin/Components/CreateTargets'
import EditMetric from 'pages/Admin/Components/EditMetric'
import EditTarget from 'pages/Admin/Components/EditTarget'
import ManageMetrics from 'pages/Admin/Components/ManageComponents/ManageMetrics'
import ManageTargets from 'pages/Admin/Components/ManageComponents/ManageTargets'
import ManageLedgerAccounts from 'pages/Admin/LedgerAccounts/ManageLedgerAccounts'
import ManageOrganizationStructure from 'pages/Admin/Organizations/ManageOrganizationStructure'
import ManagePlans from 'pages/Admin/Plans/ManagePlans'
import CopyTemplate from 'pages/Admin/Templates/CopyTemplate'
import CreateTemplate from 'pages/Admin/Templates/CreateTemplate'
import EditTemplate from 'pages/Admin/Templates/EditTemplate'
import ManageTemplates from 'pages/Admin/Templates/ManageTemplates'
import SelectTemplate from 'pages/Admin/Templates/SelectTemplate'
import CreateUser from 'pages/Admin/Users/CreateUser'
import ReportingOne from 'pages/Reporting/reporting-one'
import ReportingThree from 'pages/Reporting/reporting-three'
import ReportingTwo from 'pages/Reporting/reporting-two'

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'dashboard',
          element: <Dashboard />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'compplan',
          element: <CompensationPlan />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'employee/:userId/compplan/edit',
          element: <CreatePlanForEmployee />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'employee/:userId/compplan/all',
          element: <CompPlans />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'employee/:userId/compplan',
          element: <CompensationPlan />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'organization',
          element: <Organization />,
        },
        {
          path: 'company-settings',
          element: <OrganizationSettings />,
        },
        {
          path: 'admin/users/profile',
          element: <CreateUser />,
        },
        {
          path: 'admin/organizations/manage',
          element: <ManageOrganizationStructure />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'admin/templates',
          element: <ManageTemplates />,
        },
        {
          path: 'admin/templates/create',
          element: <CreateTemplate />,
        },
        {
          path: 'admin/templates/:templateId/edit',
          element: <EditTemplate />,
        },
        {
          path: 'admin/templates/:templateId/copy',
          element: <CopyTemplate />,
        },
        {
          path: 'admin/templates/select/:userId',
          element: <SelectTemplate />,
        },
        {
          path: 'admin/plans',
          element: <ManagePlans />,
        },
        {
          path: 'admin/ledger-accounts',
          element: <ManageLedgerAccounts />,
        },
        {
          path: 'admin/accruals',
          element: <ManageAccruals />,
        },
        {
          path: 'admin/components/targets/create',
          element: <CreateTargets />,
        },
        {
          path: 'admin/components/actuals/create',
          element: <CreateMetrics />,
        },
        {
          path: 'admin/approvals',
          element: <ManageApprovals />,
        },
        {
          path: 'admin/approvals/:approvalBatchId/edit',
          element: <EditApproval />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'profile',
          element: <EditProfile />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'templates',
          element: <Templates />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'create-comp-plan',
          element: <CreatePlanForEmployee />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'admin/components/targets',
          element: <ManageTargets />,
        },
        {
          path: 'admin/components/actuals',
          element: <ManageMetrics />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'reporting/1',
          element: <ReportingOne />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'reporting/2',
          element: <ReportingTwo />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'reporting/3',
          element: <ReportingThree />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'admin/targets/edit',
          element: <EditTarget />,
        },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        {
          path: 'admin/metrics/edit',
          element: <EditMetric />,
        },
      ],
    },
    {
      path: '/',
      element: <SimpleLayout layout={SimpleLayoutType.SIMPLE} />,
      children: [
        {
          path: 'contact-us',
          element: <AppContactUS />,
        },
      ],
    },
  ],
}

export default MainRoutes
