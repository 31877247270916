import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import React from 'react'

type PercentSelectorProps = {
  value: number
  setValue: (newValue: number) => void
  incrementAmount: number
  min: number
  max: number
  disabled?: boolean
  reviewOnly?: boolean
}

const PercentSelector: React.FC<PercentSelectorProps> = ({
  value,
  setValue,
  incrementAmount,
  min,
  max,
  disabled = false,
  reviewOnly = false,
}) => {
  const adjustPercent = (increment: boolean) => {
    let newValue = increment ? value + incrementAmount : value - incrementAmount
    newValue = Math.max(min, Math.min(max, Math.round(newValue * 100) / 100))
    setValue(newValue)
  }

  const controls = reviewOnly ? null : (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: '2px' }}>
      <IconButton
        size="small"
        onClick={() => adjustPercent(true)}
        disabled={disabled || value >= max}
        style={{ padding: '2px' }}
      >
        <ArrowDropUp style={{ fontSize: '18px' }} />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => adjustPercent(false)}
        disabled={disabled || value <= min}
        style={{ padding: '2px' }}
      >
        <ArrowDropDown style={{ fontSize: '18px' }} />
      </IconButton>
    </div>
  )

  return (
    <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.1rem' }}>
      <Typography>{`${(value * 100).toFixed(0)}%`}</Typography>
      {controls}
    </div>
  )
}

export default PercentSelector
