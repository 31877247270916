// material-ui
import { useTheme } from "@mui/material/styles";

import logoIconDark from "assets/images/Purcent-Icon-Color-W129.png";
import logoIcon from "assets/images/Purcent-Icon-MonoDark-W129.png";
import { ThemeMode } from "types/config";

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();

  return (
    <img
      src={theme.palette.mode === ThemeMode.DARK ? logoIconDark : logoIcon}
      alt="Purcent"
      width="100"
    />
  );
};

export default LogoIcon;
