import { useTheme } from '@mui/material/styles'
import { ApexOptions } from 'apexcharts'
import { FC } from 'react'
import ReactApexChart from 'react-apexcharts'
import colors from 'themes/colors'

interface CompensationChartProps {
  salary: number
  variable: number
  currency?: string
  currencyDecimals?: number
}

const CompensationChart: FC<CompensationChartProps> = ({
  salary,
  variable,
  currency = 'USD',
  currencyDecimals = 2,
}: CompensationChartProps) => {
  const theme = useTheme()

  const series = [salary, variable]

  console.log('Loading component with: ', currency, currencyDecimals)

  const formatCurrency = (value: number, cur: string, dec: number) => {
    console.log('Formatting value:', value, cur, dec)
    const formattedValue = new Intl.NumberFormat('default', {
      style: 'currency',
      currency: cur,
      minimumFractionDigits: dec,
      maximumFractionDigits: dec,
    }).format(value)
    console.log('Formatted value:', formattedValue)
    return formattedValue
  }

  const chartColors =
    theme.palette.mode === 'light' ? [colors.caribbeanBlue, colors.pear] : [colors.caribbeanBlue, colors.turquoise]

  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    colors: chartColors,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              color: theme.palette.primary.contrastText,
              fontSize: '25px',
              fontWeight: 'bold',
              showAlways: true,
              show: true,
              formatter: function (val) {
                return formatCurrency(
                  val.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0),
                  currency,
                  currencyDecimals
                )
              },
            },
            value: {
              color: theme.palette.primary.contrastText,
              show: true,
            },
            name: {
              color: theme.palette.primary.contrastText,
              show: true,
            },
          },
        },
      },
    },
    legend: {
      position: 'bottom',
      fontSize: '16px',
      labels: {
        colors: theme.palette.primary.contrastText,
      },
      formatter: function (val, opts) {
        return val + ' - ' + formatCurrency(opts.w.globals.series[opts.seriesIndex], currency, currencyDecimals)
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (val) {
          return formatCurrency(val, currency, currencyDecimals)
        },
      },
    },
    labels: ['Salary', 'Variable'],
  }

  return (
    <>
      <ReactApexChart
        key={`${currency}-${currencyDecimals}-${salary}-${variable}`}
        options={options}
        series={series}
        type="donut"
        height={350}
      />
    </>
  )
}

export default CompensationChart
