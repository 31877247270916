import { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import useAuth from 'hooks/useAuth'
import { useProfile } from 'hooks/useProfile'
import { useSearchOrganizationTemplates } from 'hooks/useTemplates'
import React, { FC, useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { KeyedObject } from 'types/root'
import { getOrganizationLabelMap } from 'types/user-profile'
import { debounce } from 'utils/debounce'
import { wholePercentFormatter } from 'utils/formatting'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: KeyedObject) => string | boolean | JSX.Element
}

const SelectTemplate: FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const navigate = useNavigate()
  const theme = useTheme()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [search, setSearch] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])

  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const { templates, isLoading } = useSearchOrganizationTemplates(token!, {
    searchTerm: search,
    organizationIds: selectedOrganizations,
    perPage: rowsPerPage,
    page: page + 1,
  })

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      setSearch(value)
    }, 300),
    []
  )

  const organizationMap = profile != null ? getOrganizationLabelMap(profile) : {}

  const columns: ColumnProps[] = [
    { id: 'label', label: 'Name', minWidth: 170 },
    { id: 'organizationId', label: 'Team', minWidth: 170, format: (value) => organizationMap[value] },
    { id: 'position', label: 'Position', minWidth: 170 },
    {
      id: 'variablePercent',
      label: 'Variable Compensation',
      minWidth: 140,
      format: (value) => wholePercentFormatter.format(value),
    },
    {
      id: 'variableCap',
      label: 'Max Payout',
      minWidth: 170,
      format: (value) => wholePercentFormatter.format(value),
    },
    {
      id: 'id',
      label: 'Action',
      minWidth: 120,
      format: (templateId: string) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate(`/create-comp-plan?withUserId=${userId}&templateId=${templateId}`)}
        >
          Select
        </Button>
      ),
    },
  ]

  return (
    <div style={{ position: 'relative' }}>
      <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={4}>
          <Typography variant="h2">Select Template</Typography>
        </Grid>
        <Grid item xs={4} container justifyContent="center">
          <TextField
            placeholder="Search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            sx={{
              userSelect: 'none',
              width: '100%',
              '& .MuiInputLabel-root': {
                backgroundColor: 'transparent',
              },
            }}
            variant="standard"
            onChange={(e) => debouncedSetSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <MultiOrganizationSelector
            userProfile={profile}
            selectedOrganizationIds={selectedOrganizations}
            handleChange={setSelectedOrganizations}
            width="80%"
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container spacing={12}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <CircularProgress color="primary" />
            Loading
          </Grid>
        </Grid>
      ) : (
        <Paper>
          <TableContainer sx={{ maxHeight: 860, minHeight: 430 }}>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {templates.length > 0 ? (
                  templates.map((row: KeyedObject) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format ? column.format(value, row) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} align="center">
                      No data available
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={templates.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  )
}

export default SelectTemplate
