import useSWR from 'swr'
import { CompensationTemplate, ExpandedCompensationTemplate, TemplateSearchParams } from 'types/comp-template'
import { fetchWithToken, postWithToken } from './http'

export function useFetchTemplate(token: string, templateId: string | undefined, params: [string, string][] | null) {
  const { data, error, isLoading } = useSWR(
    templateId ? [`compensation/templates/${templateId}`, token, params] : null,
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    compensationTemplate: data ? (data as ExpandedCompensationTemplate) : undefined,
    isLoading,
    isError: error,
  }
}

export function useFetchOrganizationTemplates(token: string, orgId: string, params: [[string, string]] | null) {
  const { data, error, isLoading } = useSWR(
    [`compensation/templates/organization/${orgId}`, token, params],
    ([url, token, params]) => fetchWithToken(url, token, params)
  )

  return {
    templates: data?.templates ? (data?.templates as ExpandedCompensationTemplate[]) : [],
    isLoading,
    isError: error,
  }
}

export function useSearchOrganizationTemplates(token: string, params: TemplateSearchParams | null) {
  const { data, error, isLoading, mutate } = useSWR(
    [`compensation/templates/organizations`, token, params],
    ([url, token, params]) => postWithToken(url, token, params)
  )

  const refetch = () => mutate()

  return {
    templates: data?.templates ? (data?.templates as CompensationTemplate[]) : [],
    isLoading,
    isError: error,
    refetch,
  }
}
