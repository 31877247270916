import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect, useState } from 'react'
import { LedgerAccount } from 'types/ledger-account'
import { OrganizationSetting } from 'types/organization'
import { Role, UserRole } from 'types/role'
import { UserData } from 'types/user'
import { UserProfile } from 'types/user-profile'

interface CreateOrUpdateUserDialogProps {
  open: boolean
  onClose: () => void
  onSubmit: (userData: UserData) => void
  organizationName: string
  organizationId: string
  user?: UserData
  theme: Theme
  roles: Role[]
  isLoading: boolean
  error: Error | null
  ledgerAccounts: LedgerAccount[]
  organizationSettings: OrganizationSetting[]
  userProfile: UserProfile
}

const CreateOrUpdateUserDialog: FC<CreateOrUpdateUserDialogProps> = ({
  open,
  onClose,
  onSubmit,
  organizationName,
  organizationId,
  user,
  theme,
  roles,
  isLoading,
  error,
  ledgerAccounts,
  organizationSettings,
  userProfile,
}) => {
  const defaultLedgerAccountSetting = organizationSettings.find(
    (setting) => setting.settingKey === 'default-ledger-account'
  )

  const defaultLedgerAccountId = defaultLedgerAccountSetting?.settingValue || ''

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [position, setPosition] = useState('')
  const [roleId, setRoleId] = useState('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [ledgerAccountId, setLedgerAccountId] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(organizationId)
  const [originalSelectedOrganizationId, setOriginalSelectedOrganizationId] = useState(organizationId)

  useEffect(() => {
    if (user) {
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setEmail(user.email)
      setPosition(user.position)
      setRoleId(user.roles[0].roleId)
      setSelectedOrganizationId(user.roles[0].organizationId)
      setStartDate(dayjs(user.startDate))
      setLedgerAccountId(user.ledgerAccountId || '')
      setOriginalSelectedOrganizationId(user.roles[0].organizationId)
    } else {
      // Reset fields when creating a new user
      setFirstName('')
      setLastName('')
      setEmail('')
      setPosition('')
      setStartDate(null)
      // Set default role to "Member"
      const memberRole = roles.find((role) => role.name === 'Member')
      if (memberRole) {
        setRoleId(memberRole.id)
      }
      setLedgerAccountId(defaultLedgerAccountId)
      setOriginalSelectedOrganizationId('')
      setSelectedOrganizationId('')
    }
  }, [user, roles, defaultLedgerAccountId])

  const subAdminCanChangeRoles = ['Member', 'Guest']
  const adminCanChangeRoles = ['Sub-Admin', ...subAdminCanChangeRoles]
  const superAdminCanChangeRoles = ['Super-Admin', 'Admin', ...adminCanChangeRoles]

  const canChangeRoles = (userProfile: UserProfile): string[] => {
    const roleForOrganization = userProfile.roles.find(
      (userRole: UserRole) => userRole.organization.id === selectedOrganizationId
    )

    if (!roleForOrganization) {
      return []
    }

    if (roleForOrganization.role.name === 'Super-Admin') {
      return superAdminCanChangeRoles
    }

    if (roleForOrganization.role.name === 'Admin') {
      return adminCanChangeRoles
    }

    if (roleForOrganization.role.name === 'Sub-Admin') {
      return subAdminCanChangeRoles
    }

    return []
  }

  const rolesUserCanChange = canChangeRoles(userProfile)

  console.log(rolesUserCanChange)

  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue)
  }

  const handleSubmit = () => {
    setErrorMessage(null)
    const userData: UserData = {
      id: user?.id,
      firstName,
      lastName,
      email,
      position,
      roles: [
        { organizationId: selectedOrganizationId, roleId, originalOrganizationId: originalSelectedOrganizationId },
      ],
      ledgerAccountId,
      startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
    }
    onSubmit(userData)
  }

  const isFormValid = () => {
    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      email.trim() !== '' &&
      position.trim() !== '' &&
      roleId !== '' &&
      startDate !== null &&
      ledgerAccountId !== ''
    )
  }

  const handleOrganizationChange = (selected: string) => {
    setSelectedOrganizationId(selected)
    console.log('selected', selected)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        {user ? 'Update' : 'Add New'} Team Member in{' '}
        <Typography variant="h5" component="span" style={{ color: theme.palette.primary.main }} display="inline">
          {organizationName}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Grid container spacing={3}>
          <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <TextField
                autoFocus
                margin="dense"
                label="First Name"
                fullWidth
                variant="standard"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                label="Last Name"
                fullWidth
                variant="standard"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                margin="dense"
                label="Position"
                fullWidth
                variant="standard"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Email"
                type="email"
                fullWidth
                variant="standard"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={!!user}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                select
                label="Ledger Account"
                value={ledgerAccountId}
                onChange={(e) => setLedgerAccountId(e.target.value)}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
              >
                {ledgerAccounts.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid item container xs={12} alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <SingleOrganizationSelector
                userProfile={userProfile}
                selectedOrganizationId={selectedOrganizationId}
                handleChange={handleOrganizationChange}
                width="100%"
                showLabelAbove
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                label="Role"
                value={roleId}
                onChange={(e) => setRoleId(e.target.value)}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                disabled={user && !rolesUserCanChange.includes(roles.find((r) => r.id === roleId)?.name || '')}
              >
                {(user && !rolesUserCanChange.includes(roles.find((r) => r.id === roleId)?.name || '')
                  ? roles
                  : roles.filter((role) => rolesUserCanChange.includes(role.name))
                ).map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleDateChange}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    variant: 'standard',
                    InputLabelProps: { shrink: true },
                  },
                }}
                disabled={!!user}
              />
            </Grid>
          </Grid>
        </Grid>
        {errorMessage && (
          <Typography color="error" sx={{ mt: 2 }}>
            An error occurred. Please try again.
          </Typography>
        )}
      </DialogContent>
      <DialogActions sx={{ m: 2 }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          disabled={!isFormValid() || isLoading}
          startIcon={isLoading ? <CircularProgress size={20} /> : null}
        >
          {user ? 'Update' : 'Add'} Team Member
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CreateOrUpdateUserDialog
