import { Typography } from '@mui/material'
import React from 'react'
import { ComponentType } from 'types/components'

interface ComponentDisplayProps {
  componentType: ComponentType
  value: string | number | boolean | null
  currency?: string
  currencyDecimals?: number
  decimalPlaces?: number
  typographyComponent?: React.ElementType
  fontSize?: 'small' | 'medium' | 'large'
  fontWeight?: 'normal' | 'bold'
  color?: 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error'
}

const ComponentDisplay: React.FC<ComponentDisplayProps> = ({
  componentType,
  value,
  currency = 'USD',
  currencyDecimals = 0,
  decimalPlaces = 2,
  typographyComponent = 'span',
  fontWeight = 'normal',
  fontSize = 'medium',
  color = 'inherit',
}) => {
  const getFormattedValue = () => {
    switch (componentType) {
      case ComponentType.COMPONENT_TYPE_CURRENCY:
        return new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency,
          minimumFractionDigits: currencyDecimals,
          maximumFractionDigits: currencyDecimals,
        }).format(Number(value))
      case ComponentType.COMPONENT_TYPE_INTEGER:
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(Number(value))
      case ComponentType.COMPONENT_TYPE_FLOAT:
        return new Intl.NumberFormat('en-US', {
          style: 'decimal',
          minimumFractionDigits: decimalPlaces,
          maximumFractionDigits: decimalPlaces,
        }).format(Number(value))
      case ComponentType.COMPONENT_TYPE_PERCENTAGE:
        return new Intl.NumberFormat('en-US', {
          style: 'percent',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(Number(value))
      case ComponentType.COMPONENT_TYPE_BOOL:
        return value ? 'True' : 'False'
      default:
        return value?.toString() ?? ''
    }
  }

  return (
    <Typography
      variant="body1"
      component={typographyComponent}
      fontWeight={fontWeight}
      color={color}
      fontSize={fontSize}
    >
      {getFormattedValue()}
    </Typography>
  )
}

export default ComponentDisplay
