// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Container,
  CardMedia,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";

// third party
import { motion } from "framer-motion";

// project import
import useConfig from "hooks/useConfig";

// assets
import { SendOutlined } from "@ant-design/icons";

import imgfooterlogo from "assets/images/landing/codedthemes-logo.svg";
import imgfootersoc1 from "assets/images/landing/img-soc1.svg";
import imgfootersoc2 from "assets/images/landing/img-soc2.svg";
import imgfootersoc3 from "assets/images/landing/img-soc3.svg";
import AnimateButton from "components/@extended/AnimateButton";

// types
import { ThemeDirection, ThemeMode } from "types/config";

const dashImage = require.context("assets/images/landing", true);

// link - custom style
const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "&:active": {
    color: theme.palette.primary.main,
  },
}));

// ==============================|| LANDING - FOOTER PAGE ||============================== //

type showProps = {
  isFull?: boolean;
};

const FooterBlock = ({ isFull }: showProps) => {
  const theme = useTheme();
  const { presetColor } = useConfig();
  const textColor =
    theme.palette.mode === ThemeMode.DARK ? "text.primary" : "background.paper";

  const linkSX = {
    color: theme.palette.common.white,
    fontSize: "0.875rem",
    fontWeight: 400,
    opacity: "0.6",
    cursor: "pointer",
    "&:hover": {
      opacity: "1",
    },
  };

  const frameworks = [
    {
      title: "CodeIgniter",
      link: "https://codedthemes.com/item/mantis-codeigniter-admin-template/",
    },
    {
      title: "React MUI",
      link: "https://mui.com/store/items/mantis-react-admin-dashboard-template/",
    },
    {
      title: "Angular",
      link: "https://codedthemes.com/item/mantis-angular-admin-template/",
    },
    {
      title: "Bootstrap 5",
      link: "https://codedthemes.com/item/mantis-bootstrap-admin-dashboard/",
    },
    {
      title: ".Net",
      link: "https://codedthemes.com/item/mantis-dotnet-bootstrap-dashboard-template/",
    },
  ];

  return (
    <>
      {isFull && (
        <Box
          sx={{
            position: "relative",
            bgcolor: theme.palette.grey.A700,
            zIndex: 1,
            mt: { xs: 0, md: 13.75 },
            pt: { xs: 8, sm: 7.5, md: 18.75 },
            pb: { xs: 2.5, md: 10 },
            "&:after": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "80%",
              bottom: 0,
              left: 0,
              background:
                theme.direction === ThemeDirection.RTL
                  ? `linear-gradient(transparent 100%, rgb(31, 31, 31) 70%)`
                  : `linear-gradient(180deg, transparent 0%, ${theme.palette.grey.A700} 70%)`,
            },
          }}
        >
          <CardMedia
            component="img"
            image={dashImage(`./img-footer-${presetColor}.png`)}
            sx={{
              display: { xs: "none", md: "block" },
              width: "55%",
              maxWidth: 700,
              position: "absolute",
              top: "-28%",
              right: 0,
              ...(theme.direction === ThemeDirection.RTL && {
                transform: "scaleX(-1)",
                float: "none",
              }),
            }}
          />
          <Container>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid
                item
                xs={12}
                md={6}
                sx={{ position: "relative", zIndex: 1 }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      pr: 0,
                      textAlign: "center",
                    },
                  }}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: theme.palette.common.white }}
                    >
                      Roadmap
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <motion.div
                      initial={{ opacity: 0, translateY: 550 }}
                      animate={{ opacity: 1, translateY: 0 }}
                      transition={{
                        type: "spring",
                        stiffness: 150,
                        damping: 30,
                      }}
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          color: theme.palette.common.white,
                          fontWeight: 700,
                        }}
                      >
                        Upcoming Release
                      </Typography>
                    </motion.div>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.common.white }}
                    >
                      What is next? Checkout the Upcoming release of Mantis
                      React.
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ my: 2 }}>
                    <Box sx={{ display: "inline-block" }}>
                      <AnimateButton>
                        <Button
                          size="large"
                          variant="contained"
                          endIcon={<SendOutlined />}
                          component={Link}
                          href="https://codedthemes.gitbook.io/mantis/roadmap"
                          target="_blank"
                        >
                          Roadmap
                        </Button>
                      </AnimateButton>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}

      <Box
        sx={{ pt: isFull ? 0 : 10, pb: 10, bgcolor: theme.palette.grey.A700 }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <motion.div
                initial={{ opacity: 0, translateY: 550 }}
                animate={{ opacity: 1, translateY: 0 }}
                transition={{
                  type: "spring",
                  stiffness: 150,
                  damping: 30,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CardMedia
                      component="img"
                      image={imgfooterlogo}
                      sx={{ width: "auto" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 400,
                        color: theme.palette.common.white,
                      }}
                    >
                      Since 2017, More than 50K+ Developers trust the
                      CodedThemes Digital Product. Mantis React is Manage under
                      their Experienced Team Players.
                    </Typography>
                  </Grid>
                </Grid>
              </motion.div>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={{ xs: 5, md: 2 }}>
                <Grid item xs={6} sm={3}>
                  <Stack spacing={{ xs: 3, md: 5 }}>
                    <Typography
                      variant="h5"
                      color={textColor}
                      sx={{ fontWeight: 500 }}
                    >
                      Help
                    </Typography>
                    <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                      <FooterLink
                        href="https://blog.mantisdashboard.io/"
                        target="_blank"
                        underline="none"
                      >
                        Blog
                      </FooterLink>
                      <FooterLink
                        href="https://codedthemes.gitbook.io/mantis/"
                        target="_blank"
                        underline="none"
                      >
                        Documentation
                      </FooterLink>
                      <FooterLink
                        href="https://codedthemes.gitbook.io/mantis/changelog"
                        target="_blank"
                        underline="none"
                      >
                        Change Log
                      </FooterLink>
                      <FooterLink
                        href="https://codedthemes.support-hub.io/"
                        target="_blank"
                        underline="none"
                      >
                        Support
                      </FooterLink>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Stack spacing={{ xs: 3, md: 5 }}>
                    <Typography
                      variant="h5"
                      color={textColor}
                      sx={{ fontWeight: 500 }}
                    >
                      Store Help
                    </Typography>
                    <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                      <FooterLink
                        href="https://mui.com/store/license/"
                        target="_blank"
                        underline="none"
                      >
                        License
                      </FooterLink>
                      <FooterLink
                        href="https://mui.com/store/customer-refund-policy/"
                        target="_blank"
                        underline="none"
                      >
                        Refund Policy
                      </FooterLink>
                      <FooterLink
                        href="https://support.mui.com/hc/en-us/sections/360002564979-For-customers"
                        target="_blank"
                        underline="none"
                      >
                        Submit a Request
                      </FooterLink>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Stack spacing={{ xs: 3, md: 5 }}>
                    <Typography
                      variant="h5"
                      color={textColor}
                      sx={{ fontWeight: 500 }}
                    >
                      Mantis Eco-System
                    </Typography>
                    <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                      {frameworks.map((item, index) => (
                        <FooterLink
                          href={item.link}
                          target="_blank"
                          underline="none"
                          key={index}
                        >
                          {item.title}
                          {/* {item.isUpcoming && <Chip variant="outlined" size="small" label="Upcoming" sx={{ ml: 0.5 }} />} */}
                        </FooterLink>
                      ))}
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Stack spacing={{ xs: 3, md: 5 }}>
                    <Typography
                      variant="h5"
                      color={textColor}
                      sx={{ fontWeight: 500 }}
                    >
                      More Products
                    </Typography>
                    <Stack spacing={{ xs: 1.5, md: 2.5 }}>
                      <FooterLink
                        href="http://mui.com/store/previews/berry-react-material-admin/"
                        target="_blank"
                        underline="none"
                      >
                        Berry React Material
                      </FooterLink>
                      <FooterLink
                        href="https://mui.com/store/previews/berry-react-material-admin-free/"
                        target="_blank"
                        underline="none"
                      >
                        Free Berry React
                      </FooterLink>
                      <FooterLink
                        href="https://github.com/codedthemes/mantis-free-react-admin-template"
                        target="_blank"
                        underline="none"
                      >
                        Free Mantis React
                      </FooterLink>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Divider sx={{ borderColor: "grey.700" }} />
      <Box
        sx={{
          py: 1.5,
          bgcolor:
            theme.palette.mode === ThemeMode.DARK
              ? theme.palette.grey[50]
              : theme.palette.grey[800],
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Typography variant="subtitle2" color="secondary">
                © Made with love by Team CodedThemes
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                sx={{ justifyContent: "flex-end" }}
              >
                <Grid item>
                  <Link underline="none" sx={linkSX}>
                    <CardMedia component="img" image={imgfootersoc1} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link underline="none" sx={linkSX}>
                    <CardMedia component="img" image={imgfootersoc2} />
                  </Link>
                </Grid>
                <Grid item>
                  <Link underline="none" sx={linkSX}>
                    <CardMedia component="img" image={imgfootersoc3} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FooterBlock;
