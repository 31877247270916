import { createRoot } from 'react-dom/client'

// scroll bar
import 'simplebar-react/dist/simplebar.min.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

// google-fonts
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import '@fontsource/public-sans/400.css'
import '@fontsource/public-sans/500.css'
import '@fontsource/public-sans/600.css'
import '@fontsource/public-sans/700.css'

import ThemeCustomization from 'themes'

import Locales from 'components/Locales'
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop'

// auth-provider
import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context'
import { GlobalStateProvider } from 'contexts/GlobalStateContext'

// project import
import { ConfigProvider } from 'contexts/ConfigContext'
import App from './App'
import reportWebVitals from './reportWebVitals'
// import { string } from "yup";

const container = document.getElementById('root')
const root = createRoot(container!)

// ==============================|| MAIN - REACT DOM RENDER ||============================== //

root.render(
  <ConfigProvider>
    <ThemeCustomization>
      {/* <RTLLayout> */}
      <Locales>
        <ScrollTop>
          {/* <SWRConfig value={{ provider: () => new Map() }}> */}
          <AuthProvider>
            <GlobalStateProvider>
              <App />
            </GlobalStateProvider>
          </AuthProvider>
          {/* </SWRConfig> */}
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  </ConfigProvider>
)

reportWebVitals()
