export type BreadcrumbItem = {
  label: string
  url?: string
}

const BreadcrumbsConfig: Record<string, BreadcrumbItem[]> = {
  '/': [{ label: 'Home', url: '/' }, { label: 'Dashboard' }],
  '/dashboard': [{ label: 'Home', url: '/' }, { label: 'Dashboard' }],
  '/organization': [{ label: 'Home', url: '/' }, { label: 'Team' }],
  '/compplan': [{ label: 'Home', url: '/' }, { label: 'Compensation Plan' }],
  '/admin/organizations/manage': [
    { label: 'Home', url: '/' },
    { label: 'Team', url: '/organization' },
    { label: 'Manage' },
  ],
  '/admin/users/profile': [
    { label: 'Home', url: '/' },
    { label: 'Team', url: '/organization' },
    { label: 'Team Member' },
  ],
  '/employee/:id/compplan': [
    { label: 'Home', url: '/' },
    { label: 'Team', url: '/organization' },
    { label: 'Employee Compensation Plan' },
  ],
  '/create-comp-plan': [
    { label: 'Home', url: '/' },
    { label: 'Team', url: '/organization' },
    { label: 'Compensation Plan' },
  ],
  '/organization/settings': [{ label: 'Home', url: '/' }, { label: 'Team' }],
  '/admin/templates': [
    { label: 'Home', url: '/' },
    { label: 'Templates', url: '/admin/templates' },
  ],
  '/admin/templates/create': [
    { label: 'Home', url: '/' },
    { label: 'Templates', url: '/admin/templates' },
    { label: 'Create Template' },
  ],
  '/admin/templates/:id/copy': [
    { label: 'Home', url: '/' },
    { label: 'Templates', url: '/admin/templates' },
    { label: 'Copy Template' },
  ],
  '/admin/templates/:id/edit': [
    { label: 'Home', url: '/' },
    { label: 'Templates', url: '/admin/templates' },
    { label: 'Edit Template' },
  ],
  '/admin/templates/select/:userId': [
    { label: 'Home', url: '/' },
    { label: 'Team', url: '/organization' },
    { label: 'Select Template' },
  ],
  '/admin/components/targets': [
    { label: 'Home', url: '/' },
    { label: 'Components', url: '/admin/components/targets' },
    { label: 'Targets' },
  ],
  '/admin/targets/edit': [
    { label: 'Home', url: '/' },
    { label: 'Components', url: '/admin/components/targets' },
    { label: 'Edit Target' },
  ],
  '/admin/components/actuals': [
    { label: 'Home', url: '/' },
    { label: 'Components', url: '/admin/components/actuals' },
    { label: 'Actuals' },
  ],
  '/admin/accruals': [{ label: 'Home', url: '/' }, { label: 'Accruals' }],
  '/admin/approvals': [{ label: 'Home', url: '/' }, { label: 'Approvals' }],
  '/admin/approvals/:id/edit': [
    { label: 'Home', url: '/' },
    { label: 'Approvals', url: '/admin/approvals' },
    { label: 'Edit' },
  ],
  '/company-settings': [{ label: 'Home', url: '/' }, { label: 'Configuration' }, { label: 'Company Settings' }],
  '/admin/ledger-accounts': [{ label: 'Home', url: '/' }, { label: 'Configuration' }, { label: 'Ledger Accounts' }],
  '/profile': [{ label: 'Home', url: '/' }, { label: 'Profile' }],
}

export default BreadcrumbsConfig
