import { FC } from 'react'
import TemplatesTable from './TemplatesTable'
import { deleteWithToken } from 'hooks/http'

const deleteTemplate = (token: string, id: string): Promise<{ message: string }> => {
  return deleteWithToken(`/compensation/templates/${id}`, token)
}

const Templates: FC = () => {
  return <TemplatesTable withCreateButtons={true} deleteTemplate={deleteTemplate} />
}

export default Templates
