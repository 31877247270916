import { CircularProgress, Grid, Typography } from '@mui/material'

const AuthLogin = () => {
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="h6">Redirecting to login, please wait...</Typography>
      </Grid>
    </Grid>
  )
}

export default AuthLogin
