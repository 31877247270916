import { Breadcrumbs as MuiBreadcrumbs, Typography } from '@mui/material'
import BreadcrumbsConfig, { BreadcrumbItem } from 'pages/breadcrumbs'
import { FC, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'

interface Props {
  maxItems?: number
  separator?: string
}

const Breadcrumbs: FC<Props> = ({ maxItems = 8, separator = '/' }) => {
  const location = useLocation()

  const getBreadcrumbs = (): BreadcrumbItem[] => {
    const path = location.pathname
    for (const [configPath, breadcrumbs] of Object.entries(BreadcrumbsConfig)) {
      if (matchDynamicPath(path, configPath)) {
        return breadcrumbs
      }
    }
    console.warn('No matching breadcrumb configuration found for path:', path)
    return []
  }

  const matchDynamicPath = (currentPath: string, pathTemplate: string): boolean => {
    const regex = new RegExp('^' + pathTemplate.replace(/:\w+/g, '[\\w-]+') + '$')
    const isMatch = regex.test(currentPath)
    return isMatch
  }

  const breadcrumbs = getBreadcrumbs()

  useEffect(() => {}, [breadcrumbs])

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems} separator={separator} sx={{ mb: 2 }}>
      {breadcrumbs.map((item, index) => (
        <Typography
          key={index}
          component={item.url ? Link : 'span'}
          to={item.url}
          color={index === breadcrumbs.length - 1 ? 'textPrimary' : 'inherit'}
          variant="h6"
          sx={{
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            fontWeight: index === breadcrumbs.length - 1 ? 'bold' : 'normal',
          }}
        >
          {item.label}
        </Typography>
      ))}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
