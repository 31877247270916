import { useParams } from "react-router-dom"

import CompPlansTable from "./CompPlansTable"

// ==============================|| SAMPLE PAGE ||============================== //

const CompPlans = () => {
  const { userId } = useParams()

  return <CompPlansTable userId={userId!} />
}

export default CompPlans
