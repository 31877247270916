import useAuth from 'hooks/useAuth'
import useLocalStorage from 'hooks/useLocalStorage'
import { useProfile } from 'hooks/useProfile'
import React, { createContext, useContext, useEffect } from 'react'
import { Organization } from 'types/organization'
import { PurcentAppState } from 'types/purcent-app-state'

const initialState: PurcentAppState = {
  selectedOrganization: null,
}

const GlobalStateContext = createContext<GlobalStateContextValue>({
  purcentAppState: null,
  onChangeOrganizationSelection: () => {},
})

interface GlobalStateWrapperProps {
  children: React.ReactNode
}

interface GlobalStateContextValue {
  purcentAppState: PurcentAppState | null
  onChangeOrganizationSelection: (org: Organization) => void
}

const GlobalStateProvider: React.FC<GlobalStateWrapperProps> = ({ children }) => {
  const [purcentAppState, setPurcentAppState] = useLocalStorage<PurcentAppState>('purcent-app-state', initialState)
  const { user, token } = useAuth()
  const { profile, isLoading, isError } = useProfile(token!)

  useEffect(() => {
    if (!isLoading && !isError && user && profile) {
      const firstOrg = profile.roles[0]?.organization
      if (
        firstOrg &&
        (!purcentAppState.selectedOrganization || purcentAppState.selectedOrganization.id !== firstOrg.id)
      ) {
        setPurcentAppState((prevState: PurcentAppState) => ({
          ...prevState,
          selectedOrganization: firstOrg,
        }))
      } else if (!firstOrg && purcentAppState.selectedOrganization) {
        setPurcentAppState(initialState)
      }
    }
  }, [user, profile, isLoading, isError, setPurcentAppState, purcentAppState.selectedOrganization])

  const onChangeOrganizationSelection = (org: Organization) => {
    setPurcentAppState({
      ...purcentAppState,
      selectedOrganization: org,
    })
  }

  return (
    <GlobalStateContext.Provider value={{ purcentAppState, onChangeOrganizationSelection }}>
      {children}
    </GlobalStateContext.Provider>
  )
}

function useGlobalState() {
  const context = useContext(GlobalStateContext)
  if (!context) {
    throw new Error('useGlobalState must be used within a GlobalStateContext')
  }
  return context
}

export { GlobalStateProvider, useGlobalState }
