import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Theme } from '@mui/system'
import PlanAllocationsCard from 'components/Plan/PlanAllocationsCards'
import PlanTargetsCard from 'components/Plan/PlanTargetsCard'
import SimpleTitle from 'components/SimpleTitle'
import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { usePlan } from 'hooks/usePlan'
import { useProfile } from 'hooks/useProfile'
import { useFetchUser } from 'hooks/useUsers'
import { FC, Suspense, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ExpandedCompensationPlan } from 'types/comp-plan'
import { User } from 'types/user'

const COMP_PLAN_TITLE = 'Compensation Plan'

const CompPlan: FC = () => {
  const theme = useTheme()
  const { userId } = useParams()
  const { token } = useAuth()
  const { profile } = useProfile(token!)

  const targetUserId = userId || profile?.user?.id
  const { compPlan, isLoading: planLoading } = usePlan(token!, targetUserId, null)
  const { userProfile, isLoading: userLoading } = useFetchUser(token!, targetUserId, null)

  const rootOrganizationId = useMemo(() => compPlan?.plan?.rootOrganizationId || '', [compPlan])

  const isLoading = planLoading || userLoading

  if (isLoading) {
    return <LoadingView />
  }

  if (!compPlan?.plan) {
    return <NoPlanView />
  }

  const user = userId === profile?.user?.id ? profile?.user : userProfile?.user

  return (
    <Suspense fallback={<CircularProgress color="primary" />}>
      <SimpleTitle title={COMP_PLAN_TITLE} />
      <CompPlanContent
        rootOrganizationId={rootOrganizationId}
        token={token!}
        user={user}
        compPlan={compPlan}
        theme={theme}
      />
    </Suspense>
  )
}

const CompPlanContent = ({
  rootOrganizationId,
  token,
  user,
  compPlan,
  theme,
}: {
  rootOrganizationId: string
  token: string
  user: User | undefined
  compPlan: ExpandedCompensationPlan
  theme: Theme
}) => {
  const { ledgerAccounts, isLoading: ledgerLoading } = useFetchLedgerAccounts(
    rootOrganizationId,
    token,
    rootOrganizationId != null
  )

  if (ledgerLoading) {
    return <CircularProgress color="primary" />
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <CompensationSummaryCard
          name={user?.firstName + ' ' + user?.lastName}
          position={user?.position}
          effectiveDate={compPlan.plan.effectiveDate}
          basePlus={compPlan?.plan.basePlus || false}
          variablePercent={compPlan?.plan.variablePercent || 0}
          baseSalary={compPlan?.plan.baseSalary || 0}
          variableCap={compPlan?.plan.variableCap || -0}
          color={theme.palette.primary.main}
        />
      </Grid>

      <Grid item xs={12}>
        <PlanTargetsCard period={compPlan.period} plan={compPlan} />
      </Grid>

      <Grid item xs={12}>
        <PlanAllocationsCard plan={compPlan} ledgerAccounts={ledgerAccounts || []} />
      </Grid>
    </Grid>
  )
}

const LoadingView: FC = () => (
  <Grid container spacing={12} style={{ height: '100%', justifyContent: 'center', alignItems: 'center' }}>
    <SimpleTitle title={COMP_PLAN_TITLE} />
    <CircularProgress color="primary" />
    Loading
  </Grid>
)

const NoPlanView: FC = () => (
  <Grid item xs={12}>
    <SimpleTitle title={COMP_PLAN_TITLE} />
    <Card>
      <CardContent>
        <Typography variant="h5">No valid compensation plan found</Typography>
        <Typography variant="body1">Please contact your manager or HR department for assistance.</Typography>
      </CardContent>
    </Card>
  </Grid>
)

export default CompPlan
