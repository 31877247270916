import { Box, LinearProgress, Theme, Typography } from '@mui/material'
import React from 'react'
import { Target } from 'types/components'

interface TargetRowProps {
  target: Target
  findMetricValue: (targetComponentId: string, targetPeriodId: string) => number
  calculateProgress: (targetComponentId: string, targetValue: string, targetPeriodId: string) => number
  theme: Theme
  componentType: string
}

const TargetRow: React.FC<TargetRowProps> = ({ target, findMetricValue, calculateProgress, theme, componentType }) => {
  const progress = calculateProgress(target.componentId || '', target.value || '', target.periodId || '')
  const metricValue = findMetricValue(target.componentId || '', target.periodId || '')

  const formatValue = (value: number, componentType: string) => {
    switch (componentType) {
      case 'currency':
        return `$${value.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      case 'percent':
        return `${(value * 100).toFixed(2)}%`
      case 'integer':
        return value.toFixed(0)
      case 'float':
        return value.toFixed(2)
      default:
        return value.toString()
    }
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="body1">{target.label}</Typography>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {`Actual: ${formatValue(metricValue, componentType)} / Target: ${formatValue(parseFloat(target.value) || 0, componentType)}`}
      </Typography>
    </Box>
  )
}

export default TargetRow
