import { Suspense } from "react"
import { Outlet } from "react-router-dom"

// project import
import Loader from "components/Loader"
import Header from "./Header"
import FooterBlock from "./FooterBlock"

// types
import { SimpleLayoutType } from "types/config"

const SimpleLayout = ({
  layout = SimpleLayoutType.SIMPLE,
}: {
  layout?: SimpleLayoutType
}) => {
  return (
    <Suspense fallback={<Loader />}>
      <Header />
      <Outlet />
      <FooterBlock isFull={layout === SimpleLayoutType.LANDING} />
    </Suspense>
  )
}

export default SimpleLayout
