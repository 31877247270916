// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";

// ==============================|| SAMPLE PAGE ||============================== //

const ReportingOne = () => {
  return (
    <MainCard title="Reporting One">
      <Typography variant="body2">Reporting One Page - Default</Typography>
    </MainCard>
  );
};

export default ReportingOne;
