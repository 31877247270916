import { DeleteOutlined } from '@ant-design/icons'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import dayjs, { Dayjs } from 'dayjs'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useProfile, useRoles } from 'hooks/useProfile'
import { useCreateUser, useFetchUser, useUpdateUser } from 'hooks/useUsers'
import { FC, startTransition, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Permissions } from 'types/permissions'
import { UserData } from 'types/user'
import { getFirstRootOrganizationId } from 'types/user-profile'

const CreateUser: FC = () => {
  console.log('CreateUser component rendered')

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const userId = searchParams.get('userId') || undefined
  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { roles, isLoading: isLoadingRoles, isError: isRolesError } = useRoles(token!, true)
  const [ledgerAccountId, setLedgerAccountId] = useState('')
  const [rootOrganizationId, setRootOrganizationId] = useState<string | null>(null)

  console.log('Current state:', { userId, token, rootOrganizationId, ledgerAccountId })

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    refetch: refetchLedgerAccounts,
  } = useFetchLedgerAccounts(rootOrganizationId || '', token!, !!rootOrganizationId)

  console.log('Ledger accounts:', { ledgerAccounts, isLoadingLedgerAccounts })

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [position, setPosition] = useState('')
  const [teamAssignments, setTeamAssignments] = useState<UserData['roles']>([
    { organizationId: '', roleId: '', delete: false },
  ])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { userProfile, isLoading: isLoadingUser } = useFetchUser(token!, userId || null, null, !!userId)
  const { createUser, isLoading: isCreatingUser } = useCreateUser(token!)
  const { updateUser, isLoading: isUpdatingUser } = useUpdateUser(token!)

  useEffect(() => {
    console.log('Profile effect triggered', { profile })
    if (profile) {
      const firstRootOrgId = getFirstRootOrganizationId(profile)
      console.log('Setting rootOrganizationId:', firstRootOrgId)
      setRootOrganizationId(firstRootOrgId)
    }
  }, [profile])

  const fetchLedgerAccounts = useCallback(() => {
    if (rootOrganizationId && token && !ledgerAccounts) {
      console.log('Fetching ledger accounts')
      refetchLedgerAccounts()
    }
  }, [rootOrganizationId, token, refetchLedgerAccounts])

  useEffect(() => {
    console.log('Root organization or token effect triggered', { rootOrganizationId, token })
    fetchLedgerAccounts()
  }, [fetchLedgerAccounts])

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.user.firstName || '')
      setLastName(userProfile.user.lastName || '')
      setEmail(userProfile.user.email || '')
      setStartDate(userProfile.user.startDate ? dayjs(userProfile.user.startDate) : null)
      setPosition(userProfile.user.position || '')
      setLedgerAccountId(userProfile.user.ledgerAccountId || '')
      setTeamAssignments(
        userProfile.roles.map((role) => ({
          organizationId: role.organization.id,
          roleId: role.role.id,
          delete: false,
        })) || [{ organizationId: '', roleId: '', delete: false }]
      )

      // Set rootOrganizationId based on the first role's organization
      if (userProfile.roles.length > 0) {
        setRootOrganizationId(userProfile.roles[0].organization.rootOrganizationId)
      }
    }
  }, [userProfile])

  useEffect(() => {
    if (token && !roles.length && !isLoadingRoles) {
      // Force a re-render to trigger a new roles fetch
      setRootOrganizationId((prevId) => prevId)
    }
  }, [token, roles, isLoadingRoles])

  // Add this function to check if the user can change roles
  const canChangeRoles = (organizationId: string): string[] => {
    const roleForOrganization = profile?.roles.find((userRole) => userRole.organization.id === organizationId)

    if (!roleForOrganization) {
      return []
    }

    if (roleForOrganization.role.name === 'Super-Admin') {
      return ['Super-Admin', 'Admin', 'Sub-Admin', 'Member', 'Guest']
    }

    if (roleForOrganization.role.name === 'Admin') {
      return ['Admin', 'Sub-Admin', 'Member', 'Guest']
    }

    if (roleForOrganization.role.name === 'Sub-Admin') {
      return ['Member', 'Guest']
    }

    return []
  }

  // Add this function to check for MEMBER_WRITE permission
  const hasMemberWritePermission = (organizationId: string): boolean => {
    const roleForOrganization = profile?.roles.find((userRole) => userRole.organization.id === organizationId)
    return roleForOrganization?.role.permissions.includes(Permissions.MEMBERS_WRITE) || false
  }
  const handleTeamAssignmentChange = (
    index: number,
    field: 'organizationId' | 'roleId' | 'delete',
    value: string | boolean
  ) => {
    const newAssignments = [...teamAssignments]
    if (field === 'delete') {
      newAssignments[index][field] = value as boolean
    } else {
      newAssignments[index][field] = value as string

      // Reset roleId when organizationId changes
      if (field === 'organizationId') {
        newAssignments[index].roleId = ''
      }
    }
    setTeamAssignments(newAssignments)
  }

  const addTeamAssignment = () => {
    setTeamAssignments([...teamAssignments, { organizationId: '', roleId: '', delete: false }])
  }

  const removeTeamAssignment = (index: number) => {
    const newAssignments = [...teamAssignments]
    newAssignments[index].delete = !newAssignments[index].delete
    setTeamAssignments(newAssignments)
  }

  const handleDateChange = (newValue: Dayjs | null) => {
    setStartDate(newValue)
  }

  const handleSubmit = async () => {
    if (!startDate) return

    setErrorMessage(null)
    const userData: UserData = {
      id: userId,
      firstName,
      lastName,
      email,
      position,
      roles: teamAssignments.filter((assignment) =>
        userId
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      ),
      ledgerAccountId: ledgerAccountId || undefined,
      startDate: startDate.format('YYYY-MM-DD'),
    }

    try {
      if (userId) {
        await updateUser(userId, userData)
      } else {
        await createUser(userData)
      }
      // Redirect to user list or show success message
      startTransition(() => {
        navigate('/organization')
      })
    } catch (error) {
      console.error('Error handling user action:', error)
      setErrorMessage('An error occurred. Please try again.')
    }
  }

  const isFormValid = () => {
    return (
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      email.trim() !== '' &&
      position.trim() !== '' &&
      startDate !== null &&
      ledgerAccountId.trim() !== '' &&
      teamAssignments.some((assignment) =>
        userId
          ? assignment.organizationId !== '' && assignment.roleId !== ''
          : !assignment.delete && assignment.organizationId !== '' && assignment.roleId !== ''
      )
    )
  }

  if (isLoadingProfile || isLoadingRoles || isLoadingLedgerAccounts || isLoadingUser) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  }

  if (isRolesError) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography color="error">Error loading roles</Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 2 }}>
      <Typography variant="h3" gutterBottom>
        {userId ? 'Edit Team Member' : 'Create New Team Member'}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
            disabled={!!userId} // Disable email field when editing
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                label="Position"
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                key="ledger-account-id"
                select
                variant="standard"
                label="Ledger Account"
                value={ledgerAccountId}
                onChange={(e) => setLedgerAccountId(e.target.value)}
                fullWidth
              >
                {ledgerAccounts?.map((account) => (
                  <MenuItem key={account.id} value={account.id}>
                    {account.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={handleDateChange}
                sx={{ width: '100%' }}
                slotProps={{ textField: { variant: 'standard' } }}
                disabled={!!userId} // Disable start date when editing
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ mt: 4, mb: 2 }}>
            <h3>Add to Team(s)</h3>
          </Box>
        </Grid>

        {teamAssignments.map((assignment, index) => {
          const isNewRow = !assignment.delete && (!assignment.organizationId || !assignment.roleId)
          const canChangeRolesForOrg = assignment.organizationId ? canChangeRoles(assignment.organizationId) : []
          const hasWritePermission = isNewRow ? true : hasMemberWritePermission(assignment.organizationId)
          const currentRoleInAllowedRoles =
            isNewRow || canChangeRolesForOrg.includes(roles.find((r) => r.id === assignment.roleId)?.name || '')
          const isDisabled = assignment.delete || (!isNewRow && (!hasWritePermission || !currentRoleInAllowedRoles))

          const tooltipTitle = isDisabled ? 'Insufficient permissions or role has been disabled' : ''

          const rolesToShow = isDisabled ? roles : roles.filter((role) => canChangeRolesForOrg.includes(role.name))

          return (
            <Grid item xs={12} key={index}>
              <Tooltip title={tooltipTitle} arrow>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    alignItems: 'center',
                    opacity: isDisabled ? 0.5 : 1,
                    backgroundColor: isDisabled ? (theme) => alpha(theme.palette.action.disabled, 0.1) : 'transparent',
                  }}
                >
                  <SingleOrganizationSelector
                    userProfile={profile}
                    selectedOrganizationId={assignment.organizationId}
                    handleChange={(value) => handleTeamAssignmentChange(index, 'organizationId', value)}
                    label="Select Team"
                    disabled={isDisabled}
                  />
                  <Select
                    variant="standard"
                    value={assignment.roleId}
                    onChange={(e) => handleTeamAssignmentChange(index, 'roleId', e.target.value)}
                    label="Role"
                    fullWidth
                    disabled={isDisabled || !assignment.organizationId}
                  >
                    {assignment.organizationId &&
                      rolesToShow.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <IconButton onClick={() => removeTeamAssignment(index)} disabled={isDisabled}>
                    <DeleteOutlined color={isDisabled ? 'disabled' : 'inherit'} />
                  </IconButton>
                </Box>
              </Tooltip>
            </Grid>
          )
        })}

        <Grid item xs={12}>
          <Button startIcon={<AddIcon />} onClick={addTeamAssignment} sx={{ mt: 1 }}>
            Add Another Team
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{ mt: 2 }}
              disabled={!isFormValid() || isCreatingUser || isUpdatingUser}
              startIcon={isCreatingUser || isUpdatingUser ? <CircularProgress size={20} /> : null}
            >
              {userId ? 'Update User' : 'Create User'}
            </Button>
          </Box>
        </Grid>

        {errorMessage && (
          <Grid item xs={12}>
            <Typography color="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default CreateUser
