import { CardContent, Divider, Grid, Typography } from '@mui/material'
import GradientPaper from 'components/cards/GradientPaper'
import React from 'react'
import { ExpandedApprovalItem } from 'types/approvals'
import { Component, Target, formatValue } from 'types/components'

interface TargetTableProps {
  organizationName: string
  items: ExpandedApprovalItem[]
}

interface TargetRowProps {
  component?: Component
  target?: Target
  periodLabel?: string
  rootPeriodLabel?: string
}

const TargetRow: React.FC<TargetRowProps> = ({ component, target, periodLabel, rootPeriodLabel }) => {
  if (component == null || target == null) {
    return <></>
  }

  const typeLabel = component.isOrganizationLevel ? 'Team' : 'Employee'

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} sm={4}>
        <Typography variant="h6">{component.label}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography align={'center'}>{typeLabel}</Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Typography align={'center'}>{formatValue(target.value, component.componentType)}</Typography>
      </Grid>
    </Grid>
  )
}

const TargetTable: React.FC<TargetTableProps> = ({ organizationName, items }) => {
  if (!items || items.length === 0) {
    return <></>
  }

  // Group items by period
  const groupedItems = items
    .filter((item) => item.approvalType === 'target')
    .filter((item) => item.targets != null && item.targets.length > 0)
    .filter((item) => item.components != null && item.components.length > 0)
    .reduce(
      (acc, item) => {
        const periodLabel = item.periodLabel || 'Unknown Period'
        if (!acc[periodLabel]) {
          acc[periodLabel] = []
        }
        acc[periodLabel].push(item)
        return acc
      },
      {} as Record<string, ExpandedApprovalItem[]>
    )

  // Custom sort function for period labels
  const sortPeriodLabels = (a: string, b: string) => {
    const order = [
      'FY',
      'H1',
      'H2',
      'Q1',
      'Q2',
      'Q3',
      'Q4',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]

    const getOrderIndex = (label: string) => {
      for (let i = 0; i < order.length; i++) {
        if (label.includes(order[i])) {
          return i
        }
      }
      return order.length + label.localeCompare(label) // For alphabetical sorting of other values
    }

    return getOrderIndex(a) - getOrderIndex(b)
  }

  const sortedPeriodLabels = Object.keys(groupedItems).sort(sortPeriodLabels)

  return (
    <div style={{ width: '100%' }}>
      {sortedPeriodLabels.map((periodLabel) => (
        <div key={periodLabel} style={{ marginBottom: '20px' }}>
          <Typography variant="h3" mb={2}>{`${organizationName} ${periodLabel} Targets`}</Typography>
          <GradientPaper sx={{ paddingTop: 1, width: '100%', marginBottom: '40px' }} gradientScale={0.5}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="h5">Target</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" align={'center'}>
                    Type
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="h5" align={'center'}>
                    Value
                  </Typography>
                </Grid>
              </Grid>
              {groupedItems[periodLabel].map((item) => {
                const component = item.components![0]
                const target = item.targets![0]
                const rootPeriodLabel = item.rootPeriodLabel

                const compositeId = `${component.id}_${target.periodId}`

                return (
                  <div key={compositeId}>
                    <div style={{ marginTop: 10, marginBottom: 10 }}>
                      <Divider />
                    </div>

                    <TargetRow
                      component={component}
                      target={target}
                      periodLabel={periodLabel}
                      rootPeriodLabel={rootPeriodLabel}
                    />
                  </div>
                )
              })}
            </CardContent>
          </GradientPaper>
        </div>
      ))}
    </div>
  )
}

export default TargetTable
