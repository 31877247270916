import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import TrueFalseSelector from 'components/TrueFalseSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ComponentType, CreateTargetDTO } from 'types/components'
import { ThemeMode } from 'types/config'
import { OrganizationCoreSetting } from 'types/organization'
import { createIdLabelMap, createPeriodToRootPeriodMap } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'

interface TargetMeta {
  targetName: string
  metricType: 'team' | 'individual'
  targetMin: number | null
  targetMax: number | null
  targetDefault: number | null
  targetFormat: ComponentType
  isExpanded: boolean
  restrictedVisibility: boolean
}

const CreateTargets: FC = () => {
  const theme = useTheme()
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [targetMetas, setTargetMetas] = useState<TargetMeta[]>([
    {
      targetName: '',
      metricType: 'team',
      targetMin: null,
      targetMax: null,
      targetDefault: null,
      targetFormat: 'int' as ComponentType,
      isExpanded: false,
      restrictedVisibility: false,
    },
  ])
  const [isReviewMode, setIsReviewMode] = useState(false)
  const [coreSettingsMap, setCoreSettingsMap] = useState<Record<string, OrganizationCoreSetting>>({})
  const [isReviewLoading, setIsReviewLoading] = useState(false)
  const [reviewTargets, setReviewTargets] = useState<CreateTargetDTO[]>([])

  const { token } = useAuth()
  const { purcentAppState } = useGlobalState()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )
  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganizations,
    token!
  )
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const hoverBackgroundColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.secondary.dark

  const periodLabelMap =
    periods && periods.periods
      ? periods.periods.reduce((acc: { [key: string]: string }, period) => {
          const idLabelMap = createIdLabelMap(period)
          Object.entries(idLabelMap).forEach(([id, label]) => {
            const newLabel = label.includes(period.label) ? label : `${label} (${period.label})`
            acc[id] = newLabel
          })
          return acc
        }, {})
      : {}

  const rootPeriodMap = periods && periods.periods ? createPeriodToRootPeriodMap(periods.periods) : {}

  const organizationLabelMap = getOrganizationLabelMap(profile)

  useEffect(() => {
    // TODO: Add logic to handle when profile is not loaded
  }, [profile, selectedOrganizations, organizationLabelMap])

  useEffect(() => {
    if (coreSettings && coreSettings.length > 0) {
      const newCoreSettingsMap = coreSettings.reduce(
        (acc, setting) => {
          acc[setting.organizationId] = setting
          return acc
        },
        {} as Record<string, OrganizationCoreSetting>
      )
      setCoreSettingsMap(newCoreSettingsMap)
    }
  }, [coreSettings])

  const handleMetricChange = (index: number, field: string, value: any) => {
    const newMetrics = [...targetMetas]
    newMetrics[index] = { ...newMetrics[index], [field]: value }
    setTargetMetas(newMetrics)
  }

  const addMetric = () => {
    setTargetMetas([
      ...targetMetas,
      {
        targetName: '',
        metricType: 'team',
        targetMin: null,
        targetMax: null,
        targetDefault: null,
        targetFormat: 'int' as ComponentType,
        isExpanded: false,
        restrictedVisibility: false,
      },
    ])
  }

  const handleOrganizationChange = (newOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newOrganizations)
    })
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newPeriods)
    })
  }

  const removeMetric = (index: number) => {
    setTargetMetas(targetMetas.filter((_, i) => i !== index))
  }

  const handleReview = () => {
    setIsReviewLoading(true)
    const newReviewTargets: CreateTargetDTO[] = []

    selectedOrganizations.forEach((orgId) => {
      selectedPeriods.forEach((periodId) => {
        targetMetas.forEach((target) => {
          const rootPeriodId = rootPeriodMap[periodId]
          const baseTarget: CreateTargetDTO = {
            organizationId: orgId,
            rootOrganizationId: purcentAppState?.selectedOrganization?.rootOrganizationId || '',
            isOrganizationLevel: target.metricType === 'individual',
            isRestricted: target.restrictedVisibility,
            periodId: periodId,
            rootPeriodId: rootPeriodId,
            label: target.targetName,
            value: target.targetDefault?.toString() ?? '',
            componentType: target.targetFormat,
            targetMin: target.targetMin?.toString() ?? '',
            targetMax: target.targetMax?.toString() ?? '',
          }

          newReviewTargets.push(baseTarget)
        })
      })
    })

    setReviewTargets(newReviewTargets)
    setIsReviewMode(true)
    setIsReviewLoading(false)
  }

  const handleTargetChange = (index: number, value: string) => {
    const updatedTargets = [...reviewTargets]
    updatedTargets[index].value = value
    setReviewTargets(updatedTargets)
  }

  const handleCreateTargets = () => {
    postWithToken('components/targets', token!, { targets: reviewTargets })
      .then((response) => {
        enqueueSnackbar('Targets created successfully', { variant: 'success' })
        // Reset the form
        setIsReviewMode(false)
        setSelectedOrganizations([])
        setSelectedPeriods([])
        setTargetMetas([
          {
            targetName: '',
            metricType: 'team',
            targetMin: null,
            targetMax: null,
            targetDefault: null,
            targetFormat: 'int' as ComponentType,
            isExpanded: false,
            restrictedVisibility: false,
          },
        ])
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to create targets'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => {
        // Redirect to /admin/components/targets
        startTransition(() => {
          navigate('/admin/components/targets')
        })
      })
  }

  const renderOrgPeriodConfig = () => {
    return (
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          Team & Period Configuration
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {isLoadingProfile ? (
              <CircularProgress />
            ) : (
              <MultiOrganizationSelector
                userProfile={profile}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                label="Select Teams"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            {isLoadingPeriods ? (
              <CircularProgress />
            ) : (
              <MultiPeriodSelector
                periods={periods.periods}
                selectedPeriods={selectedPeriods}
                handleChange={handlePeriodChange}
                label="Select Periods"
              />
            )}
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const handleExpand = (index: number) => {
    const newMetrics = [...targetMetas]
    newMetrics[index].isExpanded = !newMetrics[index].isExpanded
    setTargetMetas(newMetrics)
  }

  const renderTargetMetaConfig = () => {
    return (
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          Targets
        </Typography>
        <Grid container spacing={3}>
          {targetMetas.map((targetMeta, index) => (
            <Grid container item xs={12} spacing={2} key={index}>
              <Grid item xs={12} sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={() => handleExpand(index)}>
                  {targetMeta.isExpanded ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={targetMeta.targetName}
                  onChange={(e) => handleMetricChange(index, 'targetName', e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={targetMeta.metricType}
                    onChange={(e) => handleMetricChange(index, 'metricType', e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: hoverBackgroundColor,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="team">Team</MenuItem>
                    <MenuItem value="individual">Employee</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Format</InputLabel>
                  <Select
                    value={targetMeta.targetFormat}
                    onChange={(e) => handleMetricChange(index, 'targetFormat', e.target.value)}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          '& .MuiMenuItem-root:hover': {
                            backgroundColor: hoverBackgroundColor,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="currency">Currency</MenuItem>
                    <MenuItem value="int">Whole Number</MenuItem>
                    <MenuItem value="float">Decimal</MenuItem>
                    <MenuItem value="percent">Percentage</MenuItem>
                    <MenuItem value="bool">True/False</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                {renderInputField(index, 'targetDefault', 'Value')}
              </Grid>
              <Grid item xs={12} sm={1} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton onClick={() => removeMetric(index)}>
                  <DeleteOutlined />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Collapse in={targetMeta.isExpanded}>
                  <Paper elevation={0} style={{ padding: '16px', backgroundColor: theme.palette.grey[100] }}>
                    <Typography variant="h6" gutterBottom>
                      Advanced Settings (when creating new components)
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <TrueFalseSelector
                          name={`restricted-visibility-${index}`}
                          label="Restricted Visibility"
                          value={targetMeta.restrictedVisibility}
                          onChange={(value) => handleMetricChange(index, 'restrictedVisibility', value)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {renderInputField(index, 'targetMin', 'Target Min')}
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        {renderInputField(index, 'targetMax', 'Target Max')}
                      </Grid>
                    </Grid>
                  </Paper>
                </Collapse>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <IconButton color="primary" onClick={addMetric} style={{ width: '250px' }}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <PlusOutlined style={{ marginRight: 8 }} />
                Add Another Target
              </Box>
            </IconButton>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderInputField = (index: number, field: string, label: string) => {
    const targetMeta = targetMetas[index]
    const value = targetMeta[field as keyof typeof targetMeta] ?? ''
    const handleChange = (newValue: any) => handleMetricChange(index, field, newValue)

    return (
      <ComponentField
        componentType={targetMeta.targetFormat as ComponentType}
        label={label}
        value={value}
        onChange={handleChange}
        name={`target-${index}-${field}`}
        currency={coreSettingsMap[selectedOrganizations[0]]?.currency || 'USD'}
        currencyDecimals={coreSettingsMap[selectedOrganizations[0]]?.currencyDecimals || 0}
        decimalPlaces={2}
      />
    )
  }

  const handleBack = () => {
    setIsReviewMode(false)
  }

  const renderReviewConfig = () => {
    if (isLoadingCoreSettings) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="200px">
          <CircularProgress />
        </Box>
      )
    }

    const columns = [
      { id: 'organization', label: 'Organization', minWidth: 170 },
      { id: 'period', label: 'Period', minWidth: 170 },
      { id: 'metric', label: 'Name', minWidth: 170 },
      { id: 'type', label: 'Type', minWidth: 100 },
      { id: 'value', label: 'Value', minWidth: 100 },
      { id: 'actions', label: 'Actions', minWidth: 50 },
    ]

    const handleRemoveReviewTarget = (index: number) => {
      const updatedTargets = reviewTargets.filter((_, i) => i !== index)
      setReviewTargets(updatedTargets)
    }

    console.log(reviewTargets)

    return (
      <>
        <Typography variant="h4" gutterBottom>
          Review Targets
        </Typography>
        <Paper sx={{ mb: 3 }}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reviewTargets.map((target, index) => {
                  const orgSettings = coreSettingsMap[target.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                    percentageDecimals: 2,
                  }
                  const targetMeta = targetMetas.find((meta) => meta.targetName === target.label)
                  return (
                    <TableRow
                      id={'table-row-' + index}
                      sx={{ py: 3, '&:hover': { backgroundColor: hoverBackgroundColor } }}
                      role="checkbox"
                      tabIndex={-1}
                      key={'table-row-' + index}
                    >
                      <TableCell>{organizationLabelMap[target.organizationId]}</TableCell>
                      <TableCell>{periodLabelMap[target.periodId]}</TableCell>
                      <TableCell>{target.label}</TableCell>
                      <TableCell>{target.isOrganizationLevel ? 'Employee' : 'Team'}</TableCell>
                      <TableCell>
                        <ComponentField
                          componentType={targetMeta?.targetFormat as ComponentType}
                          label=""
                          value={target.value}
                          onChange={(value) => handleTargetChange(index, value)}
                          name={`review-target-${index}`}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleRemoveReviewTarget(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    )
  }

  return (
    <>
      {!isReviewMode && (
        <Typography variant="h3" gutterBottom>
          Configure Targets
        </Typography>
      )}
      {!isReviewMode && renderOrgPeriodConfig()}
      {!isReviewMode && renderTargetMetaConfig()}
      {!isReviewMode ? (
        <Box display="flex" justifyContent="flex-end" sx={{ mb: 3 }}>
          <Button variant="contained" onClick={handleReview} disabled={isLoadingCoreSettings || isReviewLoading}>
            {isReviewLoading ? <CircularProgress size={24} color="inherit" /> : 'Review'}
          </Button>
        </Box>
      ) : (
        renderReviewConfig()
      )}
      {isReviewMode && (
        <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={handleCreateTargets} disabled={isLoadingCoreSettings}>
            Create Targets
          </Button>
        </Box>
      )}
    </>
  )
}

export default CreateTargets
