import { Paper, useTheme } from '@mui/material'
import { CSSProperties } from 'react'

interface GradientPaperProps {
  children?: React.ReactNode
  sx?: CSSProperties
  gradientScale?: number // Optional prop for gradient scaling
}

const GradientPaper: React.FC<GradientPaperProps> = ({ children, sx, gradientScale = 1 }) => {
  const theme = useTheme()
  const mode = theme.palette.mode // Directly using the mode from the theme

  const primaryColor = theme.palette.primary.main
  const paperColor = mode === 'dark' ? theme.palette.background.paper : '#FFFFFF'
  const defaultColor = mode === 'dark' ? '#000000' : '#FFFFFF'

  // Ensure primaryColor is a valid hex and extract RGB components
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
  const processedColor = hexColorRegex.test(primaryColor) ? primaryColor : defaultColor
  const colorComponents = processedColor.replace('#', '').match(/.{1,2}/g) ?? ['FF', 'FF', 'FF']

  if (mode == 'light' && gradientScale < 1) {
    gradientScale = gradientScale + 1.1
  } else if (mode == 'light' && gradientScale >= 1) {
    gradientScale = gradientScale + 0.25
  }

  // Define the light gradient with scalability
  const gradientLight = `
    linear-gradient(
      90deg, 
      ${paperColor} 50%, 
      rgba(${parseInt(colorComponents[0], 16)}, ${parseInt(colorComponents[1], 16)}, ${parseInt(colorComponents[2], 16)}, ${Math.max(0, 0.5 + 0.5 * (1 - gradientScale))}) 100%
    )
  `

  // Updated dark gradient definition
  const gradientDark = `
    linear-gradient(
      90deg, 
      ${paperColor} 0%,
      rgba(${parseInt(colorComponents[0], 16)}, ${parseInt(colorComponents[1], 16)}, ${parseInt(colorComponents[2], 16)}, ${0.15 * gradientScale}) 100%
    )
  `

  const background = mode === 'dark' ? gradientDark : gradientLight

  return (
    <Paper
      elevation={3}
      sx={{
        background: background,
        color: mode === 'dark' ? theme.palette.text.primary : 'black',
        pl: 0,
        pb: 0,
        borderRadius: 2,
        border: mode === 'dark' ? `1px solid ${theme.palette.divider}` : 'none',
        ...sx,
      }}
    >
      {children}
    </Paper>
  )
}

export default GradientPaper
