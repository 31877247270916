// material-ui
import { useTheme } from "@mui/material/styles";
import { ThemeMode } from "types/config";

import logoDark from "assets/images/Purcent-Logo-LightColor-W145.png";
import logo from "assets/images/Purcent-Logo-Color-W145.png";

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  return (
    <img
      src={theme.palette.mode === ThemeMode.DARK ? logoDark : logo}
      alt="Purcent"
      width="100"
    />
  );
};

export default LogoMain;
