import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { FC } from 'react'

interface ConfirmationModalProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  title: string
  content: string
  isConfirming: boolean
  showConfirmButton: boolean // New prop
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  open,
  onConfirm,
  onCancel,
  title,
  content,
  isConfirming,
  showConfirmButton,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={isConfirming}>
          Cancel
        </Button>
        {showConfirmButton && (
          <Button onClick={onConfirm} autoFocus disabled={isConfirming}>
            {isConfirming ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationModal
