// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";

// ==============================|| SAMPLE PAGE ||============================== //

const ReportingTwo = () => {
  return (
    <MainCard title="Reporting Two">
      <Typography variant="body2">Reporting Two Page - Default</Typography>
    </MainCard>
  );
};

export default ReportingTwo;
