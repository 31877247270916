import { DeleteOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useSearchNeededTargetMetrics } from 'hooks/useComponents'
import { useFetchOrganizationCoreSettings } from 'hooks/useOrganizations'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ComponentType, CreateMetricDTO, NeededTargetMetric, SearchNeededTargetMetricsParams } from 'types/components'
import { OrganizationCoreSetting } from 'types/organization'
import { createIdLabelMap } from 'types/periods'
import { getOrganizationLabelMap, UserProfile } from 'types/user-profile'

const CreateMetrics: FC = () => {
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [isReviewMode, setIsReviewMode] = useState(false)
  const [metrics, setMetrics] = useState<NeededTargetMetric[]>([])
  const [coreSettingsMap, setCoreSettingsMap] = useState<Record<string, OrganizationCoreSetting>>({})
  const [limit, setLimit] = useState<number>(100)
  const [excludeMetricsEntered, setExcludeMetricsEntered] = useState<boolean>(false)
  const [createMetricDTOs, setCreateMetricDTOs] = useState<CreateMetricDTO[]>([])

  const theme = useTheme()
  const { token } = useAuth()
  const { purcentAppState } = useGlobalState()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods, isLoadingPeriods } = usePeriods(
    token!,
    purcentAppState?.selectedOrganization?.rootOrganizationId || ''
  )

  const searchParams: SearchNeededTargetMetricsParams = {
    organizationIds: selectedOrganizations,
    periodIds: selectedPeriods,
    limit: limit,
    excludeMetricsEntered: excludeMetricsEntered,
  }

  const { targets, isLoading: isLoadingTargets } = useSearchNeededTargetMetrics(
    token!,
    searchParams,
    selectedOrganizations.length > 0 && selectedPeriods.length > 0
  )

  const { coreSettings, isLoading: isLoadingCoreSettings } = useFetchOrganizationCoreSettings(
    selectedOrganizations,
    token!
  )

  const periodLabelMap: Record<string, string> = (periods?.periods || []).reduce(
    (acc, period) => ({
      ...acc,
      ...createIdLabelMap(period),
    }),
    {}
  )
  const organizationLabelMap: Record<string, string> = getOrganizationLabelMap(profile as UserProfile)

  useEffect(() => {
    if (targets) {
      // Set blank newValue for each metric
      const metricsWithBlankNewValues = targets.map((target) => ({
        ...target,
        newValue: '',
      }))
      setMetrics(metricsWithBlankNewValues)
    }
  }, [targets])

  useEffect(() => {
    if (coreSettings && coreSettings.length > 0) {
      const newCoreSettingsMap = coreSettings.reduce(
        (acc, setting) => {
          acc[setting.organizationId] = setting
          return acc
        },
        {} as Record<string, OrganizationCoreSetting>
      )
      setCoreSettingsMap(newCoreSettingsMap)
    }
  }, [coreSettings])

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    setSelectedPeriods(newPeriods)
  }

  const handleMetricChange = (index: number, newValue: any) => {
    const updatedMetrics = [...metrics]
    updatedMetrics[index].newValue = newValue
    setMetrics(updatedMetrics)
  }

  const handleDeleteMetric = (index: number) => {
    const updatedMetrics = metrics.filter((_, i) => i !== index)
    setMetrics(updatedMetrics)
  }

  const handleReview = () => {
    const newCreateMetricDTOs = metrics
      .filter((metric) => metric.newValue !== undefined && metric.newValue !== '')
      .map((metric) => ({
        componentId: metric.componentId,
        organizationId: metric.organizationId,
        rootOrganizationId: metric.rootOrganizationId,
        periodId: metric.periodId,
        rootPeriodId: metric.rootPeriodId,
        userId: metric.userId,
        value: metric.newValue as string,
      }))
    setCreateMetricDTOs(newCreateMetricDTOs)
    setIsReviewMode(true)
  }

  const handleBack = () => {
    setIsReviewMode(false)
    setCreateMetricDTOs([])
  }

  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const handleSubmit = () => {
    const metricsToSubmit = createMetricDTOs.map((dto) => ({
      componentId: dto.componentId,
      rootOrganizationId: dto.rootOrganizationId,
      organizationId: dto.organizationId,
      rootPeriodId: dto.rootPeriodId,
      periodId: dto.periodId,
      userId: dto.userId,
      value: dto.value,
    }))

    postWithToken('components/metrics', token!, metricsToSubmit)
      .then((response) => {
        enqueueSnackbar('Actuals submitted successfully', { variant: 'success' })
        // Reset the form
        setIsReviewMode(false)
        setSelectedOrganizations([])
        setSelectedPeriods([])
        setMetrics([])
        setCreateMetricDTOs([])
      })
      .catch((error) => {
        const errorMessage = error.message || 'Failed to submit metrics'
        enqueueSnackbar(errorMessage, { variant: 'error' })
      })
      .finally(() => startTransition(() => navigate('/admin/components/actuals')))
  }

  const renderOrgPeriodConfig = () => {
    return (
      <Paper elevation={3} style={{ padding: '16px', marginBottom: '16px' }}>
        <Typography variant="h4" gutterBottom>
          Team & Period Configuration
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            {isLoadingProfile ? (
              <CircularProgress />
            ) : (
              <MultiOrganizationSelector
                userProfile={profile}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
                label="Select Teams"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            {isLoadingPeriods ? (
              <CircularProgress />
            ) : (
              <MultiPeriodSelector
                periods={periods.periods}
                selectedPeriods={selectedPeriods}
                handleChange={handlePeriodChange}
                label="Select Periods"
                timeFilter="pastOnly"
              />
            )}
          </Grid>
          <Grid item xs={12} sm={1} sx={{ mb: 0, mt: -2.25 }}>
            <Typography variant="caption">Limit</Typography>
            <Select
              value={limit}
              onChange={(e) => setLimit(Number(e.target.value))}
              fullWidth
              size="small"
              variant="standard"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={1000}>1000</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={excludeMetricsEntered}
                  onChange={(e) => setExcludeMetricsEntered(e.target.checked)}
                  color="primary"
                />
              }
              label="Exclude Existing Actuals"
            />
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const renderMetricsList = () => {
    return (
      <>
        {isLoadingTargets || isLoadingCoreSettings ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper} sx={{ mb: 3 }}>
            <Table>
              <TableHead
                sx={{
                  '& th': {
                    borderTop: `1px solid ${theme.palette.divider}`,
                    borderBottom: `2px solid ${theme.palette.divider} !important`,
                  },
                }}
              >
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell>Team / Employee</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {metrics.map((metric, index) => {
                  const orgSettings = coreSettingsMap[metric.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                  }
                  const teamOrEmployee =
                    metric.firstName && metric.lastName
                      ? `${metric.firstName} ${metric.lastName}`
                      : metric.organizationId && organizationLabelMap[metric.organizationId]
                  return (
                    <TableRow key={index}>
                      <TableCell>{metric.label}</TableCell>
                      <TableCell>{teamOrEmployee}</TableCell>
                      <TableCell>{metric.periodId && periodLabelMap[metric.periodId]}</TableCell>
                      <TableCell>
                        <ComponentDisplay
                          componentType={metric.componentType as ComponentType}
                          value={metric.value}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell>
                        <ComponentField
                          componentType={metric.componentType as ComponentType}
                          label=""
                          value={metric.newValue || ''}
                          onChange={(value) => handleMetricChange(index, value)}
                          name={`metric-${index}`}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => handleDeleteMetric(index)} size="small">
                          <DeleteOutlined />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    )
  }

  const renderReviewConfig = () => {
    return (
      <>
        <Paper sx={{ mb: 3 }}>
          <TableContainer>
            <Table stickyHeader size="small" aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell>Team / Employee</TableCell>
                  <TableCell>Period</TableCell>
                  <TableCell>Target</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {createMetricDTOs.map((dto, index) => {
                  const metric = metrics.find(
                    (m) => m.componentId === dto.componentId && m.periodId === dto.periodId && m.userId === dto.userId
                  )
                  if (!metric) return null

                  const teamOrEmployee =
                    metric.firstName && metric.lastName
                      ? `${metric.firstName} ${metric.lastName}`
                      : metric.organizationId && organizationLabelMap[metric.organizationId]

                  const orgSettings = coreSettingsMap[metric.organizationId] || {
                    currency: 'USD',
                    currencyDecimals: 0,
                  }
                  return (
                    <TableRow key={index}>
                      <TableCell>{metric.label}</TableCell>
                      <TableCell>{teamOrEmployee}</TableCell>
                      <TableCell>{metric.periodId && periodLabelMap[metric.periodId]}</TableCell>
                      <TableCell>
                        <ComponentDisplay
                          componentType={metric.componentType as ComponentType}
                          value={metric.value}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                      <TableCell>
                        <ComponentDisplay
                          componentType={metric.componentType as ComponentType}
                          value={dto.value}
                          currency={orgSettings.currency}
                          currencyDecimals={orgSettings.currencyDecimals}
                          decimalPlaces={2}
                        />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </>
    )
  }

  return (
    <>
      <Typography variant="h3" gutterBottom>
        {isReviewMode ? 'Review Actuals' : 'Configure Actuals'}
      </Typography>
      {!isReviewMode && renderOrgPeriodConfig()}
      {!isReviewMode && renderMetricsList()}
      {!isReviewMode ? (
        <Box display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={handleReview} disabled={isLoadingTargets}>
            Review
          </Button>
        </Box>
      ) : (
        renderReviewConfig()
      )}
      {isReviewMode && (
        <Box display="flex" justifyContent="space-between">
          <Button variant="contained" onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={handleSubmit}>
            Submit Metrics
          </Button>
        </Box>
      )}
    </>
  )
}

export default CreateMetrics
