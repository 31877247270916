import { AuditOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import ComponentDisplay from 'components/ComponentDisplay'
import ComponentField from 'components/ComponentField'
import { postWithToken } from 'hooks/http'
import useAuth from 'hooks/useAuth'
import { useFetchMetricDetails } from 'hooks/useComponents'
import { useSnackbar } from 'notistack'
import { FC, startTransition, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ComponentType } from 'types/components'

interface ColumnProps {
  id: string
  label: string
  minWidth: number
  align?: 'right' | 'left' | 'inherit' | 'center' | 'justify' | undefined
  format?: (value: any, row: any) => string | JSX.Element
}

const EditMetric: FC = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const componentId = searchParams.get('componentId')
  const organizationId = searchParams.get('organizationId')
  const periodId = searchParams.get('periodId')
  const userId = searchParams.get('userId')

  const { token } = useAuth()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)

  const shouldFetch = !!token && !!componentId && !!organizationId && !!periodId
  console.log('Should fetch:', shouldFetch, { token, componentId, organizationId, periodId })

  const { metricDetails, isLoading, isError, revalidate } = useFetchMetricDetails(
    token!,
    {
      componentId: componentId!,
      organizationId: organizationId!,
      periodId: periodId!,
      ...(userId !== 'null' && { userId }),
    },
    shouldFetch
  )

  const [metricValue, setMetricValue] = useState('')

  useEffect(() => {
    if (metricDetails) {
      setMetricValue(metricDetails.revisions[0].value)
    }
  }, [metricDetails])

  const handleSave = async () => {
    setIsSaving(true)
    try {
      await postWithToken('components/metrics/update', token!, {
        componentId,
        periodId,
        organizationId,
        value: metricValue,
        ...(userId !== 'null' && { userId }),
      })

      enqueueSnackbar('Actual updated successfully', { variant: 'success' })
      revalidate()
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 400:
            enqueueSnackbar('Invalid input data. Please check your entries.', { variant: 'error' })
            break
          case 403:
            enqueueSnackbar('You do not have permission to update this actual.', { variant: 'error' })
            break
          default:
            enqueueSnackbar('Failed to update actual. Please try again.', { variant: 'error' })
        }
      } else {
        enqueueSnackbar('An unexpected error occurred. Please try again.', { variant: 'error' })
      }
    } finally {
      setIsSaving(false)
    }
  }

  if (isLoading) {
    return <CircularProgress />
  }

  if (isError || !metricDetails) {
    return <Typography variant="h6">Error loading actual details</Typography>
  }

  const orgSettings = metricDetails.coreSettings

  const planColumns: ColumnProps[] = [
    { id: 'name', label: 'Name', minWidth: 170, format: (value, row) => `${row.firstName} ${row.lastName}` },
    {
      id: 'approvalStatus',
      label: 'Status',
      minWidth: 100,
      format: (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 80,
      align: 'center',
      format: (value, row) => (
        <Box display="flex" justifyContent="center">
          <Tooltip title="View Plan">
            <IconButton
              color="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/employee/${row.userId}/compplan`)
                })
              }}
            >
              <EyeOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Plan">
            <IconButton
              color="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/create-comp-plan?userId=${row.userId}`)
                })
              }}
            >
              <EditOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Approval Batch">
            <IconButton
              color="primary"
              onClick={() => {
                startTransition(() => {
                  navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                })
              }}
            >
              <AuditOutlined />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ]

  const revisionColumns: ColumnProps[] = [
    { id: 'revision', label: 'Revision', minWidth: 100 },
    {
      id: 'approvalStatus',
      label: 'Status',
      minWidth: 150,
      format: (value) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase(),
    },
    {
      id: 'value',
      label: 'Value',
      minWidth: 120,
      format: (value) => (
        <ComponentDisplay
          componentType={metricDetails.component.componentType as ComponentType}
          value={value}
          currency={orgSettings.currency}
          currencyDecimals={orgSettings.currencyDecimals}
        />
      ),
    },
    { id: 'createdAt', label: 'Created At', minWidth: 170, format: (value) => new Date(value).toLocaleString() },
    {
      id: 'actions',
      label: 'Actions',
      minWidth: 80,
      align: 'center',
      format: (value, row) => (
        <Box display="flex" justifyContent="center">
          {row.approvalBatchId && (
            <Tooltip title="View Approval Batch">
              <IconButton
                color="primary"
                onClick={() => {
                  startTransition(() => {
                    navigate(`/admin/approvals/${row.approvalBatchId}/edit`)
                  })
                }}
              >
                <AuditOutlined />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    },
  ]

  return (
    <>
      <Typography variant="h2" gutterBottom>
        Edit Actual
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%' }}>
            <Typography variant="h4" gutterBottom>
              Component Information
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2, pl: 2, pr: 2 }}>
              <Grid item xs={4}>
                <Typography>Component:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                  {metricDetails.component.label}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Team:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                  {metricDetails.organization.name}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography>Actual Period:</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                  {metricDetails.period.label}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {!metricDetails.component.isOrganizationLevel && (
                  <>
                    <Grid item xs={4}>
                      <Typography>{metricDetails.user ? 'Employee:' : ''}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      {userId === '00000000-0000-0000-0000-000000000000' ? (
                        <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                          Values represent defaults used when creating employee plans
                        </Typography>
                      ) : metricDetails.user ? (
                        <Typography fontWeight="bold" color="primary" sx={{ textAlign: 'right' }}>
                          {`${metricDetails.user.firstName} ${metricDetails.user.lastName}`}
                        </Typography>
                      ) : null}
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, mb: 3, height: '100%', position: 'relative' }}>
            <Typography variant="h4" gutterBottom>
              Actual Value
            </Typography>
            <Box mb={2}>
              <ComponentField
                componentType={metricDetails.component.componentType as ComponentType}
                label="Value"
                value={metricValue}
                onChange={(value) => setMetricValue(value)}
                name="metric-value"
                currency={orgSettings.currency}
                currencyDecimals={orgSettings.currencyDecimals}
                decimalPlaces={2}
              />
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: 16,
                right: 16,
              }}
            >
              <Button variant="contained" color="primary" onClick={handleSave} disabled={isSaving}>
                {isSaving ? <CircularProgress size={24} /> : 'Save Changes'}
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Actual Revisions
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            {revisionColumns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {metricDetails.revisions
            .sort((a, b) => b.revision - a.revision)
            .map((revision) => (
              <TableRow key={revision.revision}>
                {revisionColumns.map((column) => {
                  const value = revision[column.id as keyof typeof revision]
                  return (
                    <TableCell key={column.id} align={column.align}>
                      {column.format ? column.format(value, revision) : value}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Employee Plans using this Actual
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            {planColumns.map((column) => (
              <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {metricDetails.plans.map((plan) => (
            <TableRow key={plan.id}>
              {planColumns.map((column) => {
                const value = plan[column.id as keyof typeof plan]
                return (
                  <TableCell key={column.id} align={column.align}>
                    {column.format ? column.format(value, plan) : value}
                  </TableCell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
        Templates using this Component
      </Typography>
      <Table component={Paper} size="small" sx={{ p: 3, mb: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>Label</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metricDetails.templates.map((template) => (
            <TableRow key={template.id}>
              <TableCell>{template.label}</TableCell>
              <TableCell align="center">
                <Tooltip title="Edit Template">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      startTransition(() => {
                        navigate(`/admin/templates/${template.id}/edit`)
                      })
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export default EditMetric
