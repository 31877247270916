import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { Box, Grid, Paper, Typography } from '@mui/material'
import { FC } from 'react'
import { PeriodOverviewsResponse } from 'types/dashboard'

type OnboardOverviewsProps = Pick<
  PeriodOverviewsResponse,
  'planApprovalStats' | 'targetMetricCounts' | 'approvedComponentCounts' | 'accrualCounts'
>

const OnboardOverviews: FC<OnboardOverviewsProps> = ({
  planApprovalStats,
  targetMetricCounts,
  approvedComponentCounts,
  accrualCounts,
}) => {
  const renderStatusIcon = (value: number, total: number, isAccrual: boolean = false) => {
    if (isAccrual && total === 0) {
      return <Box sx={{ mr: 1.75 }} />
    } else if (isAccrual && value === 0 && total === 0) {
      return <CheckCircleOutlined style={{ color: 'green' }} />
    } else if (value > 0 && value === total) {
      return <CheckCircleOutlined style={{ color: 'green' }} />
    } else {
      return <WarningOutlined style={{ color: 'orange' }} />
    }
  }

  const renderCountItem = (label: string, value: number, total: number, isAccrual: boolean = false) => (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" width="100%">
          <Typography variant="body1" sx={{ flexGrow: 1 }}>
            {label}
          </Typography>
          <Box sx={{ width: '80px', textAlign: 'right', mr: 2 }}>
            <Typography variant="body1">{`(${value}/${total})`}</Typography>
          </Box>
        </Box>
        {renderStatusIcon(value, total, isAccrual)}
      </Box>
    </Grid>
  )

  const accrualsDraft = accrualCounts != null ? accrualCounts.draftCount : 0
  const accrualsPending = accrualCounts != null ? accrualCounts.pendingCount : 0
  const accrualsApproved = accrualCounts != null ? accrualCounts.approvedCount : 0

  return (
    <Paper sx={{ mb: 2, padding: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Team Compensation Status
      </Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        {renderCountItem('Employee Plans Approved', planApprovalStats.approvedUsers, planApprovalStats.totalUsers)}
        {renderCountItem(
          'Targets Approved',
          approvedComponentCounts.approvedTargets,
          approvedComponentCounts.totalTargets
        )}
        {renderCountItem(
          'Actuals Reported',
          approvedComponentCounts.totalMetrics,
          approvedComponentCounts.approvedTargets
        )}
        {renderCountItem(
          'Actuals Approved',
          approvedComponentCounts.approvedMetrics,
          approvedComponentCounts.totalMetrics
        )}
        {renderCountItem('Accruals Ready', accrualsDraft + accrualsPending, accrualsDraft, true)}
        {renderCountItem('Accruals Approved', accrualsApproved + accrualsPending, accrualsApproved, true)}
      </Grid>
      <Typography variant="caption" sx={{ mt: 2, display: 'block' }} color="text.secondary">
        * Actuals are due after the end date of the period of measurement.
      </Typography>
    </Paper>
  )
}

export default OnboardOverviews
