import { Alert, Box, Button, Snackbar, Typography } from '@mui/material'
import useAuth from 'hooks/useAuth'
import { usePasswordReset } from 'hooks/useProfile'
import React, { useState } from 'react'

const PasswordSection: React.FC = () => {
  const { token } = useAuth()
  const { triggerPasswordReset } = usePasswordReset(token || '')
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleChangePassword = async () => {
    try {
      await triggerPasswordReset()
      setOpenSnackbar(true)
    } catch (error) {
      console.error('Error triggering password reset:', error)
      // Handle error (e.g., show error message)
    }
  }

  return (
    <Box width="100%">
      <Typography variant="h4" gutterBottom>
        Change Password
      </Typography>
      <Typography variant="body1" paragraph>
        To change your password, click the button below. We'll send you an email with instructions to reset your
        password securely.
      </Typography>
      <Box display="flex" justifyContent="flex-start" marginTop="20px">
        <Button variant="contained" color="primary" onClick={handleChangePassword}>
          Send Password Reset Email
        </Button>
      </Box>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
        <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          Password reset email sent successfully!
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default PasswordSection
