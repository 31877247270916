import { useCallback, useState } from 'react'
import useSWR from 'swr'
import { LedgerAccount, LedgerAccountDTO } from 'types/ledger-account'
import { deleteWithToken, fetchWithToken, postWithToken, putWithToken } from './http'

interface UseFetchLedgerAccountsReturn {
  ledgerAccounts: LedgerAccount[] | null
  isLoading: boolean
  isError: any
  refetch: () => Promise<LedgerAccount[] | null>
}

interface UseFetchLedgerAccountReturn {
  ledgerAccount: LedgerAccount | null
  isLoading: boolean
  isError: any
  refetch: () => Promise<LedgerAccount | null>
}

interface UseCreateLedgerAccountReturn {
  createLedgerAccount: (ledgerAccountDTO: LedgerAccountDTO) => Promise<LedgerAccount>
  isLoading: boolean
  error: Error | null
  createdLedgerAccount: LedgerAccount | null
}

interface UseUpdateLedgerAccountReturn {
  updateLedgerAccount: (id: string, ledgerAccountDTO: LedgerAccountDTO) => void
  isLoading: boolean
  error: Error | null
  isSuccess: boolean
}

interface UseDeleteLedgerAccountReturn {
  deleteLedgerAccount: (id: string) => void
  isLoading: boolean
  error: Error | null
  isSuccess: boolean
}

interface UseFetchAllLedgerAccountsReturn {
  ledgerAccounts: LedgerAccount[] | null
  isLoading: boolean
  isError: any
  refetch: () => Promise<LedgerAccount[] | null>
}

export function useFetchLedgerAccounts(
  organizationId: string,
  token: string,
  shouldFetch: boolean
): UseFetchLedgerAccountsReturn {
  const reallyShouldFetch = organizationId.trim() !== '' && token.trim() !== '' && shouldFetch

  const { data, error, isValidating, mutate } = useSWR(
    reallyShouldFetch ? [`ledger-accounts?organizationId=${organizationId}`, token] : null,
    ([url, token]) => {
      return fetchWithToken(url, token, null)
    },
    {
      suspense: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      dedupingInterval: 0,
    }
  )

  const refetch = async (): Promise<LedgerAccount[] | null> => {
    const refetchedData = await mutate()
    return refetchedData as LedgerAccount[] | null
  }

  return {
    ledgerAccounts: data as LedgerAccount[] | null,
    isLoading: !data && isValidating,
    isError: error,
    refetch,
  }
}

export function useFetchLedgerAccount(id: string, token: string): UseFetchLedgerAccountReturn {
  const shouldFetch = id != null && id.trim() !== '' && token != null && token.trim() !== ''

  const { data, error, isValidating, mutate } = useSWR(
    shouldFetch ? [`ledger-accounts/${id}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  )

  const refetch = async (): Promise<LedgerAccount | null> => {
    const refetchedData = await mutate()
    return refetchedData as LedgerAccount | null
  }

  return {
    ledgerAccount: data as LedgerAccount | null,
    isLoading: !data && isValidating,
    isError: error,
    refetch,
  }
}

export function useCreateLedgerAccount(token: string): UseCreateLedgerAccountReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [createdLedgerAccount, setCreatedLedgerAccount] = useState<LedgerAccount | null>(null)

  const createLedgerAccount = useCallback(
    (ledgerAccountDTO: LedgerAccountDTO): Promise<LedgerAccount> => {
      setIsLoading(true)
      setError(null)
      setCreatedLedgerAccount(null)

      return postWithToken('ledger-accounts', token, ledgerAccountDTO)
        .then((response) => {
          const newAccount = response as LedgerAccount
          setCreatedLedgerAccount(newAccount)
          return newAccount
        })
        .catch((err) => {
          const error = err instanceof Error ? err : new Error('An error occurred')
          setError(error)
          throw error
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [token]
  )

  return {
    createLedgerAccount,
    isLoading,
    error,
    createdLedgerAccount,
  }
}

export function useUpdateLedgerAccount(token: string): UseUpdateLedgerAccountReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const updateLedgerAccount = useCallback(
    (id: string, ledgerAccountDTO: LedgerAccountDTO) => {
      setIsLoading(true)
      setError(null)
      setIsSuccess(false)

      putWithToken(`ledger-accounts/${id}`, token, ledgerAccountDTO)
        .then(() => {
          setIsSuccess(true)
        })
        .catch((err) => {
          setError(err instanceof Error ? err : new Error('An error occurred'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [token]
  )

  return {
    updateLedgerAccount,
    isLoading,
    error,
    isSuccess,
  }
}

export function useDeleteLedgerAccount(token: string): UseDeleteLedgerAccountReturn {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [isSuccess, setIsSuccess] = useState(false)

  const deleteLedgerAccount = useCallback(
    (id: string) => {
      setIsLoading(true)
      setError(null)
      setIsSuccess(false)

      deleteWithToken(`ledger-accounts/${id}`, token)
        .then(() => {
          setIsSuccess(true)
        })
        .catch((err) => {
          setError(err instanceof Error ? err : new Error('An error occurred'))
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [token]
  )

  return {
    deleteLedgerAccount,
    isLoading,
    error,
    isSuccess,
  }
}

export function useFetchAllLedgerAccounts(
  organizationIds: string[],
  token: string,
  shouldFetch: boolean
): UseFetchAllLedgerAccountsReturn {
  const reallyShouldFetch = organizationIds.length > 0 && token.trim() !== '' && shouldFetch

  const { data, error, isValidating, mutate } = useSWR(
    reallyShouldFetch ? [`ledger-accounts/all`, token, organizationIds] : null,
    ([url, token, orgIds]) => postWithToken(url, token, orgIds),
    {
      suspense: true,
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      dedupingInterval: 0,
    }
  )

  const refetch = async (): Promise<LedgerAccount[] | null> => {
    const refetchedData = await mutate()
    return refetchedData as LedgerAccount[] | null
  }

  return {
    ledgerAccounts: data as LedgerAccount[] | null,
    isLoading: !data && isValidating,
    isError: error,
    refetch,
  }
}
