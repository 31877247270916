import { Box, Grid, Slider, Theme, Typography, styled } from '@mui/material'
import AcceleratorSelector from 'components/AcceleratorSelector'
import React from 'react'

type AllocationAdjusterProps = {
  allocationValues: number[]
  setAllocationValues: React.Dispatch<React.SetStateAction<number[]>>
  maxPayout: number[]
  setMaxPayout: React.Dispatch<React.SetStateAction<number[]>>
  exampleSalary: number
  variableCompensation: number
  accelerator: string
  setAccelerator: React.Dispatch<React.SetStateAction<string>>
  theme: Theme
  currency: string
  currencyDecimals: number
}

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.primary.main,
  '& .MuiSlider-thumb': {
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${theme.palette.primary.main}33`,
    },
    '&.Mui-active': {
      boxShadow: `0px 0px 0px 14px ${theme.palette.primary.main}33`,
    },
  },
  '& .MuiSlider-track': {
    color: theme.palette.primary.main,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.primary.main,
  },
}))

const AllocationAdjuster: React.FC<AllocationAdjusterProps> = ({
  allocationValues,
  setAllocationValues,
  maxPayout,
  setMaxPayout,
  exampleSalary,
  variableCompensation,
  accelerator,
  setAccelerator,
  theme,
  currency,
  currencyDecimals,
}) => {
  const handleAllocationChange = (event: Event, newValue: number | number[]) => {
    setAllocationValues(Array.isArray(newValue) ? newValue.map((value) => value / 100) : [newValue / 100])
  }

  const handleMaxPayoutChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setMaxPayout(newValue.map((value) => value / 100))
    } else {
      setMaxPayout([newValue / 100])
    }
  }

  const allocationPercent = allocationValues[1] // Default value
  const primaryPayout = exampleSalary * variableCompensation * allocationPercent * 1.0
  const maxPayoutValue = primaryPayout * maxPayout[1]

  return (
    <Box p={2} m={2} display="flex" flexDirection="column" alignItems="center">
      <Box mb={2}>
        <Typography color="textSecondary">
          Set minimum, default, and maximum values for Allocation Percentage and Max Payout Percentage. These values are
          used to set limits and defaults when creating a compensation plan from this template.
        </Typography>
      </Box>
      <Typography variant="h5" gutterBottom mt={2}>
        Allocation Percentage
      </Typography>
      <StyledSlider
        value={allocationValues.map((value) => value * 100)}
        onChange={handleAllocationChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${Math.round(value)}%`}
        min={0}
        max={100}
        marks={[
          { value: 0, label: '0%' },
          { value: 100, label: '100%' },
          { value: Math.round(allocationValues[0] * 100), label: `${Math.round(allocationValues[0] * 100)}%` },
          { value: Math.round(allocationValues[2] * 100), label: `${Math.round(allocationValues[2] * 100)}%` },
          { value: Math.round(allocationValues[1] * 100), label: `${Math.round(allocationValues[1] * 100)}%` },
        ]}
        step={1}
        size="medium"
      />
      <Typography variant="h5" gutterBottom mt={3}>
        Max Payout Percentage
      </Typography>
      <StyledSlider
        value={maxPayout.map((value) => value * 100)}
        onChange={handleMaxPayoutChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${Math.round(value)}%`}
        min={100}
        max={300}
        marks={[
          { value: 100, label: '100%' },
          { value: 300, label: '300%' },
          { value: Math.round(maxPayout[0] * 100), label: `${Math.round(maxPayout[0] * 100)}%` },
          { value: Math.round(maxPayout[1] * 100), label: `${Math.round(maxPayout[1] * 100)}%` },
          { value: Math.round(maxPayout[2] * 100), label: `${Math.round(maxPayout[2] * 100)}%` },
        ]}
        step={1}
        size="medium"
      />
      <Grid container spacing={3} mt={3} mb={1}>
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Accelerator
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <AcceleratorSelector
              value={accelerator}
              setValue={setAccelerator}
              incrementAmount={0.1}
              fontSize="1.5rem"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ textAlign: 'center' }}>
          <Typography variant="h5" gutterBottom>
            Payout
          </Typography>
          <Typography variant="h1" color="primary">
            {primaryPayout.toLocaleString(undefined, {
              style: 'currency',
              currency: currency,
              minimumFractionDigits: currencyDecimals,
              maximumFractionDigits: currencyDecimals,
            })}
          </Typography>
          <Typography variant="h4">
            {maxPayoutValue.toLocaleString(undefined, {
              style: 'currency',
              currency: currency,
              minimumFractionDigits: currencyDecimals,
              maximumFractionDigits: currencyDecimals,
            })}
          </Typography>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" mt={2}>
        These values represent the on-target and maximum payouts for the given example compensation of $
        {exampleSalary
          ? exampleSalary.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 })
          : '0'}
        .
      </Typography>
    </Box>
  )
}

export default AllocationAdjuster
