import { Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect, useState, useTransition } from 'react'

import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import { useGlobalState } from 'contexts/GlobalStateContext'
import { useFetchApprovalChains } from 'hooks/useApprovals'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import { useFetchOrganization, useFetchOrganizationSettings } from 'hooks/useOrganizations'
import { useProfile } from 'hooks/useProfile'
import { OrganizationSetting, OrganizationSettingDTO } from 'types/organization'
import OrganizationSettingsSection from './OrganizationSettingsSection'

const OrganizationSettingsPage: React.FC = () => {
  const { token } = useAuth()
  const { purcentAppState } = useGlobalState()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)

  const [organizationId, setOrganizationId] = useState('')
  const [isPending, startTransition] = useTransition()
  const {
    isLoading: isLoadingOrg,
    isError: isErrorOrg,
    organization: organization,
  } = useFetchOrganization(organizationId, token!)
  const {
    settings,
    isLoading: isLoadingSettings,
    isError: isErrorSettings,
    saveSettings: originalSaveSettings,
  } = useFetchOrganizationSettings(organizationId, token!)

  const saveSettings = (settings: OrganizationSettingDTO[]) => originalSaveSettings(settings as OrganizationSetting[])

  const {
    approvalChains,
    isLoading: isLoadingApprovalChains,
    isError: isErrorApprovalChains,
  } = useFetchApprovalChains(token!, organization?.rootOrganizationId || '')

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    isError: isErrorLedgerAccounts,
  } = useFetchLedgerAccounts(organization?.rootOrganizationId || '', token!, organization?.rootOrganizationId !== '')

  useEffect(() => {
    const newOrgId = purcentAppState?.selectedOrganization?.id
    if (newOrgId && newOrgId !== organizationId) {
      startTransition(() => {
        setOrganizationId(newOrgId)
      })
    }
  }, [purcentAppState?.selectedOrganization?.id])

  const isLoading =
    isLoadingOrg ||
    isLoadingSettings ||
    isLoadingProfile ||
    isLoadingApprovalChains ||
    isLoadingLedgerAccounts ||
    isPending
  const isError = isErrorOrg || isErrorSettings || isErrorApprovalChains || isErrorLedgerAccounts

  const handleOrganizationChange = (selected: string) => {
    startTransition(() => {
      setOrganizationId(selected)
    })
  }

  if (isLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress color="primary" />
      </Grid>
    )
  }

  if (isError) {
    return <div>Failed to load organization data or settings</div>
  }

  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: '20px',
        }}
      >
        <Typography variant="h2">{organization?.name || 'Loading...'} Team Settings</Typography>
        <SingleOrganizationSelector
          userProfile={profile}
          selectedOrganizationId={organizationId}
          handleChange={handleOrganizationChange}
          width={200}
        />
      </Box>
      {isPending ? (
        <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <CircularProgress color="primary" />
        </Grid>
      ) : (
        <OrganizationSettingsSection
          settings={settings}
          saveSettings={saveSettings}
          approvalChains={approvalChains}
          ledgerAccounts={ledgerAccounts}
        />
      )}
    </>
  )
}

export default OrganizationSettingsPage
