import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React from 'react'

import { useCreatePlanContext } from '../../contexts/CreatePlanContextProvider'

import CompensationSummaryCard from 'components/cards/CompensationSummaryCard'
// import { VariableAllocationTable } from 'types/comp-allocations'
import PlanAllocationTables from './PlanAllocationTables'
import PlanComponentSummary from './PlanComponentSummary'
import PlanTargets from './PlanTargets'
// import { ComponentValues } from './types'

const ComponentSelectionForm = () => {
  const theme = useTheme()
  const {
    // compensationTemplate,
    // templateComponents,
    // setTemplateComponents,
    // templateComponentsInPlan,
    // setTemplateComponentsInPlan,
    // totalComponentsInPlanValue,
    //    maxVariableAllowedInComponents,
    plan,
    employeeName,
    // mode,
  } = useCreatePlanContext()

  // const resetPlanDesign = () => {
  //   let tempTemplateComponentsInPlan: VariableAllocationTable[] = []
  //   compensationTemplate?.template.variableAllocations.map((category, index) =>
  //     tempTemplateComponentsInPlan.push({ label: category.label, rows: [] })
  //   )
  //   setTemplateComponentsInPlan(tempTemplateComponentsInPlan)

  //   setTemplateComponents(JSON.parse(JSON.stringify(compensationTemplate?.template.variableAllocations || [])))
  //   // Reset the values in the plan summary
  //   // Move all conponents from the plan to the templateComponents
  // }

  // const moveAllComponentsToPlan = () => {
  //   let tempTemplateComponentsInPlan: VariableAllocationTable[] = JSON.parse(JSON.stringify(templateComponentsInPlan))
  //   let tempTemplateComponents: VariableAllocationTable[] = JSON.parse(JSON.stringify(templateComponents))
  //   tempTemplateComponents.forEach((component, categoryIndex) => {
  //     for (let componentIndex = component.rows.length - 1; componentIndex >= 0; componentIndex--) {
  //       let tempComponent = {
  //         ...tempTemplateComponents[categoryIndex].rows[componentIndex],
  //       }

  //       tempTemplateComponents[categoryIndex].rows.splice(componentIndex, 1)
  //       tempTemplateComponentsInPlan[categoryIndex].rows.push(tempComponent)
  //     }
  //   })
  //   setTemplateComponents(tempTemplateComponents)
  //   setTemplateComponentsInPlan(tempTemplateComponentsInPlan)
  // }

  // const addComponentToPlan = (categoryIndex: number, componentIndex: number) => {
  //   let tempTemplateComponentsInPlan = JSON.parse(JSON.stringify(templateComponentsInPlan))
  //   let tempTemplateComponents = JSON.parse(JSON.stringify(templateComponents))
  //   let tempComponent = {
  //     ...templateComponents[categoryIndex].rows[componentIndex],
  //   }
  //   tempTemplateComponents[categoryIndex].rows.splice(componentIndex, 1)
  //   tempTemplateComponentsInPlan[categoryIndex].rows.push(tempComponent)
  //   setTemplateComponents(tempTemplateComponents)
  //   setTemplateComponentsInPlan(tempTemplateComponentsInPlan)
  // }

  // const removeComponentFromPlan = (categoryIndex: number, componentIndex: number) => {
  //   let tempTemplateComponentsInPlan = JSON.parse(JSON.stringify(templateComponentsInPlan))
  //   let tempTemplateComponents = JSON.parse(JSON.stringify(templateComponents))
  //   let tempComponent = {
  //     ...templateComponentsInPlan[categoryIndex].rows[componentIndex],
  //   }
  //   tempTemplateComponentsInPlan[categoryIndex].rows.splice(componentIndex, 1)
  //   tempTemplateComponents[categoryIndex].rows.push(tempComponent)
  //   setTemplateComponents(tempTemplateComponents)
  //   setTemplateComponentsInPlan(tempTemplateComponentsInPlan)
  // }

  // const updateComponentInPlanValues = ({
  //   categoryIndex,
  //   componentIndex,
  //   target,
  //   acheivementMultiplier,
  //   maxPayout,
  //   allocationPercentage,
  //   minAcheivement,
  // }: ComponentValues) => {
  //   let tempTemplateComponentsInPlan = templateComponentsInPlan.slice()
  //   if (target!) {
  //     if (tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].updatedTargetValues)
  //       tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].updatedTargetValues![0] = target
  //     else tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].updatedTargetValues = { [target]: target }
  //   }
  //   if (acheivementMultiplier!) {
  //     tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].acheivementMultiplier =
  //       'a**' + acheivementMultiplier.toString()
  //   }
  //   if (maxPayout!) {
  //     tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].maxPayout = maxPayout
  //   }
  //   if (allocationPercentage!) {
  //     tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].allocationPercentage = allocationPercentage
  //   }
  //   if (minAcheivement!) {
  //     tempTemplateComponentsInPlan[categoryIndex].rows[componentIndex].updatedMinimumAcheivement = minAcheivement
  //   }

  //   setTemplateComponentsInPlan(tempTemplateComponentsInPlan)
  // }

  return (
    <React.Fragment>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={12}>
          <CompensationSummaryCard
            basePlus={plan.basePlus}
            variablePercent={plan.variablePercent}
            baseSalary={plan.baseSalary}
            variableCap={plan.variableCap}
            color={theme.palette.primary.main}
            name={employeeName}
            position={plan.position}
            effectiveDate={plan.effectiveDate}
          />
        </Grid>
        <Grid item container xs={12}>
          <PlanTargets />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <PlanComponentSummary />
        </Grid>
        <Grid item container xs={12}>
          <PlanAllocationTables />
        </Grid>
        {/* <Grid item container xs={12} sx={{ mt: 3 }} spacing={2}>
          <Grid item xs={6}>
            <CreatePlanColumn
              label={'In Plan Components'}
              columnType={'IN-PLAN'}
              components={templateComponentsInPlan}
              componentsInformation={{
                components: compensationTemplate?.components || [],
                targets: compensationTemplate?.targets || [],
              }}
              moveAllComponentsToPlan={moveAllComponentsToPlan}
              addComponentToPlan={addComponentToPlan}
              removeComponentFromPlan={removeComponentFromPlan}
              resetComponentsInPlan={resetPlanDesign}
              updateComponentInPlanValues={updateComponentInPlanValues}
            />
          </Grid>
          {mode !== 'REVIEW' && (
            <Grid item xs={6}>
              <CreatePlanColumn
                label={'Not In Plan Components'}
                columnType={'OUT-OF-PLAN'}
                components={templateComponents}
                componentsInformation={{
                  components: compensationTemplate?.components || [],
                  targets: compensationTemplate?.targets || [],
                }}
                moveAllComponentsToPlan={moveAllComponentsToPlan}
                addComponentToPlan={addComponentToPlan}
                removeComponentFromPlan={removeComponentFromPlan}
                resetComponentsInPlan={resetPlanDesign}
                updateComponentInPlanValues={updateComponentInPlanValues}
              />
            </Grid>
          )}
        </Grid> */}
      </Grid>
    </React.Fragment>
  )
}

export default ComponentSelectionForm
