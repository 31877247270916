// material-ui
import { Grid, Stack, TextField } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import dayjs from 'dayjs'

import CompensationChart from 'components/CompensationChart'
import React from 'react'
import CompensationSummaryTile from './CompensationSummaryTile'

import { ThemeMode } from 'types/config'
import { currencyFormatter, twoDigitPercentFormatter } from 'utils/formatting'
import GradientPaper from '../GradientPaper'

// ==============================|| REPORT CARD ||============================== //
interface CompensationSummaryCardProps {
  basePlus?: boolean
  variablePercent?: number
  baseSalary?: number
  variableCap?: number
  color?: string
  name?: string
  position?: string
  effectiveDate?: string
}

const CompensationSummaryCard = ({
  basePlus,
  variablePercent,
  baseSalary,
  variableCap,
  color,
  name,
  position,
  effectiveDate,
}: CompensationSummaryCardProps) => {
  const theme = useTheme()

  const footerBackgroundColor =
    theme.palette.mode === ThemeMode.DARK ? theme.palette.primary.dark : theme.palette.secondary.dark

  return (
    <React.Fragment>
      <Stack spacing={5}>
        {name && position && effectiveDate && (
          <Grid item xs={12}>
            <Grid container spacing={0} alignItems={'center'}>
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <GradientPaper>
                    <Stack
                      direction="row"
                      sx={{ paddingTop: 1, paddingBottom: 1 }}
                      justifyContent="space-around"
                      alignItems="center"
                      spacing={2}
                    >
                      <TextField
                        label="Employee Name"
                        value={name}
                        InputProps={{ readOnly: true }}
                        sx={{
                          userSelect: 'none',
                          width: '25%',
                          '& .MuiInputLabel-root': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        focused
                        variant="standard"
                      />

                      <TextField
                        label="Position"
                        value={position}
                        InputProps={{ readOnly: true }}
                        sx={{
                          userSelect: 'none',
                          width: '25%',
                          '& .MuiInputLabel-root': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        focused
                        variant="standard"
                      />

                      <TextField
                        label="Effective Date"
                        value={dayjs(effectiveDate).format('YYYY-MM-DD')}
                        InputProps={{ readOnly: true }}
                        sx={{
                          userSelect: 'none',
                          width: '25%',
                          '& .MuiInputLabel-root': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        focused
                        variant="standard"
                      />
                    </Stack>
                  </GradientPaper>
                </Grid>
              </Grid>
            </Grid>
            {/* </Paper> */}
          </Grid>
        )}
        {basePlus && baseSalary && variablePercent && variableCap && (
          <Grid item xs={12}>
            <GradientPaper>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item xs={4}>
                  <CompensationChart
                    salary={baseSalary}
                    variable={basePlus ? baseSalary * variablePercent : baseSalary / (1 - variablePercent) - baseSalary}
                  />
                </Grid>
                <Grid item xs={4}>
                  <CompensationSummaryTile
                    label="On-Target Total Compensation"
                    value={basePlus ? baseSalary + baseSalary * variablePercent : baseSalary / (1 - variablePercent)}
                    formatter={currencyFormatter}
                    width={'40%'}
                    border={true}
                    borderColor={footerBackgroundColor}
                    footer={`Up to ${currencyFormatter.format(baseSalary + baseSalary * variableCap * variablePercent)} (Max Total Compensation)`}
                    footerTextColor={theme.palette.secondary.contrastText}
                    footerBackgroundColor={footerBackgroundColor}
                    backgroundColor={'transparent'}
                  ></CompensationSummaryTile>
                </Grid>

                <Grid item xs={4} container direction="column" spacing={2}>
                  <Grid item>
                    <CompensationSummaryTile
                      label="Base Salary"
                      value={baseSalary}
                      formatter={currencyFormatter}
                      width={'40%'}
                      border={true}
                      borderColor={footerBackgroundColor}
                      backgroundColor={'transparent'}
                    ></CompensationSummaryTile>
                  </Grid>
                  <Grid item>
                    <CompensationSummaryTile
                      label="Variable Compensation"
                      value={variablePercent}
                      formatter={twoDigitPercentFormatter}
                      width={'40%'}
                      border={true}
                      borderColor={footerBackgroundColor}
                      footer={`Up to ${currencyFormatter.format(baseSalary * variableCap * variablePercent)} (Max Variable Compensation)`}
                      footerTextColor={theme.palette.secondary.contrastText}
                      footerBackgroundColor={footerBackgroundColor}
                      backgroundColor={'transparent'}
                    ></CompensationSummaryTile>
                  </Grid>
                </Grid>
              </Grid>
            </GradientPaper>
          </Grid>
        )}
      </Stack>
    </React.Fragment>
  )
}

export default CompensationSummaryCard
