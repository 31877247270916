import { Alert, Box, Button, Card, CardContent, Grid, MenuItem, Snackbar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ApprovalChain } from 'types/approvals'
import { LedgerAccount } from 'types/ledger-account'
import { OrganizationSettingDTO } from 'types/organization'
import { supportedCurrencies } from 'utils/currency'
import ApprovalSettings from './ApprovalSettings'
import TemplateDefaults from './TemplateDefaults'

interface OrganizationSettingsSectionProps {
  settings: OrganizationSettingDTO[] | undefined
  saveSettings: (settings: OrganizationSettingDTO[]) => Promise<void>
  approvalChains: ApprovalChain[] | undefined
  ledgerAccounts: LedgerAccount[] | null
}

const DEFAULT_SETTINGS: Record<string, Omit<OrganizationSettingDTO, 'organizationId' | 'rootOrganizationId'>> = {
  'default-ledger-account': {
    settingKey: 'default-ledger-account',
    settingName: 'Default Ledger Account',
    settingValue: '',
    settingType: 'string',
  },
  currency: { settingKey: 'currency', settingName: 'Currency', settingValue: 'USD', settingType: 'string' },
  'currency-decimals': {
    settingKey: 'currency-decimals',
    settingName: 'Currency Decimals',
    settingValue: '2',
    settingType: 'integer',
  },
  'default-base-plus': {
    settingKey: 'default-base-plus',
    settingName: 'Default Base Plus',
    settingValue: 'true',
    settingType: 'boolean',
  },
  'default-example-compensation': {
    settingKey: 'default-example-compensation',
    settingName: 'Default Example Compensation',
    settingValue: '100000',
    settingType: 'float',
  },
  'default-variable-compensation': {
    settingKey: 'default-variable-compensation',
    settingName: 'Default Variable Compensation',
    settingValue: '0.1,0.2,0.3',
    settingType: 'string',
  },
  'default-max-payout': {
    settingKey: 'default-max-payout',
    settingName: 'Default Max Payout',
    settingValue: '1.2,1.5,2.0',
    settingType: 'string',
  },
  'default-template-tables': {
    settingKey: 'default-template-tables',
    settingName: 'Default Tables',
    settingValue: '',
    settingType: 'string',
  },
  'target-approval-chain': {
    settingKey: 'target-approval-chain',
    settingName: 'Target Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'metric-approval-chain': {
    settingKey: 'metric-approval-chain',
    settingName: 'Metric Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'plan-approval-chain': {
    settingKey: 'plan-approval-chain',
    settingName: 'Plan Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
  'accrual-approval-chain': {
    settingKey: 'accrual-approval-chain',
    settingName: 'Accrual Approval Chain',
    settingValue: '',
    settingType: 'string',
  },
}

const OrganizationSettingsSection: React.FC<OrganizationSettingsSectionProps> = ({
  settings,
  saveSettings,
  approvalChains,
  ledgerAccounts,
}) => {
  const [localSettings, setLocalSettings] = useState<OrganizationSettingDTO[]>([])
  const [successMessage, setSuccessMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (settings && settings.length > 0) {
      const settingsMap = settings.reduce(
        (acc, setting) => {
          acc[setting.settingKey] = setting
          return acc
        },
        {} as Record<string, OrganizationSettingDTO>
      )

      const completeSettings = Object.keys(DEFAULT_SETTINGS).map((key) => ({
        ...DEFAULT_SETTINGS[key],
        ...settingsMap[key],
        organizationId: settings[0]?.organizationId || '',
        rootOrganizationId: settings[0]?.rootOrganizationId || '',
      }))

      setLocalSettings(completeSettings)
    } else {
      // If settings is undefined or empty, use default settings
      const defaultCompleteSettings = Object.keys(DEFAULT_SETTINGS).map((key) => ({
        ...DEFAULT_SETTINGS[key],
        organizationId: '',
        rootOrganizationId: '',
      }))
      setLocalSettings(defaultCompleteSettings)
    }
  }, [settings])

  const handleSave = async () => {
    try {
      await saveSettings(localSettings)
      setSuccessMessage('Settings saved successfully')
      setErrorMessage('') // Clear any previous error message
    } catch (error) {
      setErrorMessage('Failed to save settings')
      setSuccessMessage('') // Clear any previous success message
    }
  }

  // Update the handleChange function to handle both string and number indices
  const handleChange = (keyOrIndex: string | number, value: string) => {
    setLocalSettings((prevSettings) =>
      prevSettings.map((setting, index) =>
        typeof keyOrIndex === 'string'
          ? setting.settingKey === keyOrIndex
            ? { ...setting, settingValue: value }
            : setting
          : index === keyOrIndex
            ? { ...setting, settingValue: value }
            : setting
      )
    )
  }

  const getCurrencySettings = () => {
    const currencyCode = localSettings.find((s) => s.settingName === 'Currency')?.settingValue || 'USD'
    const currencyDecimals = parseInt(
      localSettings.find((s) => s.settingName === 'Currency Decimals')?.settingValue || '2',
      10
    )
    return { currency: currencyCode, currencyDecimals }
  }

  const renderAccountingSettings = () => (
    <Card elevation={3} sx={{ mb: 4 }}>
      <CardContent>
        <Typography variant="h5" gutterBottom mb={2}>
          Accounting Settings
        </Typography>
        <Grid container spacing={3} p={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Default Ledger Account"
              select
              variant="standard"
              value={localSettings.find((s) => s.settingName === 'Default Ledger Account')?.settingValue || ''}
              onChange={(e) => handleChange('default-ledger-account', e.target.value)}
            >
              {ledgerAccounts?.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.label} ({account.externalId})
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Currency"
              select
              variant="standard"
              value={localSettings.find((s) => s.settingName === 'Currency')?.settingValue || ''}
              onChange={(e) => handleChange('currency', e.target.value)}
            >
              {supportedCurrencies.map((currency) => (
                <MenuItem key={currency.code} value={currency.code}>
                  {currency.code} - {currency.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Currency Decimals"
              type="number"
              variant="standard"
              InputProps={{
                inputProps: { min: 0, max: 4 },
                inputMode: 'numeric',
              }}
              value={localSettings.find((s) => s.settingName === 'Currency Decimals')?.settingValue || ''}
              onChange={(e) => handleChange('currency-decimals', e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )

  return (
    <Box>
      {renderAccountingSettings()}
      <TemplateDefaults
        settings={localSettings}
        handleChange={handleChange}
        currency={getCurrencySettings().currency}
        currencyDecimals={getCurrencySettings().currencyDecimals}
      />
      <ApprovalSettings settings={localSettings} handleChange={handleChange} approvalChains={approvalChains} />

      <Box display="flex" justifyContent="flex-end" mt={4}>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Settings
        </Button>
      </Box>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSuccessMessage('')} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!errorMessage}
        autoHideDuration={6000}
        onClose={() => setErrorMessage('')}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setErrorMessage('')} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  )
}

export default OrganizationSettingsSection
