// material-ui
import { Box, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material'
import SimpleTitle from 'components/SimpleTitle'
import useAuth from 'hooks/useAuth'
import { usePlanWithStatus } from 'hooks/usePlan'
import { useProfile } from 'hooks/useProfile'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router'
import { Permissions } from 'types/permissions'
import { getOrganizationIdsWithPermissions } from 'types/user-profile'
import EmployeeDashboard from './EmployeeDashboard'
import ManagerDashboard from './ManagerDashboard'

const Dashboard: React.FC = () => {
  const { token } = useAuth()
  const { profile, isError } = useProfile(token!)

  const organizationIdsCanViewManagerDashboard =
    profile != null ? getOrganizationIdsWithPermissions(profile, [Permissions.MANAGER_DASHBOARD_READ]) : []

  const showManagerDashboard = organizationIdsCanViewManagerDashboard.length > 0

  const [view, setView] = useState(showManagerDashboard ? 'manager' : 'employee')
  const theme = useTheme()
  const [isReady, setIsReady] = useState(false)

  const DASHBOARD_TITLE = 'Dashboard'

  const userId = profile?.user?.id

  const { compPlan, isLoading: isLoadingPlan, isError: isErrorPlan, status } = usePlanWithStatus(token!, userId, null)

  useEffect(() => {
    if (compPlan && !isLoadingPlan && !isErrorPlan) {
      setIsReady(true)
    }
  }, [compPlan, isLoadingPlan, isErrorPlan])

  console.log('status', status)
  console.log('isLoadingPlan', isLoadingPlan)
  console.log('isReady', isReady)

  if (isError) {
    return <Navigate to="/login" />
  }

  if ((isLoadingPlan || !isReady) && status == undefined) {
    return <Typography>Loading...</Typography>
  }

  const handleViewChange = (event: React.MouseEvent<HTMLElement>, newView: string) => {
    if (newView !== null) {
      setView(newView)
    }
  }

  return (
    <div style={{ position: 'relative' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <SimpleTitle title={DASHBOARD_TITLE} />
        {showManagerDashboard && (
          <ToggleButtonGroup value={view} exclusive onChange={handleViewChange} aria-label="View toggle">
            <ToggleButton value="manager" aria-label="Manager View">
              Manager View
            </ToggleButton>
            <ToggleButton value="employee" aria-label="Employee View">
              Employee View
            </ToggleButton>
          </ToggleButtonGroup>
        )}
      </Box>
      {!showManagerDashboard || view === 'employee' ? (
        <EmployeeDashboard
          key="employee-dashboard"
          user={profile.user}
          plan={compPlan}
          theme={theme}
          status={status || 0}
        />
      ) : (
        <ManagerDashboard />
      )}
    </div>
  )
}

export default Dashboard
