import { Box, Button, ButtonGroup, Theme } from '@mui/material'
import { isBefore } from 'date-fns'
import { FC } from 'react'
import { Period, parsePeriodDate } from 'types/periods'

interface PeriodNavigationProps {
  periods: string[]
  selectedPeriod: string
  onSelectPeriod: (period: string) => void
  theme: Theme
  periodLabelMap: Record<string, string>
  periodIdMap: Record<string, Period>
}

const PeriodNavigation: FC<PeriodNavigationProps> = ({
  periods,
  selectedPeriod,
  onSelectPeriod,
  theme,
  periodLabelMap,
  periodIdMap,
}) => {
  const currentDate = new Date()

  // Log the input data for debugging
  console.log('PeriodNavigation input:', { periods, periodLabelMap, periodIdMap })

  const filteredPeriods = periods.filter((period) => {
    // Find the matching key in periodLabelMap
    const matchingKey = Object.keys(periodLabelMap).find((key) =>
      key.replaceAll(' ', '').startsWith(period.replaceAll(' ', ''))
    )
    if (!matchingKey) {
      console.log(`No matching key found for ${period}`)
      return false
    }

    const periodId = periodLabelMap[matchingKey]
    const periodData = periodIdMap[periodId]
    if (!periodData) {
      console.log(`No period data found for ${matchingKey}`)
      return false
    }

    const result = isBefore(parsePeriodDate(periodData.startDate), currentDate)
    console.log(`Period ${matchingKey} start date: ${periodData.startDate}, included: ${result}`)
    return result
  })

  // Log the filtered periods
  console.log('Filtered periods:', filteredPeriods)

  // If no periods are available, render a message
  if (filteredPeriods.length === 0) {
    return <Box>No available periods</Box>
  }

  return (
    <Box sx={{ mb: 2, width: '100%' }} key="period-navigation-box">
      <ButtonGroup variant="contained" aria-label="outlined primary button group" fullWidth key="period-button-group">
        {filteredPeriods.map((period) => (
          <Button
            key={`period-button-${period}`}
            onClick={() => onSelectPeriod(period)}
            variant={period === selectedPeriod ? 'contained' : 'outlined'}
            fullWidth
            sx={{
              border: 'none',
              color: theme.palette.text.primary,
              backgroundColor:
                period === selectedPeriod ? theme.palette.action.selected : theme.palette.background.paper,
              '&:hover': {
                backgroundColor:
                  period === selectedPeriod ? theme.palette.action.selected : theme.palette.background.paper,
                borderColor: theme.palette.action.active,
              },
            }}
          >
            {period}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  )
}

export default PeriodNavigation
