import useSWR from 'swr'
import { PeriodsResponse } from 'types/periods'
import { fetchWithToken } from './http'

export function usePeriods(token: string, organizationId: string, shouldFetch: boolean = true) {
  const shouldFetchData = token != null && token.trim() !== '' && shouldFetch

  const { data, error, isValidating } = useSWR(
    shouldFetchData ? [`periods/organization/${organizationId}`, token] : null,
    ([url, token]) => fetchWithToken(url, token, null),
    {
      suspense: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  )

  return {
    periods: data as PeriodsResponse,
    isLoadingPeriods: !data && isValidating,
    isError: error,
  }
}
