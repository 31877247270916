import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'

interface SpinnerButtonProps extends ButtonProps {
  loading?: boolean
}

const SpinnerButton: React.FC<SpinnerButtonProps> = ({ children, loading = false, disabled, ...props }) => {
  return (
    <Button {...props} disabled={disabled || loading}>
      {loading ? <CircularProgress size={24} color="inherit" /> : children}
    </Button>
  )
}

export default SpinnerButton
