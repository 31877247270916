import { Box, Grid, Typography, useTheme } from '@mui/material'
import SingleOrganizationSelector from 'components/SingleOrganizationSelector'
import useAuth from 'hooks/useAuth'
import { useFetchLedgerAccounts } from 'hooks/useLedgerAccounts'
import {
  useCreateOrganization,
  useDeleteOrganization,
  useFetchExpandedOrganization,
  useFetchOrganizationSettings,
  useUpdateOrganization,
} from 'hooks/useOrganizations'
import { useProfile, useRoles } from 'hooks/useProfile'
import { useCreateUser, useUpdateUser } from 'hooks/useUsers'
import { FC, startTransition, Suspense, useCallback, useEffect, useRef, useState } from 'react'
import {
  CreateOrgnaizationDTO,
  findOrganizationById,
  getOrganizationChildrenCount,
  UpdateOrganizationDTO,
} from 'types/organization'
import { OrganizationRolePair } from 'types/role'
import { CreateUserDTO, UpdateUserDTO, UserData } from 'types/user'
import EditOrganization from './EditOrganization'
import OrganizationTree from './OrganizationTree'

const ManageOrganizationStructure: FC = () => {
  const theme = useTheme()
  const { token } = useAuth()
  const [needsRefresh, setNeedsRefresh] = useState(false)
  const { profile, refetch: refetchProfile } = useProfile(token!, false)
  const { roles, isLoading: isRolesLoading, isError: isRolesError } = useRoles(token!, true)
  const [rootOrganizationId, setRootOrganizationId] = useState('')
  const [rootTeamId, setRootTeamId] = useState('')
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('')
  const { organizationHierarchy, isLoading, isError, refetch } = useFetchExpandedOrganization(rootTeamId, token!, false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    createOrganization,
    isLoading: isCreatingOrg,
    error: createOrgError,
    createdOrganization,
  } = useCreateOrganization(token!)

  const { deleteOrganization, isLoading: isDeletingOrg, error: deleteOrgError } = useDeleteOrganization(token!)

  const { createUser, isLoading: isCreatingUser, error: createUserError } = useCreateUser(token!)
  const { updateUser, isLoading: isUpdatingUser, error: updateUserError } = useUpdateUser(token!)

  const {
    ledgerAccounts,
    isLoading: isLoadingLedgerAccounts,
    isError: isLedgerAccountsError,
    refetch: refetchLedgerAccounts,
  } = useFetchLedgerAccounts(rootOrganizationId, token!, rootOrganizationId !== '')

  const {
    settings: organizationSettings,
    isLoading: isLoadingSettings,
    isError: isSettingsError,
    refetch: refetchSettings,
  } = useFetchOrganizationSettings(rootTeamId, token!)

  const ledgerAccountsFetchedRef = useRef<string | null>(null)

  const {
    updateOrganization,
    isLoading: isUpdatingOrg,
    error: updateOrgError,
    isSuccess: isUpdateOrgSuccess,
  } = useUpdateOrganization(token!)

  const handleOrganizationChange = (selected: string) => {
    startTransition(() => {
      setRootTeamId(selected)
      setSelectedOrganizationId(selected)
      if (organizationHierarchy) {
        const newRootOrgId = organizationHierarchy.organization.rootOrganizationId || ''
        setRootOrganizationId(newRootOrgId)
        ledgerAccountsFetchedRef.current = null // Reset the ref when organization changes
      }
      refetchSettings()
    })
  }

  useEffect(() => {
    if (organizationHierarchy) {
      const newRootOrgId = organizationHierarchy.organization.rootOrganizationId || ''
      setRootOrganizationId(newRootOrgId)

      if (ledgerAccountsFetchedRef.current !== newRootOrgId) {
        refetchLedgerAccounts()
        ledgerAccountsFetchedRef.current = newRootOrgId
      }
    }
  }, [organizationHierarchy, rootTeamId, refetchLedgerAccounts])

  const handleOrganizationCreated = useCallback(
    (organizationDTO: CreateOrgnaizationDTO): Promise<void> => {
      return new Promise<void>((resolve) => {
        if (isSubmitting || isCreatingOrg) {
          resolve()
          return
        }

        setIsSubmitting(true)
        createOrganization(organizationDTO)
        resolve()
      })
    },
    [createOrganization, isCreatingOrg, isSubmitting]
  )

  const handleOrganizationDeleted = useCallback(
    (organizationId: string): Promise<void> => {
      return new Promise<void>((resolve) => {
        if (isSubmitting || isDeletingOrg) {
          resolve()
          return
        }

        setIsSubmitting(true)
        deleteOrganization(organizationId)
          .then(() => {
            setNeedsRefresh(true)
            setSelectedOrganizationId('') // Reset selected org after deletion
          })
          .finally(() => {
            setIsSubmitting(false)
            resolve()
          })
      })
    },
    [deleteOrganization, isDeletingOrg, isSubmitting]
  )

  const handleOrganizationUpdated = useCallback(
    (organizationId: string, updateDTO: UpdateOrganizationDTO): Promise<void> => {
      return new Promise<void>((resolve) => {
        if (isSubmitting || isUpdatingOrg) {
          resolve()
          return
        }

        setIsSubmitting(true)
        updateOrganization(organizationId, updateDTO)
          .then(() => {
            setNeedsRefresh(true)
          })
          .catch((error) => {
            console.error('Error updating organization:', error)
          })
          .finally(() => {
            setIsSubmitting(false)
            resolve()
          })
      })
    },
    [updateOrganization, isSubmitting, isUpdatingOrg, isUpdateOrgSuccess]
  )

  const handleUserAction = useCallback(
    (userData: UserData): Promise<void> => {
      return new Promise<void>((resolve) => {
        if (isSubmitting || isCreatingUser || isUpdatingUser) {
          console.log('User action already in progress')
          resolve()
          return
        }

        setIsSubmitting(true)

        const roles: Array<OrganizationRolePair> = userData.roles

        if (userData.id) {
          // This is an update operation
          const updateUserDTO: UpdateUserDTO = {
            firstName: userData.firstName,
            lastName: userData.lastName,
            email: userData.email,
            position: userData.position,
            roles: roles,
            ledgerAccountId: userData.ledgerAccountId,
          }

          console.log('updateUserDTO', updateUserDTO)

          updateUser(userData.id, updateUserDTO)
            .then(() => {
              setNeedsRefresh(true)
            })
            .catch((error) => {
              console.error('Error updating user:', error)
            })
            .finally(() => {
              setIsSubmitting(false)
              resolve()
            })
        } else {
          // This is a create operation
          const createUserDTO: CreateUserDTO = {
            email: userData.email,
            firstName: userData.firstName,
            lastName: userData.lastName,
            position: userData.position,
            startDate: userData.startDate,
            roles: roles,
            ledgerAccountId: userData.ledgerAccountId,
          }

          createUser(createUserDTO)
            .then(() => {
              setNeedsRefresh(true)
            })
            .catch((error) => {
              console.error('Error creating user:', error)
            })
            .finally(() => {
              setIsSubmitting(false)
              resolve()
            })
        }
      })
    },
    [createUser, updateUser, isSubmitting, isCreatingUser, isUpdatingUser]
  )

  useEffect(() => {
    if (createdOrganization) {
      setNeedsRefresh(true)
    }
  }, [createdOrganization])

  useEffect(() => {
    if (needsRefresh) {
      Promise.all([refetch(), refetchProfile()]).finally(() => {
        setNeedsRefresh(false)
        setIsSubmitting(false)
      })
    }
  }, [needsRefresh, refetch, refetchProfile])

  useEffect(() => {
    if (createOrgError) {
      console.error('Error creating organization:', createOrgError)
      setIsSubmitting(false)
    }
  }, [createOrgError])

  const renderContent = () => {
    if (
      isLoading ||
      isRolesLoading ||
      isCreatingOrg ||
      isDeletingOrg ||
      isLoadingLedgerAccounts ||
      isLoadingSettings ||
      isUpdatingOrg
    ) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
          <Typography>Loading...</Typography>
        </Box>
      )
    }

    if (
      isError ||
      isRolesError ||
      createOrgError ||
      deleteOrgError ||
      isLedgerAccountsError ||
      isSettingsError ||
      updateOrgError
    ) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
          <Typography color="error">Error: {createOrgError?.message || 'Error loading data'}</Typography>
        </Box>
      )
    }

    if (!rootTeamId) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Typography variant="h5" color="text.secondary">
            Select a Team to Edit
          </Typography>
        </Box>
      )
    }

    const selectedOrganization =
      selectedOrganizationId && organizationHierarchy
        ? findOrganizationById(organizationHierarchy, selectedOrganizationId)
        : organizationHierarchy // Use root organization if no specific org is selected

    const childrenCount = selectedOrganization
      ? getOrganizationChildrenCount(organizationHierarchy, selectedOrganization.organization.id)
      : 0

    const hasChildren = childrenCount > 0

    return (
      <Grid container spacing={2} sx={{ height: '100%' }}>
        <Grid item xs={12} md={8} sx={{ height: '100%', overflow: 'auto' }}>
          {organizationHierarchy && (
            <OrganizationTree
              organizationHierarchy={organizationHierarchy}
              setSelectedOrganization={setSelectedOrganizationId}
              theme={theme}
              token={token!}
              onOrganizationCreated={handleOrganizationCreated}
              isSubmitting={isSubmitting || isCreatingOrg}
            />
          )}
        </Grid>
        <Grid item xs={12} md={4} sx={{ height: '100%' }}>
          {selectedOrganization ? (
            <Box sx={{ height: '100%' }}>
              <EditOrganization
                organization={selectedOrganization.organization}
                users={selectedOrganization.users}
                theme={theme}
                roles={roles}
                onDeleteOrganization={handleOrganizationDeleted}
                onUserAction={handleUserAction}
                hasChildren={hasChildren}
                isSubmitting={isSubmitting || isCreatingUser || isUpdatingUser}
                error={createUserError || updateUserError}
                ledgerAccounts={ledgerAccounts || []}
                organizationSettings={organizationSettings || []}
                onUpdateOrganization={(organizationId, updateDTO) =>
                  handleOrganizationUpdated(organizationId, updateDTO)
                }
                userProfile={profile}
              />
            </Box>
          ) : null}{' '}
        </Grid>
      </Grid>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 160px)' }}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <Typography variant="h2">Manage Team Structure</Typography>
          </Grid>
          <Grid item>
            <SingleOrganizationSelector
              userProfile={profile}
              selectedOrganizationId={rootTeamId}
              handleChange={handleOrganizationChange}
              width={200}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'hidden', p: 2 }}>
        <Suspense fallback={<Typography>Loading...</Typography>}>{renderContent()}</Suspense>
      </Box>
    </Box>
  )
}

export default ManageOrganizationStructure
