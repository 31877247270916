// material-ui
import { Typography } from "@mui/material";

// project import
import MainCard from "components/MainCard";

// ==============================|| SAMPLE PAGE ||============================== //

const ReportingThree = () => {
  return (
    <MainCard title="Reporting Three">
      <Typography variant="body2">Reporting Three Page - Default</Typography>
    </MainCard>
  );
};

export default ReportingThree;
