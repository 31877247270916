import { SearchOutlined } from '@ant-design/icons'
import { Box, CircularProgress, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import MultiPeriodSelector from 'components/MultiPeriodSelector'
import useAuth from 'hooks/useAuth'
import { useSearchMetrics } from 'hooks/useComponents'
import { usePeriods } from 'hooks/usePeriods'
import { useProfile } from 'hooks/useProfile'
import { ChangeEvent, FC, startTransition, useCallback, useEffect, useState } from 'react'
import { MetricSearchResponse } from 'types/components'
import { createIdLabelMap } from 'types/periods'
import { getOrganizationLabelMap } from 'types/user-profile'
import { debounce } from 'utils/debounce'
import MetricsTable from '../MetricsTable'

const ManageMetrics: FC = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const [selectedPeriods, setSelectedPeriods] = useState<string[]>([])
  const [periodLabelMap, setPeriodLabelMap] = useState<{ [key: string]: string }>({})
  const [organizationLabelMap, setOrganizationLabelMap] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [rootOrganizationId, setRootOrganizationId] = useState<string>('')
  const [metrics, setMetrics] = useState<MetricSearchResponse>({
    metrics: [],
    paginationInfo: {
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      perPage: 0,
    },
  })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const { token } = useAuth()
  const { profile, isLoading: isLoadingProfile } = useProfile(token!)
  const { periods, isLoadingPeriods } = usePeriods(token!, rootOrganizationId, rootOrganizationId !== '')

  const { metrics: metricsRsp, isLoading: isLoadingMetrics } = useSearchMetrics(
    token!,
    {
      organizationIds: selectedOrganizations,
      periodId: selectedPeriods,
      searchTerm,
      page: page,
      perPage: rowsPerPage,
    },
    true
  )

  useEffect(() => {
    startTransition(() => {
      setIsLoading(isLoadingMetrics || isLoadingPeriods || isLoadingProfile)
    })
  }, [isLoadingMetrics, isLoadingPeriods, isLoadingProfile])

  useEffect(() => {
    if (metricsRsp) {
      setMetrics(metricsRsp)
    }
  }, [metricsRsp])

  useEffect(() => {
    if (periods) {
      const newPeriodLabelMap =
        periods && periods.periods
          ? periods.periods.reduce((acc: { [key: string]: string }, period) => {
              const idLabelMap = createIdLabelMap(period)
              Object.entries(idLabelMap).forEach(([id, label]) => {
                const newLabel = label.includes(period.label) ? label : `${label} (${period.label})`
                acc[id] = newLabel
              })
              return acc
            }, {})
          : {}

      setPeriodLabelMap(newPeriodLabelMap)
    }
  }, [periods])

  useEffect(() => {
    if (profile) {
      const newOrganizationLabelMap = getOrganizationLabelMap(profile)
      setOrganizationLabelMap(newOrganizationLabelMap)
      // find the first root organization
      const org = profile.roles.find((role) => role.organization.rootOrganizationId != null)
      if (org) {
        setRootOrganizationId(org.organization.rootOrganizationId!)
      }
    }
  }, [profile])

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      startTransition(() => {
        setSearchTerm(value)
      })
    }, 300),
    []
  )

  const handleOrganizationChange = (newOrganizations: string[]) => {
    startTransition(() => {
      setSelectedOrganizations(newOrganizations)
      setPage(0)
    })
  }

  const handlePeriodChange = (newPeriods: string[]) => {
    startTransition(() => {
      setSelectedPeriods(newPeriods)
      setPage(0)
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    startTransition(() => {
      setPage(newPage)
    })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined) => {
    startTransition(() => {
      setRowsPerPage(+event?.target?.value!)
      setPage(0)
    })
  }

  return (
    <div style={{ position: 'relative' }}>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Typography variant="h2" sx={{ mb: 2 }}>
            Actuals
          </Typography>
          <Grid container alignItems="center" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={6} container justifyContent="center">
              <TextField
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlined />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  userSelect: 'none',
                  width: '100%',
                  '& .MuiInputLabel-root': {
                    backgroundColor: 'transparent',
                  },
                }}
                variant="standard"
                onChange={(e) => debouncedSetSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MultiOrganizationSelector
                userProfile={profile}
                selectedOrganizationIds={selectedOrganizations}
                handleChange={handleOrganizationChange}
              />
            </Grid>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MultiPeriodSelector
                periods={periods != null ? periods.periods : []}
                selectedPeriods={selectedPeriods}
                handleChange={handlePeriodChange}
              />
            </Grid>
          </Grid>
          <MetricsTable
            selectedOrganizations={selectedOrganizations}
            selectedPeriods={selectedPeriods}
            searchTerm={searchTerm}
            periodLabelMap={periodLabelMap}
            organizationLabelMap={organizationLabelMap}
            metrics={metrics}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </>
      )}
    </div>
  )
}

export default ManageMetrics
