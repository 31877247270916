import { CopyOutlined, DeleteOutlined, EditOutlined, SearchOutlined } from '@ant-design/icons'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material'
import MultiOrganizationSelector from 'components/MultiOrganizationSelector'
import { useSearch } from 'contexts/SearchContext'
import useAuth from 'hooks/useAuth'
import { useSearchExpandedTargets } from 'hooks/useComponents'
import { useProfile } from 'hooks/useProfile'
import { FC, Fragment, useEffect, useRef, useState } from 'react'
import { ExpandedTarget, getTargetCompositeId } from 'types/components'
import { ThemeMode } from 'types/config'
import AllocationAdjuster from './AlocationAdjuster'
import OperatorLegend from './OperatorLegend'

const steps = ['Select Components', 'Customize Formulas', 'Adjust Allocations']

type AdvancedRowEditorProps = {
  tableIndex: number
  rowIndex: number
  organizationLabels: Record<string, string>
  periodLabel: string
  periodId: string
  exampleSalary: number
  variableCompensation: number
  currentTargetsUsed: ExpandedTarget[]
  currentRequirements: string[]
  currentOptions: string[]
  allocationPercentage: number
  minAllocationPercentage: number
  maxAllocationPercentage: number
  defaultPayoutPercent: number
  minPayoutPercent: number
  maxPayoutPercent: number
  acceleratorInitial: string
  handleUpdateAccelerator: (tableIndex: number, rowIndex: number, accelerator: string) => void
  handleFormulaChange: (requirements: string[], options: string[]) => void
  handleUpdateAllocations: (
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number
  ) => void
  handleCloseModal: () => void // Add this new prop
  handleRowUpdate: (
    tableIndex: number,
    rowIndex: number,
    newFormulaRequirements: string[],
    newFormulaOptions: string[],
    allocationPercentage: number,
    minAllocationPercentage: number,
    maxAllocationPercentage: number,
    defaultPayoutPercent: number,
    minPayoutPercent: number,
    maxPayoutPercent: number,
    newAccelerator: string
  ) => void
  addTargetIfNotExists: (newTarget: ExpandedTarget) => void
  theme: Theme
  currency: string
  currencyDecimals: number
}

const AdvancedRowEditor: FC<AdvancedRowEditorProps> = ({
  tableIndex,
  rowIndex,
  organizationLabels,
  periodLabel,
  periodId,
  exampleSalary,
  variableCompensation,
  currentTargetsUsed,
  currentRequirements,
  currentOptions,
  allocationPercentage,
  minAllocationPercentage,
  maxAllocationPercentage,
  defaultPayoutPercent,
  minPayoutPercent,
  maxPayoutPercent,
  acceleratorInitial,
  handleFormulaChange,
  handleUpdateAllocations,
  handleUpdateAccelerator,
  handleCloseModal, // Add this new prop
  handleRowUpdate,
  addTargetIfNotExists,
  theme,
  currency,
  currencyDecimals,
}) => {
  const { token } = useAuth()
  const { profile } = useProfile(token!)
  const [activeStep, setActiveStep] = useState(0)
  const { search, setSearch } = useSearch()
  const [selectedTargets, setSelectedTargets] = useState<ExpandedTarget[]>(currentTargetsUsed)
  const [allocationValues, setAllocationValues] = useState([
    minAllocationPercentage,
    allocationPercentage,
    maxAllocationPercentage,
  ]) // Example values for min, default, max
  const [maxPayout, setMaxPayout] = useState([minPayoutPercent, defaultPayoutPercent, maxPayoutPercent]) // Default value for max payout
  const [formulas, setFormulas] = useState(
    currentRequirements.map((requirement, index) => ({ requirement, formula: currentOptions[index] }))
  )
  const [editMode, setEditMode] = useState<number | null>(null)
  const [variableMap, setVariableMap] = useState<
    Record<string, { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }>
  >(
    {} as Record<
      string,
      { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }
    >
  )
  const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])
  const { targets, isLoading, isError } = useSearchExpandedTargets(
    token!,
    {
      searchTerm: search,
      organizationIds: selectedOrganizations,
      periodId: [periodId],
      userIds: ['00000000-0000-0000-0000-000000000000'],
    },
    true
  )
  const [accelerator, setAccelerator] = useState(acceleratorInitial)

  const formulaRefs = useRef<(HTMLInputElement | null)[]>([])

  const hoverColor = theme.palette.mode == ThemeMode.DARK ? theme.palette.primary.darker : theme.palette.primary.lighter

  const handleEditFormula = (index: number, field: 'requirement' | 'formula', value: string) => {
    const newFormulas = [...formulas]
    newFormulas[index][field] = replaceSlugsWithVariables(value)
    setFormulas(newFormulas)
  }

  useEffect(() => {
    if (selectedTargets.length > 0) {
      initializeFormulasWithVariables()
    }
  }, [variableMap]) // Runs only once on component mount

  useEffect(() => {
    mapVariablesToTargets()
    initializeFormulasWithVariables()
  }, [selectedTargets])

  const mapVariablesToTargets = () => {
    const varOptions = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ]
    const newVariableMap: Record<
      string,
      { targetId: string; targetVar: string; metricVar: string; targetSlug: string; metricSlug: string }
    > = {}
    selectedTargets.forEach((target, index) => {
      const targetId = getTargetCompositeId(target.target)
      newVariableMap[targetId] = {
        targetId: targetId,
        targetVar: `Target ${varOptions[index]}`,
        metricVar: `Actual ${varOptions[index]}`,
        targetSlug: target.target.slug,
        metricSlug: target.component.slug,
      }
    })
    setVariableMap(newVariableMap)
  }

  const initializeFormulasWithVariables = () => {
    const updatedFormulas = currentRequirements.map((requirement, index) => {
      const formula = currentOptions[index]
      return {
        requirement: replaceSlugsWithVariables(requirement),
        formula: replaceSlugsWithVariables(formula),
      }
    })
    setFormulas(updatedFormulas)
  }

  const replaceSlugsWithVariables = (text: string) => {
    Object.keys(variableMap).forEach((key) => {
      const { targetSlug, metricSlug, targetVar, metricVar } = variableMap[key]
      text = text.replace(new RegExp(`\\b${targetSlug}\\b`, 'g'), targetVar)
      text = text.replace(new RegExp(`\\b${metricSlug}\\b`, 'g'), metricVar)
    })
    return text
  }

  const replaceVariablesWithSlugs = (text: string) => {
    Object.keys(variableMap).forEach((key) => {
      const { targetSlug, metricSlug, targetVar, metricVar } = variableMap[key]
      text = text.replace(new RegExp(`\\b${targetVar}\\b`, 'g'), targetSlug)
      text = text.replace(new RegExp(`\\b${metricVar}\\b`, 'g'), metricSlug)
    })
    return text
  }

  const updateFormulasOnRowChange = (newFormulas: { requirement: string; formula: string }[]) => {
    const updatedRequirements = newFormulas.map((formula) => formula.requirement).map(replaceVariablesWithSlugs)
    const updatedOptions = newFormulas.map((formula) => formula.formula).map(replaceVariablesWithSlugs)

    handleFormulaChange(updatedRequirements, updatedOptions)
  }

  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  const handleNext = () => {
    if (activeStep === 0 && selectedTargets.length === 0) {
      // Don't proceed if no targets are selected on the first step
      return
    }

    if (activeStep === 1) {
      updateFormulasOnRowChange(formulas)
    }
    if (activeStep === steps.length - 1) {
      // Handle finish action here
      const updatedRequirements = formulas.map((formula) => formula.requirement).map(replaceVariablesWithSlugs)
      const updatedOptions = formulas.map((formula) => formula.formula).map(replaceVariablesWithSlugs)

      handleRowUpdate(
        tableIndex,
        rowIndex,
        updatedRequirements,
        updatedOptions,
        allocationValues[1],
        allocationValues[0],
        allocationValues[2],
        maxPayout[1],
        maxPayout[0],
        maxPayout[2],
        accelerator
      )

      handleCloseModal()
    } else {
      setActiveStep(activeStep + 1)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const handleOrganizationChange = (newOrganizations: string[]) => {
    setSelectedOrganizations(newOrganizations)
  }

  const getTargetLabel = (target: ExpandedTarget) => {
    const targetLabel = target.target.label
    const orgLabel = organizationLabels[target.target.organizationId!]
    const targetWithOrgRemoved = targetLabel.replace(orgLabel, '')
    return `${orgLabel} ${targetWithOrgRemoved}`
  }

  const selectComponentsStep = () => {
    const handleToggle = (targetId: string) => () => {
      const selectedIndex = selectedTargets.findIndex((target) => getTargetCompositeId(target.target) === targetId)
      let newSelected: ExpandedTarget[] = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(
          selectedTargets,
          targets.targets.find((target) => getTargetCompositeId(target.target) === targetId)!
        )
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedTargets.slice(1))
      } else if (selectedIndex === selectedTargets.length - 1) {
        newSelected = newSelected.concat(selectedTargets.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selectedTargets.slice(0, selectedIndex),
          selectedTargets.slice(selectedIndex + 1)
        )
      }

      setSelectedTargets(newSelected)
      addTargetIfNotExists(targets.targets.find((target) => getTargetCompositeId(target.target) === targetId)!)
    }

    const handleClearSelected = () => {
      setSelectedTargets([])
    }

    const customList = (items: ExpandedTarget[], listHeight: string) => (
      <Box
        sx={{
          height: listHeight,
          overflow: 'auto',
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: '4px',
          mb: 1,
        }}
      >
        <List dense component="div" role="list">
          {items.map((target) => {
            const labelId = `transfer-list-item-${getTargetCompositeId(target.target)}-label`

            return (
              <ListItem
                key={getTargetCompositeId(target.target)}
                role="listitem"
                button
                onClick={handleToggle(getTargetCompositeId(target.target))}
                sx={{
                  '&:hover': {
                    backgroundColor: hoverColor,
                  },
                }}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={
                      selectedTargets.findIndex(
                        (item) => getTargetCompositeId(item.target) === getTargetCompositeId(target.target)
                      ) !== -1
                    }
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                    sx={{
                      '&:hover': {
                        backgroundColor: hoverColor,
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={getTargetLabel(target)} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Box>
    )

    const filteredTargets = isLoading || isError ? [] : targets.targets

    return (
      <Grid container spacing={5} alignItems="stretch" sx={{ height: '100%' }}>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom align="center">
            {`Available Components`}
          </Typography>

          <Grid item>{customList(filteredTargets, '410px')}</Grid>
          <Grid item mt={2}>
            <TextField
              fullWidth
              placeholder="Search components..."
              value={search}
              onChange={handleSearchChange}
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item mt={2}>
            <MultiOrganizationSelector
              selectedOrganizationIds={selectedOrganizations}
              handleChange={handleOrganizationChange}
              userProfile={profile}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h5" gutterBottom align="center">
            {`Selected Components (${selectedTargets.length})`}
          </Typography>
          {customList(selectedTargets, '450px')}
          <Button
            sx={{ width: '100%' }}
            variant="outlined"
            size="large"
            onClick={handleClearSelected}
            disabled={selectedTargets.length === 0}
            aria-label="clear selected"
          >
            Clear Selected
          </Button>
        </Grid>
      </Grid>
    )
  }

  const customizeFormulasStep = () => {
    const handleAddFormula = () => {
      setFormulas([...formulas, { requirement: '', formula: '' }])
    }

    const handleDeleteFormula = (index: number) => {
      const newFormulas = formulas.filter((_, i) => i !== index)
      setFormulas(newFormulas)
      updateFormulasOnRowChange(newFormulas)
    }

    const handleToggleEditMode = (index: number) => {
      setEditMode(editMode === index ? null : index)
    }

    const handleClickAway = (event: MouseEvent | TouchEvent | React.KeyboardEvent) => {
      // Check if the click occurred within the variable legend
      const variableLegend = document.getElementById('variable-legend')
      if (variableLegend && variableLegend.contains(event.target as Node)) {
        return // Do nothing if the click was inside the variable legend
      }
      setEditMode(null)
    }

    return (
      <Box display="flex" flexDirection="row" width="100%">
        <Box width="70%" pr={2}>
          <Box mb={2} width="100%">
            {variableLegend()}
          </Box>
          <Box mt={2} width="100%">
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Typography variant="h5" align="center">
                  Requirement
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5" align="center">
                  Formula
                </Typography>
              </Grid>
              <ClickAwayListener onClickAway={handleClickAway}>
                <Grid container spacing={2} p={2}>
                  {formulas.map((formula, index) => (
                    <Fragment key={index}>
                      <Grid item xs={5}>
                        {editMode === index ? (
                          <TextField
                            fullWidth
                            variant="standard"
                            multiline
                            value={formula.requirement}
                            onChange={(e) => handleEditFormula(index, 'requirement', e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleClickAway(e)
                              }
                            }}
                            sx={{
                              '& .MuiInputBase-input': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        ) : (
                          <Typography onClick={() => handleToggleEditMode(index)}>
                            <Grid container>
                              <Grid item xs={10}>
                                {formula.requirement ? (
                                  <Typography fontStyle="italic" align="center" ml={10}>
                                    {formula.requirement}
                                  </Typography>
                                ) : (
                                  <Box ml={10}>
                                    <Typography
                                      align="center"
                                      fontStyle="italic"
                                      color="textSecondary"
                                      fontSize="0.75rem"
                                    >
                                      Click to edit
                                    </Typography>
                                    <Typography
                                      align="center"
                                      fontStyle="italic"
                                      color="textSecondary"
                                      fontSize="0.75rem"
                                    >
                                      {`(example: Actual A < 50)`}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <EditOutlined />
                              </Grid>
                            </Grid>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={5}>
                        {editMode === index ? (
                          <TextField
                            fullWidth
                            variant="standard"
                            multiline
                            value={formula.formula}
                            onChange={(e) => handleEditFormula(index, 'formula', e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleClickAway(e)
                              }
                            }}
                            inputRef={(el) => (formulaRefs.current[index] = el)}
                            sx={{
                              '& .MuiInputBase-input': {
                                textAlign: 'center',
                              },
                            }}
                          />
                        ) : (
                          <Typography onClick={() => handleToggleEditMode(index)}>
                            <Grid container>
                              <Grid item xs={10}>
                                {formula.formula ? (
                                  <Typography fontStyle="italic" align="center" ml={10}>
                                    {formula.formula}
                                  </Typography>
                                ) : (
                                  <Box ml={10}>
                                    <Typography
                                      align="center"
                                      fontStyle="italic"
                                      color="textSecondary"
                                      fontSize="0.75rem"
                                    >
                                      Click to edit
                                    </Typography>
                                    <Typography
                                      align="center"
                                      fontStyle="italic"
                                      color="textSecondary"
                                      fontSize="0.75rem"
                                    >
                                      {`(example: Actual A/Target A  or 0.9)`}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                              <Grid item xs={2}>
                                <EditOutlined />
                              </Grid>
                            </Grid>
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={2} style={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton onClick={() => handleDeleteFormula(index)}>
                          <DeleteOutlined />
                        </IconButton>
                      </Grid>
                      {index < formulas.length - 1 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                    </Fragment>
                  ))}
                </Grid>
              </ClickAwayListener>
              <Grid item xs={12}>
                <Button onClick={handleAddFormula}>Add Another Formula</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} mt={6}>
              <Typography variant="body2" color="textSecondary" align="center">
                Please note that the list of conditions will be evaluated sequentially. The first condition that matches
                your situation will be used in the payout calculation. Subsequent conditions will not be considered once
                a match is found.
              </Typography>
            </Grid>
          </Box>
        </Box>
        <Box width="30%" pl={2}>
          <OperatorLegend theme={theme} />
        </Box>
      </Box>
    )
  }

  const variableLegend = () => {
    const handleCopyVariable = (variable: string) => {
      navigator.clipboard
        .writeText(variable)
        .then(() => {
          // Optionally, you can show a success message here
          console.log('Variable copied to clipboard')
        })
        .catch((err) => {
          console.error('Failed to copy variable: ', err)
        })
    }

    return (
      <Accordion defaultExpanded id="variable-legend">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ backgroundColor: theme.palette.divider }} // Example of using theme color
        >
          <Typography variant="h5" align="center" color={theme.palette.primary.contrastText}>
            Component Variables Legend
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" m={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h5">Component</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5">Target Variable</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="h5">Actual Variable</Typography>
              </Grid>
              {selectedTargets.map((target) => {
                const targetId = getTargetCompositeId(target.target)
                const targetLabel = getTargetLabel(target)
                return (
                  <Fragment key={targetId}>
                    <Grid item xs={6}>
                      <Typography variant="body2">{targetLabel}</Typography>
                    </Grid>
                    <Grid item xs={3} container alignItems="center">
                      <Typography variant="body2" mr={1}>
                        {variableMap[targetId].targetVar}
                      </Typography>
                      <Tooltip title="Copy Target Variable">
                        <IconButton size="small" onClick={() => handleCopyVariable(variableMap[targetId].targetVar)}>
                          <CopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={3} container alignItems="center">
                      <Typography variant="body2" mr={1}>
                        {variableMap[targetId].metricVar}
                      </Typography>
                      <Tooltip title="Copy Actual Variable">
                        <IconButton size="small" onClick={() => handleCopyVariable(variableMap[targetId].metricVar)}>
                          <CopyOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Fragment>
                )
              })}
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>
    )
  }

  const adjustAllocationsStep = () => (
    <AllocationAdjuster
      allocationValues={allocationValues}
      setAllocationValues={setAllocationValues}
      maxPayout={maxPayout}
      setMaxPayout={setMaxPayout}
      exampleSalary={exampleSalary}
      variableCompensation={variableCompensation}
      accelerator={accelerator}
      setAccelerator={setAccelerator}
      theme={theme}
      currency={currency}
      currencyDecimals={currencyDecimals}
    />
  )

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return selectComponentsStep()
      case 1:
        return customizeFormulasStep()
      case 2:
        return adjustAllocationsStep()
      default:
        return <div>Unknown step</div>
    }
  }

  return (
    <Box display="flex" flexDirection="column" minHeight="700px" width="100%">
      <Tabs
        value={activeStep}
        onChange={(event, newValue) => setActiveStep(newValue)}
        aria-label="Horizontal tabs example"
        variant="fullWidth"
        sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}
      >
        {steps.map((label, index) => {
          if (selectedTargets.length === 0 && (index === 1 || index === 2)) {
            return <Tab key={index} label={label} disabled />
          }

          return <Tab key={index} label={label} />
        })}
      </Tabs>
      <Box p={1} flexGrow={1} height="100%" width="100%">
        {renderStepContent(activeStep)}
      </Box>
      <Box mt={2} display="flex" justifyContent="space-between">
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          disabled={activeStep === 0 && selectedTargets.length === 0}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Box>
  )
}

export default AdvancedRowEditor
